import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';

import { fetchLinkedInGeoLocations } from 'services/api';
import { ReactComponent as SearchSVG } from 'assets/svgs/icon-search.svg';
import { ReactComponent as AddSVG } from 'assets/svgs/icon-add.svg';
import { ReactComponent as CloseSVG } from 'assets/svgs/icon-close.svg';

import styles from './TargetFilterSelection.module.scss';
import ActionButton from 'components/ActionButton';
import EmptySearchResult from 'components/EmptySearchResult';
import LoadingIndicator from 'components/LoadingIndicator';
import { LOCALES } from 'constants/ISO639Locales';

const filterLocaleSearchResults = inputText => {
  const inputTextMatch = inputText?.toLowerCase();
  if (!inputTextMatch) return [];

  return LOCALES.filter(
    locale =>
      locale.code.startsWith(inputTextMatch) ||
      locale.name.toLowerCase().startsWith(inputTextMatch) ||
      locale.nativeName.toLowerCase().startsWith(inputTextMatch)
  );
};

const OnEscClick = ({ eventHandler }) => {
  React.useEffect(() => {
    const onEscapeKey = ({ key }) => {
      if (key === 'Escape' && typeof eventHandler === 'function') {
        eventHandler();
      }
    };

    window.addEventListener('keydown', onEscapeKey);
    return () => {
      window.removeEventListener('keydown', onEscapeKey);
    };
  }, [eventHandler]);

  return null;
};

class TargetFilterSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: '',
      chosenLocales: [],

      debouncedSearch: null,
      isSearchingLocations: false,
      locationSearchResults: null,
      noLocationsFound: false,
      chosenLocations: [],

      inputType: null,
    };
  }

  handleLocaleInputChange = e => {
    const inputText = e.target.value;
    this.setState({ inputText });
  };

  onLocaleSearchFormSubmit = (e, searchResult) => {
    e.preventDefault();
    const { chosenLocales } = this.state;
    if (!searchResult || !searchResult.length) return;

    // Add first one with onSubmit
    this.setState({
      chosenLocales: [...chosenLocales, searchResult[0]],
      inputText: '',
      searchResult: [],
      inputType: null,
    });
  };

  onLocationSearchFormSubmit = (e, searchResult) => {
    e.preventDefault();
    const { chosenLocations } = this.state;
    if (!searchResult || !searchResult.length) return;

    // Add first one with onSubmit
    this.setState({
      chosenLocations: [...chosenLocations, searchResult[0]],
      inputText: '',
      searchResult: [],
      inputType: null,
    });
  };

  renderEmptyState = subject => {
    const { inputText } = this.state;

    return (
      <div className={styles.emptyResult}>
        <div>
          <span className={styles.infoIcon}>i</span>
        </div>
        <div>
          <EmptySearchResult title={`No ${subject} found with *${inputText}*`} />
        </div>
      </div>
    );
  };

  renderLocaleSearch = () => {
    const { inputText, chosenLocales } = this.state;
    const searchResult = inputText ? filterLocaleSearchResults(inputText) : [];

    const isNoResultsVisible = !!inputText && !searchResult.length;

    return (
      <>
        <div
          className={styles.searchBackground}
          onClick={() => this.setState({ inputType: null })}
        ></div>
        <div className={styles.search}>
          <form onSubmit={e => this.onLocaleSearchFormSubmit(e, searchResult)}>
            <div className={styles.inputWrapper}>
              <SearchSVG
                className={classnames(styles.searchIcon, {
                  [styles.valid]: !!searchResult?.length,
                })}
              />
              <input
                autoFocus
                type="text"
                className={styles.input}
                onChange={this.handleLocaleInputChange}
                value={inputText}
                placeholder="Type language…"
              />

              <button
                type="button"
                onClick={() => this.setState({ inputType: null, inputText: '' })}
                className={styles.inputCancelButton}
              >
                <CloseSVG className={styles.cancelIcon} />
              </button>
            </div>
          </form>

          {searchResult && searchResult.length > 0 && (
            <div className={styles.localeSearchResults}>
              {searchResult
                .filter(resultItem => {
                  return !chosenLocales.find(loc => loc.code === resultItem.code);
                })
                .map(item => (
                  <button
                    type="button"
                    className={styles.localeSearchResult}
                    onClick={() => {
                      this.setState({
                        chosenLocales: [...chosenLocales, item],
                        inputText: '',
                        inputType: null,
                        searchResult: [],
                      });
                    }}
                    title={`${item.name}`}
                  >
                    <span className={styles.localeInfo}>
                      <span className={styles.localeName}>
                        {item.nativeName} ({item.name})
                      </span>
                    </span>
                    <span className={styles.plusSign}>
                      <AddSVG className={styles.plusSignIcon} />
                    </span>
                  </button>
                ))}
            </div>
          )}
          {isNoResultsVisible && this.renderEmptyState('languages')}
        </div>
      </>
    );
  };

  handleLocationInputChange = e => {
    const inputText = e.target.value;

    if (this.state.debouncedSearch) {
      this.state.debouncedSearch.cancel();
    }

    this.setState({ inputText, debouncedSearch: null, noLocationsFound: false });

    // Debounced Search logic
    if (inputText.length >= 2) {
      const debouncedSearch = debounce(() => {
        this.setState({ isSearchingLocations: true });
        // execute search
        this.checkGeoLocation(inputText);

        // also clear debounced search from state
        this.setState({ debouncedSearch: null });
      }, 400);

      // Update state with debounced search function
      this.setState({ debouncedSearch });

      // Start debounced search
      debouncedSearch();
    } else {
      this.setState({ locationSearchResults: null, isSearchingLocations: false });
    }
  };

  checkGeoLocation = text => {
    return fetchLinkedInGeoLocations(text)
      .then(res => res.data)
      .then(searchResult => {
        // Check that result is for latest inputText
        if (this.state.inputText !== text) return;

        // Update results
        this.setState({
          locationSearchResults:
            searchResult.geo_locations.length > 0 ? searchResult.geo_locations : null,
          isSearchingLocations: false,
          noLocationsFound: !searchResult.geo_locations.length,
        });
      })
      .catch(() =>
        this.setState({
          locationSearchResults: null,
          isSearchingLocations: false,
          noLocationsFound: true,
        })
      );
  };

  renderLocationSearch = () => {
    const {
      inputText,
      chosenLocations,
      locationSearchResults,
      isSearchingLocations,
      noLocationsFound,
    } = this.state;
    const isNoResultsVisible =
      !!inputText && !isSearchingLocations && !locationSearchResults && noLocationsFound;

    return (
      <>
        <div
          className={styles.searchBackground}
          onClick={() => this.setState({ inputType: null })}
        ></div>
        <div className={styles.search}>
          <form onSubmit={e => this.onLocationSearchFormSubmit(e, locationSearchResults)}>
            <div className={styles.inputWrapper}>
              <SearchSVG
                className={classnames(styles.searchIcon, {
                  [styles.valid]: !!locationSearchResults?.length,
                })}
              />
              <input
                autoFocus
                type="text"
                className={styles.input}
                onChange={this.handleLocationInputChange}
                value={inputText}
                placeholder="Type location…"
              />

              {isSearchingLocations ? (
                <div className={styles.loading}>
                  <LoadingIndicator />
                </div>
              ) : (
                <button
                  type="button"
                  onClick={() =>
                    this.setState({ inputType: null, inputText: '', locationSearchResults: null })
                  }
                  className={styles.inputCancelButton}
                >
                  <CloseSVG className={styles.cancelIcon} />
                </button>
              )}
            </div>
          </form>

          {locationSearchResults && locationSearchResults.length > 0 && (
            <div className={styles.localeSearchResults}>
              {locationSearchResults
                .filter(resultItem => {
                  return !chosenLocations.find(loc => loc.id === resultItem.id);
                })
                .map(item => (
                  <button
                    key={item.id}
                    type="button"
                    className={styles.localeSearchResult}
                    onClick={() => {
                      this.setState({
                        chosenLocations: [...chosenLocations, item],
                        inputText: '',
                        inputType: null,
                        locationSearchResults: [],
                      });
                    }}
                    title={`${item.name}`}
                  >
                    <span className={styles.localeInfo}>
                      <span className={styles.localeName}>{item.name}</span>
                    </span>
                    <span className={styles.plusSign}>
                      <AddSVG className={styles.plusSignIcon} />
                    </span>
                  </button>
                ))}
            </div>
          )}
          {isNoResultsVisible && this.renderEmptyState('locations')}
        </div>
      </>
    );
  };

  render() {
    const { chosenLocales, chosenLocations, inputType } = this.state;
    const { action } = this.props;

    const closeInput = () =>
      this.setState({ inputType: null, inputText: '', locationSearchResults: null });

    return (
      <div className={styles.targeFilterSelection}>
        <div className={styles.title}>Choose target audience filters</div>
        <div className={styles.typeSelections}>
          {inputType === 'locale' && this.renderLocaleSearch()}
          {inputType === 'location' && this.renderLocationSearch()}
          {['locale', 'location'].includes(inputType) && <OnEscClick eventHandler={closeInput} />}

          {!inputType && (
            <>
              <button
                className={styles.typeSelectionButton}
                onClick={() => this.setState({ inputType: 'locale' })}
              >
                <span className={styles.plusSign}>
                  <AddSVG className={styles.plusSignIcon} />
                </span>{' '}
                Add language
              </button>
              <button
                className={styles.typeSelectionButton}
                onClick={() => this.setState({ inputType: 'location' })}
              >
                <span className={styles.plusSign}>
                  <AddSVG className={styles.plusSignIcon} />
                </span>{' '}
                Add location
              </button>
            </>
          )}
        </div>

        <div className={styles.tags}>
          {chosenLocales?.map(chosenLocale => (
            <div className={styles.tag}>
              {chosenLocale.nativeName} ({chosenLocale.name})
              <button
                onClick={() =>
                  this.setState({
                    chosenLocales: chosenLocales.filter(item => item.code !== chosenLocale.code),
                  })
                }
              >
                &times;
              </button>
            </div>
          ))}

          {chosenLocations?.map(chosenLocation => (
            <div className={styles.tag}>
              {chosenLocation.name}
              <button
                onClick={() =>
                  this.setState({
                    chosenLocations: chosenLocations.filter(item => item.id !== chosenLocation.id),
                  })
                }
              >
                &times;
              </button>
            </div>
          ))}
        </div>

        {(chosenLocales?.length > 0 || chosenLocations?.length > 0) && (
          <ActionButton
            onClick={() => action({ locales: chosenLocales, locations: chosenLocations })}
          >
            Use selected target filters
          </ActionButton>
        )}

        <div className={styles.secondaryAction}>
          <button
            title="Choose this if you want to gather only posts that are not targeted to any language and location."
            onClick={() => {
              action({
                locales: [{ code: 'global', name: 'Global' }],
                locations: [{ id: 'global', name: 'Global' }],
              });
            }}
          >
            Show only posts without target location and language?
          </button>
        </div>
      </div>
    );
  }
}

TargetFilterSelection.propTypes = {
  action: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default TargetFilterSelection;
