import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { STEPS } from 'services/media-trackers/common';
import { SERVICES } from 'constants/Services';

const getConfirmMessagesForFeed = feedConfig => {
  if (!feedConfig) {
    return [];
  }

  const { service, filters } = feedConfig;
  const feedFilter = get(filters, 0, {}); // only one filter per feed

  // # Twitter rules
  if (service === SERVICES.TWITTER) {
    const hasKeywords = !isEmpty(feedFilter.keywords);
    const hasUsernames = !isEmpty(feedFilter.usernames);
    const hasPicturesOnlyFilter = !!feedFilter.pictures_only;
    const hasVideosOnlyFilter = !!feedFilter.videos_only;
    const hasMediaFilter = hasPicturesOnlyFilter || hasVideosOnlyFilter;

    const usernameFilterName = `${(feedFilter.usernames || []).map(name => `@${name}`).join(', ')}`;
    const keywordFilterName = `${(feedFilter.keywords || []).join(', ')}`;

    let withMediaPhrase = '';
    if (hasPicturesOnlyFilter && hasPicturesOnlyFilter) {
      withMediaPhrase = ' with an uploaded image or a video';
    } else if (hasPicturesOnlyFilter) {
      withMediaPhrase = ' with an uploaded image';
    } else if (hasVideosOnlyFilter) {
      withMediaPhrase = ' with a video';
    }

    if (hasKeywords && !hasUsernames) {
      // Keyword
      return [
        <>
          We only have access to the past 7 days of Twitter‘s history, and there doesn’t seem to be
          any content mentioning <strong>{keywordFilterName}</strong>
          {withMediaPhrase}.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Username
    if (!hasKeywords && hasUsernames && !hasMediaFilter) {
      return [
        <>
          There doesn’t seem to be any content published by <strong>{usernameFilterName}</strong>{' '}
          just yet.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Username + Keyword
    if (hasKeywords && hasUsernames && !hasMediaFilter) {
      return [
        <>
          There doesn’t seem to be any content published by <strong>{usernameFilterName}</strong>{' '}
          with a keyword filter <strong>{keywordFilterName}</strong> in the most recent history.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Username + Pictures only
    if (!hasKeywords && hasUsernames && hasMediaFilter) {
      return [
        <>
          There doesn’t seem to be any content published by <strong>{usernameFilterName}</strong>{' '}
          {withMediaPhrase} in the most recent history.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // username + keyword + pictures only
    if (hasKeywords && hasUsernames && hasMediaFilter) {
      return [
        <>
          There doesn’t seem to be any content published by <strong>{usernameFilterName}</strong>{' '}
          {withMediaPhrase} and mentioning <strong>{keywordFilterName}</strong> as a filter in the
          most recent history.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }
  }

  // # Instagram rules
  if (service === SERVICES.INSTAGRAM) {
    const hasKeywords = !isEmpty(feedFilter.keywords);
    const hasUsernames = !isEmpty(feedFilter.usernames);
    const hasAccount = !isEmpty(feedFilter.account_username || feedFilter.account_name);
    const isStoriesFeed = !!feedFilter.include.includes('stories');
    const isMentionsFeed = !!feedFilter.include.includes('public_mentions');

    const accountFilterName = feedFilter.account_username || feedFilter.account_name;
    const keywordFilterName = `${(feedFilter.keywords || []).join(', ')}`;
    const usernamesFilterName = `${(feedFilter.usernames || []).join(', ')}`;

    // Stories (confirmation should not be available for stories, here just as fallback)
    if (isStoriesFeed) {
      return [
        <>There doesn’t seem to be any stories of {accountFilterName} yet.</>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Mentions
    if (isMentionsFeed && !hasKeywords) {
      return [
        <>There doesn’t seem to be any public mentions of {accountFilterName} yet.</>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Mentions + Hashtags
    if (isMentionsFeed && hasKeywords) {
      return [
        <>
          There doesn’t seem to be any public mentions of {accountFilterName} with a keyword filter{' '}
          {keywordFilterName} just yet.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Account
    if (hasAccount && !hasKeywords) {
      return [
        <>There doesn’t seem to be any content published by {accountFilterName} yet.</>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Account + hashtag
    if (hasAccount && hasKeywords) {
      return [
        <>
          There doesn’t seem to be any content published by {accountFilterName} and mentioning{' '}
          {keywordFilterName} in the most recent history.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Hashtag
    if (!hasAccount && hasKeywords && !hasUsernames) {
      return [
        <>There doesn’t seem to be any public content mentioning {keywordFilterName} yet.</>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Hashtag + usernames
    if (!hasAccount && hasKeywords && hasUsernames) {
      return [
        <>
          The most recent history of the {keywordFilterName} feed doesn’t seem to include images and
          videos by {usernamesFilterName}.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }
  }

  // # Facebook rules
  if (service === SERVICES.FACEBOOK) {
    const hasKeywords = !isEmpty(feedFilter.keywords);
    const isAdminPostsFeed = !!feedFilter.include.includes('admin_posts');
    const isVisitorsFeed = !!feedFilter.include.includes('fan_posts');
    const isMentionsFeed = !!feedFilter.include.includes('public_mentions');
    const isRatingsFeed = !!feedFilter.include.includes('ratings');

    const pageFilterName = feedFilter.page_name;
    const keywordFilterName = `${(feedFilter.keywords || []).join(', ')}`;

    // Page admins / Any facebook page
    if (isAdminPostsFeed && !hasKeywords) {
      return [
        <>There doesn’t seem to be any content published by {pageFilterName} yet.</>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Page admins / Any facebook page + Keyword
    if (isAdminPostsFeed && hasKeywords) {
      return [
        <>
          There doesn’t seem to be any content published by {pageFilterName} with a keyword filter{' '}
          {keywordFilterName} in the most recent history.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Page vistors
    if (isVisitorsFeed && !hasKeywords) {
      return [
        <>
          There doesn’t seem to be any content posted on {pageFilterName} by the visitors just yet.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Page vistors + Keyword
    if (isVisitorsFeed && hasKeywords) {
      return [
        <>
          There doesn’t seem to be any content posted on {pageFilterName} by the visitors mentioning
          the keyword {keywordFilterName}.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Mentions
    if (isMentionsFeed && !hasKeywords) {
      return [
        <>
          There doesn’t seem to be any public mentions of {pageFilterName} just yet. Would you still
          like to create the feed and gather all the future content?
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Mentions + Keyword
    if (isMentionsFeed && hasKeywords) {
      return [
        <>
          There doesn’t seem to be any public mentions of {pageFilterName} with a keyword{' '}
          {keywordFilterName} just yet. Would you still like to create the feed and gather all the
          future content?
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Ratings
    if (isRatingsFeed) {
      return [
        <>
          There doesn’t seem to be any reviews of {pageFilterName} just yet. Would you still like to
          create the feed and gather all the future content?
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }
  }

  // # YouTube rules
  if (service === SERVICES.YOUTUBE) {
    const hasKeywords = !isEmpty(feedFilter.keywords);
    const isChannelFeed = feedFilter.type === 'channel';
    const isPlaylistFeed = feedFilter.type === 'playlist';

    const channelFilterName = feedFilter.name;
    const playlistFilterName = feedFilter.name;
    const keywordFilterName = `${(feedFilter.keywords || []).join(', ')}`;

    // Keyword
    if (hasKeywords && !isChannelFeed && !isPlaylistFeed) {
      return [
        <>There doesn’t seem to be any public YouTube videos mentioning {keywordFilterName} yet.</>,
        <>Would you still like to create the feed and gather all the future content</>,
      ];
    }

    // Channel
    if (!hasKeywords && isChannelFeed) {
      return [
        <>There doesn’t seem to be any public videos on {channelFilterName} YouTube channel yet.</>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Channel + Keyword
    if (hasKeywords && isChannelFeed) {
      return [
        <>
          There doesn’t seem to be any public videos on {channelFilterName} YouTube channel
          mentioning a keyword {keywordFilterName} yet.
        </>,
        <> Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Playlist
    if (!hasKeywords && isPlaylistFeed) {
      return [
        <>
          There doesn’t seem to be any public videos on {playlistFilterName} YouTube playlist yet.
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // Playlist + Keyword
    if (hasKeywords && isPlaylistFeed) {
      return [
        <>
          There doesn’t seem to be any public videos on {playlistFilterName} YouTube playlist
          mentioning a keyword {keywordFilterName} yet. Would you still like to create the feed and
          gather all the future content?
        </>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }
  }

  // # Pinterest rules
  if (service === SERVICES.PINTEREST) {
    const authorFilterName = feedFilter.author;
    const boardFilterName = feedFilter.category;

    // User’s Board Pins
    if (boardFilterName) {
      return [
        <>There doesn’t seem to be any public pins stored in {boardFilterName} board yet.</>,
        <>Would you still like to create the feed and gather all the future content?</>,
      ];
    }

    // User’s Pins
    return [
      <>There doesn’t seem to be any public pins by {authorFilterName} yet.</>,
      <>Would you still like to create the feed and gather all the future content?</>,
    ];
  }

  // General fallback message
  return [
    <>There doesn’t seem to be any content available at the moment.</>,
    <>Would you still like to create the feed and gather all the future content?</>,
  ];
};

const ConfirmFeedWithoutHistory = ({ feedConfig, onAccept, onRevertToStart }) => {
  return {
    actions: [
      {
        action: () => onAccept(),
        id: 'confirm_feed_without_history',
        name: 'Yes, create the feed',
      },
      {
        action: () => onRevertToStart(),
        id: 'reject_feed_without_history',
        name: 'No, let’s change feed filters',
      },
    ],
    id: STEPS.CONFIRM_FEED_WITHOUT_HISTORY,
    messages: getConfirmMessagesForFeed(feedConfig),
    visible: false,
  };
};

export default ConfirmFeedWithoutHistory;
