import * as Sentry from '@sentry/browser';
import config from 'config';

export function initializeSentry() {
  const { sentryDsn } = config;
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      environment: process.env.REACT_APP_ENV,
    });
  }
}

export const reportSentryError = (error, payload = {}) => {
  if (Sentry) {
    Sentry.captureException(new Error(error), { extra: payload });
  }
};
