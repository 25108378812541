import React from 'react';
import PropTypes from 'prop-types';

import ActionButton from 'components/ActionButton';
import OptionActionButton from './OptionActionButton';
import ServiceSelectButton from './ServiceSelectButton';
import styles from './UserMessageActions.module.scss';
import classnames from 'classnames';

const PersistentUserMessageActions = React.memo(
  ({ actions, actionButtonType, actionsTitle, actionButtonsDirection, answer, undoAction }) => {
    return (
      <div
        className={classnames(styles[actionButtonsDirection], {
          [styles.serviceSelectWrap]: actionButtonType === 'serviceSelect',
        })}
      >
        {actionsTitle && <div className={styles.actionsTitle}>{actionsTitle}</div>}
        {actions.map(option => {
          const isSelected = answer && answer.value === option.id;
          const isAnswered = answer && !!answer.value;

          const persistentButtonAction = () => {
            // First undo...
            undoAction();

            // ...then main action
            option.action();
          };

          if (actionButtonType === 'serviceSelect') {
            return (
              <ServiceSelectButton
                isAnswered={isAnswered}
                isSelected={isSelected}
                onClick={persistentButtonAction}
                option={option}
                key={option.id}
              />
            );
          }

          if (actionButtonType === 'option') {
            return (
              <OptionActionButton
                isAnswered={isAnswered}
                isSelected={isSelected}
                onClick={persistentButtonAction}
                option={option}
                key={option.id}
              />
            );
          }

          return (
            <ActionButton
              key={option.id}
              onClick={persistentButtonAction}
              isSelected={isSelected}
              isInactive={isAnswered && !isSelected}
            >
              {option.name}
            </ActionButton>
          );
        })}
      </div>
    );
  }
);

PersistentUserMessageActions.defaultProps = {
  actions: [],
  actionsTitle: undefined,
  actionButtonType: undefined,
  actionButtonsDirection: undefined,
  answer: null,
};

PersistentUserMessageActions.propTypes = {
  actions: PropTypes.array.isRequired,
  actionsTitle: PropTypes.string,
  actionButtonType: PropTypes.string,
  actionButtonsDirection: PropTypes.string,
  answer: PropTypes.object,
  undoAction: PropTypes.func.isRequired,
};

export default PersistentUserMessageActions;
