import React from 'react';
import { ReactComponent as ExternalLinkSVG } from 'assets/svgs/icon-external-link.svg';
import styles from './LearnMoreLink.module.scss';

const LearnMoreLink = ({ children, ...rest }) => (
  <>
    ({' '}
    <a {...rest} target="_blank" rel="noopener noreferrer">
      {children || 'Learn more'}
      <ExternalLinkSVG className={styles.icon} />
    </a>
    )
  </>
);

export default LearnMoreLink;
