import React from 'react';

import { getConversationState } from 'services/conversation-updater';
import { QUESTION_IDS as QUESTION } from 'services/media-trackers/tiktok';
import { SERVICES } from 'constants/Services';
import { fetchTokenService } from 'services/api';
import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';
import { helpScoutMessage } from 'utils/help-scout';

class ConversationAboutTiktok extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoadingToken: false };
  }

  connectCallback = () => {
    const { answerQuestion, selectedAccount } = this.props;
    this.setState({ isLoadingToken: true });

    return fetchTokenService('tiktok')
      .then(response => {
        const tokens = response?.data || [];

        const accountHasValidToken = tokens.some(
          token => token.channelUserId === selectedAccount.external_user_id
        );

        this.setState({ isLoadingToken: false });

        return answerQuestion({
          response: {
            value: true,
            text: 'Valid token',
          },
          questionId: QUESTION.CHECKING_TOKEN,
          nextId: accountHasValidToken ? null : QUESTION.INVALID_TOKEN,
        });
      })
      .catch(error => {
        this.setState({ isLoadingToken: false });

        return answerQuestion({
          response: {
            value: false,
            text: 'Invalid Token',
          },
          questionId: QUESTION.CHECKING_TOKEN,
          nextId: QUESTION.INVALID_TOKEN,
        });
      });
  };

  reconnectAccount = () => {
    const { getConnectAction } = this.props;

    const reconnect = getConnectAction(
      QUESTION.ACCOUNT_CONNECTED_ALREADY,
      QUESTION.CHECKING_TOKEN,
      null,
      true
    );
    reconnect();
  };

  getConversationTemplate = () => {
    const { createAccountConnectQuestion, getConnectAction, initialAccountCount } = this.props;

    return [
      // Question for user who has no accounts yet for service
      createAccountConnectQuestion({
        actions: [
          {
            action: getConnectAction(QUESTION.NO_ACCOUNT_CONNECTED_YET, QUESTION.ACCOUNT_DETAILS),
            id: QUESTION.CONNECT_ACCOUNT,
            name: 'Connect TikTok account…',
          },
        ],
        messages: [
          'TikTok is everyone’s new favourite 😍',
          <>
            To gather content from TikTok, you’ll need to connect your account with Flockler. Don’t
            worry, we don’t post anything to your TikTok account.
            <br />
            <br />
            <strong>
              After you have connected the account, you can display your latest TikTok videos on
              websites and other digital services.
            </strong>
          </>,
        ],
        nextId: QUESTION.CHECKING_TOKEN,
        visible: initialAccountCount === 0,
        callback: this.connectCallback,
        questionId: QUESTION.NO_ACCOUNT_CONNECTED_YET,
      }),

      createAccountConnectQuestion({
        actions: [],
        messages: [],
        nextId: QUESTION.CHECKING_TOKEN,
        visible: initialAccountCount !== 0,
        callback: this.connectCallback,
        questionId: QUESTION.ACCOUNT_CONNECTED_ALREADY,
        shouldBeReverted: true,
      }),

      // "Ghost" question when waiting for pages to load
      {
        id: QUESTION.CHECKING_TOKEN,
        nextId: null,
        isLoading: true,
        isUserReplyDisabled: true,
        isBotMessageDisabled: !this.state.isLoadingToken,
        messages: [],
        visible: false,
      },

      {
        id: QUESTION.INVALID_TOKEN,
        nextId: null,
        isUserReplyDisabled: true,
        messages: [
          'Your TikTok account seems to be expired.',
          <span>
            Please <button onClick={this.reconnectAccount}>reconnect your TikTok account</button> or{' '}
            <button
              onClick={() =>
                helpScoutMessage({ subject: 'I have problems with creating a TikTok feed.' })
              }
            >
              chat with us
            </button>{' '}
            if problem persists.
          </span>,
        ],
        visible: false,
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

export default serviceConversation(ConversationAboutTiktok, {
  service: SERVICES.TIKTOK,
});
