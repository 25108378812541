import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import NotFoundImg from 'assets/img/not-found.png';
import { parseRelativeTimeText } from 'services/relative-time';
import styles from './PreviewItem.module.scss';

const ImgWithFallback = ({ src, ...rest }) => {
  const [imgSrc, setImgSrc] = React.useState(src);

  return <img src={imgSrc} {...rest} alt="" onError={() => setImgSrc(NotFoundImg)} />;
};

const PreviewItem = ({ author, title, body, cover_url, published_at, url }) => {
  const isFooterVisible = !!author || !!published_at;

  return (
    <div className={classnames(styles.previewItem, { [styles.hasFooter]: isFooterVisible })}>
      {cover_url && <ImgWithFallback src={cover_url} className={styles.image} />}

      <div className={styles.content}>
        {title && <div className={styles.title}>{title}</div>}
        {body && <div className={styles.text} dangerouslySetInnerHTML={{ __html: body }} />}
        {url && (
          <a href={url} className={styles.readMoreLink} target="_blank" rel="noopener noreferrer">
            Read more…
          </a>
        )}
      </div>

      {isFooterVisible && (
        <div className={styles.footer}>
          {author && <span className={styles.author}>{author}</span>}
          {published_at && (
            <span className={styles.timestamp}>{parseRelativeTimeText(published_at)}</span>
          )}
        </div>
      )}
    </div>
  );
};

PreviewItem.defaultProps = {
  author: undefined,
  body: undefined,
  cover_url: undefined,
  published_at: undefined,
  title: undefined,
  url: undefined,
};

PreviewItem.propTypes = {
  author: PropTypes.string,
  body: PropTypes.string,
  cover_url: PropTypes.string,
  published_at: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

export default PreviewItem;
