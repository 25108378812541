import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Router } from '@reach/router';

import { useConsent } from 'services/cookie-policy';
import { initializeAnalytics } from 'services/analytics';
import { initializeApp } from 'concepts/app';
import { getLatestError } from 'concepts/error';
import { getIsFromEu } from 'concepts/i18n';
import ErrorView from 'containers/ErrorView';
import SiteLoading from 'containers/SiteLoading';

import SiteUrl from 'containers/SiteUrl';
import FeedConversation from 'containers/FeedConversation';
import SectionRoute from 'containers/SectionRoute';
import ReconnectView from 'containers/reconnect/ReconnectView';
import UpdateSectionView from 'containers/update-section/UpdateSectionView';

const FeedConversationWithSection = SectionRoute(FeedConversation);

const App = ({ initializeApp, isFromEu, latestError }) => {
  const [consent] = useConsent();
  const hasAnalyticsConsent = consent && consent.analytics;
  const nonEuWithoutConsent = isFromEu === false && !consent;

  useEffect(() => {
    if (nonEuWithoutConsent || hasAnalyticsConsent) {
      initializeAnalytics();
    }
  }, [hasAnalyticsConsent, nonEuWithoutConsent]);

  useEffect(() => {
    initializeApp();
  }, []);

  if (latestError) {
    return <ErrorView />;
  }

  return (
    <>
      <Router>
        <SiteLoading path="/" />
        <SiteUrl path="/:siteUrl">
          <FeedConversation path="/" />
          <FeedConversationWithSection path="/walls/:sectionId" />
          <ReconnectView path="/reconnect/:feedId" />
          <UpdateSectionView path="/update-section/:feedId" />
        </SiteUrl>
      </Router>
    </>
  );
};

const mapStateToProps = state => ({
  isFromEu: getIsFromEu(state),
  latestError: getLatestError(state),
});

const mapDispatchToProps = { initializeApp };

export default connect(mapStateToProps, mapDispatchToProps)(App);
