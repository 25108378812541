import capitalize from 'lodash/capitalize';
import config from 'config';
import { SERVICES } from 'constants/Services';

// # Open service connect modal
// Returns promise which will be:
// resolved when postMessage is received
// rejected if postMessage origin is not from flocklerUrl
function openServiceConnectModal(url, service) {
  return new Promise(function (resolve, reject) {
    const w = 800;
    const h = 650;
    const left = Math.max(window.screen.width / 2 - w / 2, 0);
    const top = Math.max(window.screen.height / 2 - h / 2, 0);

    const serviceConnectWindow = window.open(
      url,
      'serviceConnect',
      `width=${w},height=${h},top=${top},left=${left},toolbar=0,menubar=0,location=0,status=0`
    );

    // This function is from postMessage event
    function receiveMessage(event) {
      const isTrustedOrigin =
        event.origin === config.flocklerUrl || event.origin === config.newsroomUrl;
      const isValidEventName = event.data.event === getValidEventName(service);
      if (isTrustedOrigin && isValidEventName) {
        // Remove listener
        window.removeEventListener('message', receiveMessage);

        // -> Flockler connection automatically closes window
        resolve(event.data);
      }
    }

    // This is used for removing event listeners after closing popup
    var windowFocus = function () {
      // remove event listeners if window is closed!
      if (serviceConnectWindow === null || serviceConnectWindow.closed) {
        window.removeEventListener('focus', windowFocus);
        window.removeEventListener('message', receiveMessage);
      }
    };

    // Add event listener
    window.addEventListener('focus', windowFocus);
    window.addEventListener('message', receiveMessage);
  });
}

function createConnectUrl(service) {
  let serviceName = service;
  if (serviceName === SERVICES.INSTAGRAM) {
    serviceName = 'facebook?connection_type=instagram_graph_api';
  }

  if (serviceName === SERVICES.GOOGLE_REVIEW) {
    serviceName = 'googlemybusiness';
  }

  if (serviceName === SERVICES.INSTAGRAM_BASIC) {
    serviceName = 'instagram';
  }

  return `${config.flocklerUrl}/connect/${serviceName}`;
}

function getValidEventName(service) {
  let serviceName = service;
  if (serviceName === SERVICES.INSTAGRAM) {
    serviceName = 'facebook';
  }

  if (serviceName === SERVICES.GOOGLE_REVIEW) {
    serviceName = 'googlemybusiness';
  }

  if (serviceName === SERVICES.INSTAGRAM_BASIC) {
    serviceName = 'instagram';
  }

  const serviceCapitalized = capitalize(serviceName);

  return `connectService${serviceCapitalized}Success`;
}

export function connectAccount(service) {
  const connectUrl = createConnectUrl(service);
  return openServiceConnectModal(connectUrl, service);
}
