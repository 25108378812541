import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { helpScoutMessage } from 'utils/help-scout';
import styles from './Header.module.scss';
import { ReactComponent as ArrowRightSVG } from 'assets/svgs/icon-arrow-right-circle.svg';
import { ReactComponent as HelpSVG } from 'assets/svgs/icon-help-circle-filled.svg';

const Header = ({ continueToAppUrl, children, ...rest }) => (
  <div className={styles.header} {...rest}>
    <div className={styles.headerContent}>
      <button
        className={classnames(styles.actionLink, styles.actionLinkLeft)}
        onClick={() => helpScoutMessage({ subject: `Help with automated feed.` })}
        title="Help"
        aria-label="Help"
      >
        <HelpSVG className={styles.actionLinkIcon} />
        <span className={styles.actionLinkText}>Help</span>
      </button>

      <div className={styles.headerTitle}>{children}</div>

      {!!continueToAppUrl && (
        <a
          className={classnames(styles.actionLink, styles.actionLinkRight)}
          href={continueToAppUrl}
          title="Continue to App"
          aria-label="Continue to App"
        >
          <span className={styles.actionLinkText}>Continue to App</span>
          <ArrowRightSVG className={styles.actionLinkIcon} />
        </a>
      )}
    </div>
  </div>
);

Header.defaultProps = {
  continueToAppUrl: null,
  children: null,
};

Header.propTypes = {
  continueToAppUrl: PropTypes.string,
  clildren: PropTypes.node,
};

export default Header;
