import { createSelector } from 'reselect';

// Creates selector function with map function
// This can be used for transforming different services API responses
// to standardized "app format" with fromDto functions
export const createDataMapSelector = mapFn => selectorFn =>
  createSelector(
    selectorFn,
    selectedData => selectedData.map(mapFn)
  );
