// # Media tracker concepts

import { createSelector } from 'reselect';
import get from 'lodash/get';
import * as api from 'services/api';
import { getSiteId } from 'concepts/site';
import { reportSentryError } from 'services/sentry';

// Action Types
export const ACTIONS = Object.freeze({
  CREATE_MT: 'MT/CREATE_MT',
  CREATE_MT_SUCCESS: 'MT/CREATE_MT_SUCCESS',
  CREATE_MT_FAIL: 'MT/CREATE_MT_FAIL',

  CREATE_MT_HISTORY_PREVIEW: 'MT/CREATE_MT_HISTORY_PREVIEW',
  CREATE_MT_HISTORY_PREVIEW_SUCCESS: 'MT/CREATE_MT_HISTORY_PREVIEW_SUCCESS',
  CREATE_MT_HISTORY_PREVIEW_FAIL: 'MT/CREATE_MT_HISTORY_PREVIEW_FAIL',

  FETCH_MTS: 'MT/FETCH_MTS',
  FETCH_MTS_SUCCESS: 'MT/FETCH_MTS_SUCCESS',
  FETCH_MTS_FAIL: 'MT/FETCH_MTS_FAIL',

  UPDATE_MT_SUCCESS: 'MT/UPDATE_MT_SUCCESS',
});

// Selectors
export const getMediaTrackers = state => state.mediaTracker.mediaTrackers;
export const getMediaTrackersLoadingState = state => state.mediaTracker.isLoadingMts;
export const getHistoryPreviewResult = state => state.mediaTracker.historyPreview;
export const getHistoryPreviewLoadingState = state => state.mediaTracker.isLoadingHistoryPreview;

export const getEnabledMediaTrackerCount = createSelector(
  getMediaTrackers,
  mediaTrackers => mediaTrackers.filter(mt => mt.media_tracker_setting.enabled).length
);

export const getPreviewItemsCount = createSelector(
  getHistoryPreviewResult,
  previewResult => previewResult.count
);

export const getPreviewKey = createSelector(
  getHistoryPreviewResult,
  previewResult => previewResult.preview_key
);

// Action Creators

// @todo
// HOX in theory there is chance that one request is going on when another starts and it gets ready
// while latter one is still loading. This should be tackled with mechanism that keeps track of ongoing
// requests. Creating and storing unique id for request is one option, and checking with that repsponse is for latest request.
export const createHistoryPreview = previewMt => (dispatch, getState) => {
  // Start action
  dispatch({ type: ACTIONS.CREATE_MT_HISTORY_PREVIEW });

  const siteId = getSiteId(getState());

  if (!siteId) {
    return dispatch({ type: ACTIONS.CREATE_MT_HISTORY_PREVIEW_FAIL });
  }

  return api
    .postMediaTrackerHistoryPreview(siteId, previewMt)
    .then(response =>
      dispatch({
        type: ACTIONS.CREATE_MT_HISTORY_PREVIEW_SUCCESS,
        payload: response.data.history_preview,
      })
    )
    .catch(error => {
      dispatch({ type: ACTIONS.CREATE_MT_HISTORY_PREVIEW_FAIL });
      reportSentryError('History preview failed', {
        media_tracker: JSON.stringify(previewMt),
        site_id: siteId,
        error: JSON.stringify(get(error, 'response.data')) || error,
      });
      return Promise.reject(error);
    });
};

export const createMediaTracker = mtObject => (dispatch, getState) => {
  // Start action
  dispatch({
    type: ACTIONS.CREATE_MT,
  });

  const siteId = getSiteId(getState());

  if (!siteId) {
    dispatch({
      type: ACTIONS.CREATE_MT_FAIL,
    });
    return Promise.reject('no site id found');
  }

  // Post media tracker object
  return api
    .postMediaTracker(siteId, mtObject)
    .then(response => {
      dispatch({
        type: ACTIONS.CREATE_MT_SUCCESS,
        payload: response.data,
      });

      return Promise.resolve(response.data);
    })
    .catch(error => {
      dispatch({
        type: ACTIONS.CREATE_MT_FAIL,
      });

      return Promise.reject(error);
    });
};

export const updateMediaTracker = (mtId, update) => (dispatch, getState) => {
  const siteId = getSiteId(getState());

  return api
    .updateMediaTracker(siteId, mtId, { media_tracker: update })
    .then(response => dispatch({ type: ACTIONS.UPDATE_MT_SUCCESS, payload: response.data }));
};

// This does not currently have redux actions, because there is no need
export const fetchMediaTracker = mtId => (dispatch, getState) => {
  const siteId = getSiteId(getState());

  if (!siteId) {
    return Promise.reject();
  }

  return api.fetchMediaTracker(siteId, mtId);
};

export const fetchMediaTrackers = () => (dispatch, getState) => {
  const siteId = getSiteId(getState());

  if (!siteId) {
    return Promise.reject();
  }

  dispatch({ type: ACTIONS.FETCH_MTS });

  return api
    .fetchMediaTrackers(siteId)
    .then(({ data }) => dispatch({ type: ACTIONS.FETCH_MTS_SUCCESS, payload: data }))
    .catch(() => dispatch({ type: ACTIONS.FETCH_MTS_FAIL }));
};

// Reducer
export const initialState = Object.freeze({
  mediaTrackers: [],
  isLoadingMts: false,
  historyPreview: {},
  isLoadingHistoryPreview: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.CREATE_MT_HISTORY_PREVIEW: {
      return {
        ...state,
        historyPreview: {},
        isLoadingHistoryPreview: true,
      };
    }

    case ACTIONS.CREATE_MT_HISTORY_PREVIEW_SUCCESS: {
      return {
        ...state,
        historyPreview: action.payload,
        isLoadingHistoryPreview: false,
      };
    }

    case ACTIONS.CREATE_MT_HISTORY_PREVIEW_FAIL: {
      return {
        ...state,
        historyPreview: {},
        isLoadingHistoryPreview: false,
      };
    }

    case ACTIONS.CREATE_MT_SUCCESS: {
      return {
        ...state,
        mediaTrackers: [...state.mediaTrackers, action.payload],
      };
    }

    case ACTIONS.FETCH_MTS: {
      return {
        ...state,
        isLoadingMts: true,
        mediaTrackers: [],
      };
    }

    case ACTIONS.FETCH_MTS_SUCCESS: {
      return {
        ...state,
        isLoadingMts: false,
        mediaTrackers: action.payload,
      };
    }

    case ACTIONS.FETCH_MTS_FAIL: {
      return {
        ...state,
        isLoadingMts: false,
      };
    }

    case ACTIONS.UPDATE_MT_SUCCESS: {
      return {
        ...state,
        mediaTrackers: state.mediaTrackers.map(mt => {
          if (mt.id === action.payload.id) {
            return action.payload;
          }

          return mt;
        }),
      };
    }

    default: {
      return state;
    }
  }
}
