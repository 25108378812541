import React from 'react';
import Question from 'components/Question';

const ConversationMessageList = React.memo(({ conversation, revertToQuestion }) => (
  <React.Fragment>
    {conversation
      .filter(question => question.visible)
      .map(question => (
        <Question
          key={question.id}
          question={question}
          revertToQuestion={() => revertToQuestion(question.id)}
        />
      ))}
  </React.Fragment>
));

export default ConversationMessageList;
