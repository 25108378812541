// Service to use Google Analytics
import ReactGA from 'react-ga';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import toString from 'lodash/toString';
import config from 'config';

const gaCode = config.feedsGaCode;
const isValidGaCode = !!gaCode && gaCode !== 'UA-00000000-00';
let hasInitializedAnalytics = false;

// Initialize React Google Analytics
export const initializeAnalytics = () => {
  if (!isValidGaCode) {
    console.log('[Analytics] Not tracking GA');
    return null;
  }

  if (hasInitializedAnalytics) {
    return;
  }

  hasInitializedAnalytics = true;

  ReactGA.initialize(gaCode);
  pageView(window.location.pathname + window.location.search);
};

export const trackEvent = opts => {
  if (!isValidGaCode) {
    return null;
  }

  // Stringify possible objects from event data
  const eventOpts = mapValues(opts, value => {
    if (isObject(value)) {
      return JSON.stringify(value);
    }

    return toString(value);
  });

  return ReactGA.event(eventOpts);
};

export const trackCustomEvent = (category, action, label) =>
  trackEvent({ category, action, label });

export const pageView = path => {
  if (!isValidGaCode) {
    return null;
  }

  return ReactGA.pageview(path);
};
