import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { useConsent } from 'services/cookie-policy';
import { getSite } from 'concepts/site';
import { getUser } from 'concepts/user';
import { getIsFromEu } from 'concepts/i18n';
import { initializeSiteAndMts, getSiteInitializationStatus } from 'concepts/app';
import loadHelpScout from 'services/load-help-scout';
import loadTrackingGtm from 'services/load-tracking-gtm';
import CookiePopup from 'components/CookiePopup';
import PageLoader from 'components/PageLoader';
import { pushToGtmDataLayer } from 'utils/gtm-push';
import HelpScout from 'components/HelpScout';

const Site = ({
  children,
  initializeSiteAndMts,
  isInitializing,
  isFromEu,
  site,
  siteUrl,
  user,
}) => {
  const [consent] = useConsent();
  const [isHelpScoutAdded, setIsHelpScoutAdded] = useState(false);
  const hasCustomerSupportConsent = consent && consent.customer_support;
  const nonEuWithoutConsent = isFromEu === false && !consent;
  const canLoadHelpScout = hasCustomerSupportConsent || nonEuWithoutConsent;

  useEffect(() => {
    if (!site) {
      initializeSiteAndMts(siteUrl);
    }
  }, [initializeSiteAndMts, site, siteUrl]);

  useEffect(() => {
    if (!isHelpScoutAdded && canLoadHelpScout) {
      loadHelpScout();
      setIsHelpScoutAdded(true);
    }
  }, [isHelpScoutAdded, canLoadHelpScout]);

  // useEffect(() => {
  //     if (user && user.id && site && site.uuid) {
  //       pushToGtmDataLayer({_customerIoUserId: `${site.uuid}-${user.id}`, _flocklerUserEmail: `${user.email}`})
  //       loadTrackingGtm(true, consent);
  //     }
  // }, [site, user]);

  if (!site || isInitializing) {
    return <PageLoader />;
  }

  return (
    <React.Fragment>
      {isHelpScoutAdded && !isEmpty(user) && canLoadHelpScout && <HelpScout user={user} />}
      {!consent && isFromEu && <CookiePopup />}

      {children}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isInitializing: getSiteInitializationStatus(state),
  isFromEu: getIsFromEu(state),
  site: getSite(state),
  user: getUser(state),
});

const mapDispatchToProps = { initializeSiteAndMts };

export default connect(mapStateToProps, mapDispatchToProps)(Site);
