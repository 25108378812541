export function getConversationState(conversationTemplate, answers) {
  return conversationTemplate.map(question => {
    if (!answers || !answers.length) {
      return question;
    }

    // Update responses for already answered questions
    const matchingAnswer = answers.find(({ questionId }) => questionId === question.id);
    if (matchingAnswer) {
      return { ...question, response: matchingAnswer.response, visible: true };
    }

    // Update next question visibility
    const matchingNextQuestion = answers.find(({ nextId }) => nextId && nextId === question.id);
    if (matchingNextQuestion) {
      return { ...question, visible: true };
    }

    return question;
  });
}
