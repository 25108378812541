import React from 'react';

import { getConversationState } from 'services/conversation-updater';
import { QUESTION_IDS as QUESTION } from 'services/media-trackers/pinterest';
import { SERVICES } from 'constants/Services';
import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';
import PinterestLinkInput from 'components/PinterestLinkInput';

const EMPTY_RESPONSE = { value: null, text: null };

class ConversationAboutPinterest extends React.Component {
  getConversationTemplate = () => {
    const { answerQuestion } = this.props;

    return [
      // Add new content source
      {
        input: (
          <PinterestLinkInput
            action={({ board, user }) => {
              answerQuestion({
                response: {
                  value: { board, user },
                  text: board ? `Pins from board: ${board}` : `Pins from user: ${user}`,
                },
                questionId: QUESTION.ADD_SOURCE,
              });
            }}
            placeholder="https://pinterest.com/username/board-name/"
          />
        ),

        id: QUESTION.ADD_SOURCE,
        messages: [
          'Pinterest, got it! 📌',
          ' Would you like to display pins by a user or pins from a specific board?',
        ],
        response: EMPTY_RESPONSE,
        visible: true,
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

export default serviceConversation(ConversationAboutPinterest, {
  service: SERVICES.PINTEREST,
});
