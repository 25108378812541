import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash/get';
import trim from 'lodash/trim';
import toLower from 'lodash/toLower';
import LoadingIndicator from 'components/LoadingIndicator';

import UserMessageActionCard from 'components/UserMessageActionCard';
import { checkUsername, checkPinterestBoard } from 'services/api';
import { ReactComponent as ExternalLinkSVG } from 'assets/svgs/icon-link.svg';
import { parsePinterestUrl, parsePinterestUser } from 'services/pinterest-validation';
import styles from './PinterestLinkInput.module.scss';

class PinterestLinkInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: '',
      isInvalidUsername: false,
      isInvalidBoard: false,
      isCheckingInput: false,
    };
  }

  handleInputChange = e => {
    this.setState({
      inputText: e.target.value,
      isInvalidUsername: false,
      isInvalidBoard: false,
      isCheckingInput: false,
    });
  };

  submitForm = ({ user, board }) => {
    const { action } = this.props;
    this.setState({ isCheckingInput: true });

    // # Validate Board
    if (board) {
      return checkPinterestBoard(user, board)
        .then(response => {
          this.setState({ isCheckingInput: false });
          const isValidBoard = get(response, ['data', 'board', 'code']) === '200';

          if (isValidBoard) {
            return action({ user, board });
          }

          this.setState({ isInvalidBoard: true });
        })
        .catch(() => this.setState({ isInvalidBoard: true, isCheckingInput: false }));
    }

    // # Validate Username
    return checkUsername('pinterest', user)
      .then(response => {
        this.setState({ isCheckingInput: false });
        // Check API returns in lowercase
        const isValidUsername =
          get(response, ['data', 'users', user, 'code']) === '200' ||
          get(response, ['data', 'users', toLower(user), 'code']) === '200';

        if (isValidUsername) {
          return action({ user, board });
        }

        this.setState({ isInvalidUsername: true });
      })
      .catch(() => this.setState({ isInvalidUsername: true, isCheckingInput: false }));
  };

  maybeSubmit = (e, { user, board }) => {
    e.preventDefault();

    // Board is preferred if set
    if (board) {
      this.submitForm({ user, board });
      return;
    }

    if (user) {
      this.submitForm({ user });
    }
  };

  renderErrorMessage = () => (
    <span className={styles.inputValidationError}>Please enter a Pinterest URL</span>
  );

  render() {
    const { inputText, isCheckingInput, isInvalidBoard, isInvalidUsername } = this.state;
    const { action, ...rest } = this.props;

    // check validity and parse parts from url
    const { board, user } = parsePinterestUrl(inputText) || { user: parsePinterestUser(inputText) };
    const isContainingSpaces = trim(inputText).indexOf(' ') >= 0;
    const hasBoardOrUser = !!board || !!user;
    const isLinkValid = !isContainingSpaces && hasBoardOrUser;
    const isUrlErrorMessageVisible = inputText.length > 0 && !isLinkValid;
    const isActionButtonsVisible =
      !isCheckingInput && !isInvalidUsername && !isInvalidBoard && !!user && !isContainingSpaces;

    return (
      <UserMessageActionCard
        title={
          <span>
            Add the URL of a user or board
            {isUrlErrorMessageVisible ? this.renderErrorMessage() : ''}
          </span>
        }
      >
        <form onSubmit={e => this.maybeSubmit(e, { user, board })}>
          <div className={styles.wrapper}>
            <ExternalLinkSVG
              className={classnames(styles.linkIcon, { [styles.valid]: isLinkValid })}
            />
            <input
              autoFocus
              type="text"
              className={styles.input}
              onChange={this.handleInputChange}
              value={inputText}
              {...rest}
            />
          </div>
        </form>

        {isInvalidUsername && user && (
          <span className={classnames(styles.footer, styles.submitErrorMessage)}>
            User "{user}" not found in Pinterest. Please check URL.
          </span>
        )}

        {isInvalidBoard && board && (
          <span className={classnames(styles.footer, styles.submitErrorMessage)}>
            Board "{board}" from user "{user}" not found in Pinterest. Please check that board is
            public and URL is correct.
          </span>
        )}

        {isCheckingInput && (
          <span className={classnames(styles.footer, styles.loading)}>
            <LoadingIndicator />
          </span>
        )}

        {isActionButtonsVisible && (
          <div className={styles.footer}>
            <button
              className={styles.actionButton}
              onClick={() => this.submitForm({ user })}
              title={`From user: ${user}`}
            >
              Add user: <span className={styles.highlight}>{user}</span>
            </button>

            {!!board && (
              <button
                className={styles.actionButton}
                onClick={() => this.submitForm({ user, board })}
                title={`From board: ${board}`}
              >
                Add board: <span className={styles.highlight}>{board}</span>
              </button>
            )}
          </div>
        )}
      </UserMessageActionCard>
    );
  }
}

PinterestLinkInput.propTypes = {
  action: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default PinterestLinkInput;
