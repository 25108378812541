import React from 'react';

import Header from 'components/Header';
import styles from './AppContainer.module.scss';

const AppContainer = ({ continueToAppUrl, children, title, ...rest }) => (
  <div className={styles.appContainer} {...rest}>
    <Header continueToAppUrl={continueToAppUrl}>{title}</Header>

    <div className={styles.content}>{children}</div>
  </div>
);

export default AppContainer;
