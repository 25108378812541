import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ActionButton from 'components/ActionButton';
import { ReactComponent as CheckmarkSvg } from 'assets/icons/icon-checkmark.svg';
import styles from './UserMessageActions.module.scss';

const OptionActionButton = ({ isSelected, isAnswered, option, ...rest }) => {
  return (
    <div
      className={classnames(styles.option, {
        [styles.selected]: isSelected,
        [styles.answered]: isAnswered,
      })}
    >
      <span className={styles.checkmark}>
        <CheckmarkSvg />
      </span>
      <ActionButton isSelected={isSelected} {...rest} className={styles.optionActionButton}>
        <span className={styles.optionIcon}>{option.icon}</span>
        <span>
          <span className={styles.optionTitle}>{option.name}</span>
          <span className={styles.optionDescription}>{option.description}</span>
        </span>
      </ActionButton>
    </div>
  );
};

OptionActionButton.defaultProps = {
  isSelected: false,
  isAnswered: false,
  option: {},
};

OptionActionButton.propTypes = {
  isSelected: PropTypes.bool,
  isAnswered: PropTypes.bool,
  option: PropTypes.shape({
    messages: PropTypes.array,
    icon: PropTypes.any,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default OptionActionButton;
