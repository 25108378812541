import axios from 'axios';
import config from 'config';
import isNil from 'lodash/isNil';

const GLOBAL_TIMEOUT = 30000;

export const PATHS = Object.freeze({
  CHECK_PINTEREST_BOARD: '/check-pinterest-board',
  CHECK_USERNAME: service => `/check-username/${service}`,
  DEMO_REVIEWS: siteId => `/sites/${siteId}/demo-reviews`,
  DPA_REVIEWS: siteId => `/sites/${siteId}/dpa/reviews`,
  FACEBOOK_PAGES: '/facebook/user/pages',
  FACEBOOK_PAGE_SEARCH: '/facebook/search/page',
  FEATURE_FLAGS: siteId => `/feature_flags/?site_id=${siteId}`,
  FEED_PREVIEW: '/utils/feed_preview',
  INSTAGRAM_BUSINESS_DISCOVERY: username => `/instagram/business-discovery/${username}`,
  LINKEDIN_COMPANIES: '/linkedin/search/companies',
  LINKEDIN_ORGANIZATIONS: '/linkedin/organizations',
  LINKEDIN_GEO_LOCATIONS: '/linkedin/geo_locations',
  MEDIA_TRACKER_ACCOUNTS: siteId => `/sites/${siteId}/media_tracker_accounts`,
  MEDIA_TRACKER: (siteId, mediaTrackerId) => `/sites/${siteId}/media_trackers/${mediaTrackerId}`,
  MEDIA_TRACKERS: siteId => `/sites/${siteId}/media_trackers`,
  MEDIA_TRACKERS_HISTORY_PREVIEW: siteId => `/sites/${siteId}/media_trackers/history_preview`,
  REVIEWS_SERVICE: siteId => `/sites/${siteId}/reviews_service`,
  REVIEWS_SERVICE_EMAIL_EMBED_CODE: siteId => `/sites/${siteId}/reviews_service/email_embed_code`,
  STATUS: '/status',
  SITE: siteId => `/sites/${siteId}`,
  SITES: '/sites',
  SITE_COMPLETE_SIGNUP: siteId => `/sites/${siteId}/complete_signup`,
  SITE_SECTIONS: siteId => `/sites/${siteId}/sections`,
  SITE_SETTINGS: siteId => `/sites/${siteId}/settings`,
  SITE_SUBSCRIPTION: siteId => `/sites/${siteId}/subscription`,
  SOCIAL_MEDIA_ACCOUNTS: '/social_media_accounts',
  TWITTER_USER_SEARCH: '/twitter/user-search',
  TOKEN_SERVICE: channel => `/token-service/${channel}`,
  USER: '/me',
  VALIDATE_FEED_URL: '/validations/feed_url',
  YOUTUBE_CHANNEL_SEARCH: '/youtube/search/combo',
});

const client = axios.create({
  baseURL: config.privateApiUrl,
  timeout: GLOBAL_TIMEOUT,
  withCredentials: true,
});

const CsrfToken = {
  _token: '',

  get() {
    return this._token;
  },

  set(token) {
    this._token = token;
  },
};

function saveCsrfToken(token) {
  return Promise.resolve(CsrfToken.set(token));
}

client.interceptors.request.use(config => {
  config.headers['X-CSRF-TOKEN'] = CsrfToken.get();
  return config;
});

/*
 * public
 */
export const checkUsername = (service, username) => {
  return client.get(PATHS.CHECK_USERNAME(service), {
    params: { usernames: username },
  });
};

export const checkPinterestBoard = (username, board) => {
  return client.get(PATHS.CHECK_PINTEREST_BOARD, {
    params: { username, board },
  });
};

export const fetchFacebookPages = socialMediaAccountId => {
  return client.get(PATHS.FACEBOOK_PAGES, {
    params: { social_media_account_id: socialMediaAccountId },
  });
};

export const fetchFacebookPagesWithInstagramAccess = socialMediaAccountId => {
  return client.get(PATHS.FACEBOOK_PAGES, {
    params: { social_media_account_id: socialMediaAccountId, only_instagram: 1 },
  });
};

export const fetchInstagramBusinessAccounts = (username, socialMediaAccountId) => {
  return client.get(PATHS.INSTAGRAM_BUSINESS_DISCOVERY(username), {
    params: !isNil(socialMediaAccountId) ? { social_media_account_id: socialMediaAccountId } : {},
  });
};

export const fetchFacebookPageSearch = (socialMediaAccountId, searchTerm) => {
  return client.get(PATHS.FACEBOOK_PAGE_SEARCH, {
    params: { social_media_account_id: socialMediaAccountId, q: searchTerm },
  });
};

export const fetchLinkedinCompanies = socialMediaAccountId => {
  return client.get(PATHS.LINKEDIN_COMPANIES, {
    params: { social_media_account_id: socialMediaAccountId },
  });
};

export const fetchLinkedInOrganizations = () => {
  return client.get(PATHS.LINKEDIN_ORGANIZATIONS);
};

export const fetchLinkedInGeoLocations = query => {
  return client.get(PATHS.LINKEDIN_GEO_LOCATIONS, {
    params: { query },
  });
};

export const fetchSiteMediaTrackerAccounts = siteId => {
  return client.get(PATHS.MEDIA_TRACKER_ACCOUNTS(siteId));
};

export const fetchMediaTrackers = siteId => {
  return client.get(PATHS.MEDIA_TRACKERS(siteId));
};

export const fetchUserSocialMediaAccounts = () => {
  return client.get(PATHS.SOCIAL_MEDIA_ACCOUNTS);
};

export function fetchUser() {
  return client.get(PATHS.USER);
}

export const fetchSite = siteId => {
  return client.get(PATHS.SITE(siteId));
};

export function fetchSites(params) {
  return client.get(PATHS.SITES, { params });
}

export function fetchSiteSettings(siteId) {
  return client.get(PATHS.SITE_SETTINGS(siteId));
}

export function fetchSiteSubscription(siteId) {
  return client.get(PATHS.SITE_SUBSCRIPTION(siteId));
}

export function fetchStatus() {
  return client
    .get(PATHS.STATUS)
    .then(response => saveCsrfToken(response.data.csrf_token))
    .catch(error => console.log('FIXME'));
}

export function fetchTwitterUserSearch(searchTerm) {
  return client.get(PATHS.TWITTER_USER_SEARCH, {
    params: { q: searchTerm },
  });
}

export const fetchYoutubeChannelSearch = (accountId, searchTerm) => {
  return client.get(PATHS.YOUTUBE_CHANNEL_SEARCH, {
    params: { social_media_account_id: accountId, q: searchTerm },
  });
};

export const validateFeedUrl = url => {
  return client.get(PATHS.VALIDATE_FEED_URL, {
    params: { url },
  });
};

export const feedPreview = (url, count = 3) => {
  return client.get(PATHS.FEED_PREVIEW, {
    params: { count, url },
  });
};

export const fetchTokenService = channel => {
  return client.get(PATHS.TOKEN_SERVICE(channel));
};

export const fetchReviewsDpa = siteId => {
  return client.get(PATHS.DPA_REVIEWS(siteId));
};

export const fetchReviewsService = siteId => {
  return client.get(PATHS.REVIEWS_SERVICE(siteId));
};

export const fetchFeatureFlags = siteId => {
  return client.get(PATHS.FEATURE_FLAGS(siteId));
};

// All POST | PUT need to check fetchStatus() before actual request
export const postMediaTrackerHistoryPreview = (siteId, payload) =>
  fetchStatus().then(() => client.post(PATHS.MEDIA_TRACKERS_HISTORY_PREVIEW(siteId), payload));

export const postMediaTracker = (siteId, payload) =>
  fetchStatus().then(() => client.post(PATHS.MEDIA_TRACKERS(siteId), payload));

export const postSiteSection = (siteId, payload) =>
  fetchStatus().then(() => client.post(PATHS.SITE_SECTIONS(siteId), payload));

export const updateMediaTracker = (siteId, mediaTrackerId, payload) =>
  fetchStatus().then(() => client.put(PATHS.MEDIA_TRACKER(siteId, mediaTrackerId), payload));

export const fetchMediaTracker = (siteId, mediaTrackerId) => {
  return client.get(PATHS.MEDIA_TRACKER(siteId, mediaTrackerId));
};

export const fetchSignupTracking = siteId => {
  return client.get(PATHS.SITE_COMPLETE_SIGNUP(siteId));
};

export const createDemoReviews = siteId =>
  fetchStatus().then(() => client.post(PATHS.DEMO_REVIEWS(siteId)));

export const sendReviewFormEmbedCode = siteId =>
  fetchStatus().then(() => client.post(PATHS.REVIEWS_SERVICE_EMAIL_EMBED_CODE(siteId)));

export const acceptReviewsDpa = siteId =>
  fetchStatus().then(() => client.post(PATHS.DPA_REVIEWS(siteId)));
