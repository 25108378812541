import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import isFunction from 'lodash/isFunction';
import maxBy from 'lodash/maxBy';
import { SERVICES } from 'constants/Services';

import {
  answerQuestion,
  connectServiceAccount,
  getAnswers,
  getAccountId,
  getConversationFeedType,
  getSelectedAccount,
  getServiceAccounts,
  getFeedConfig,
  revertToQuestion,
  setAccountId,
  setChannelConversationStatus,
  setFeedType,
} from 'concepts/feed-builder';

const EMPTY_RESPONSE = { value: null, text: null };
const SERVICES_WITH_AT_PREFIXED_ACCOUNT_NAME = [SERVICES.TWITTER, SERVICES.TIKTOK];

const wrapComponent = (WrappedComponent, opts = {}) => {
  const accountPrefix = includes(SERVICES_WITH_AT_PREFIXED_ACCOUNT_NAME, opts.service) ? '@' : '';

  class ConversationAboutService extends Component {
    constructor(props) {
      super(props);

      // Save account count on initiation to state!
      // we need to save it to keep conversation conditional logic unchanged
      this.state = {
        initialAccountCount: props.serviceAccounts ? props.serviceAccounts.length : 0,
      };
    }

    answerQuestion = ({ questionId, response, nextId = null, disableValueTracking }) => {
      return Promise.resolve(
        this.props.answerQuestion({ questionId, response, nextId, disableValueTracking })
      ).then(() => {
        if (!nextId) {
          this.props.setChannelConversationStatus(true);
        }
      });
    };

    createAccountConnectQuestion = ({
      questionId,
      nextId,
      callback,
      shouldBeReverted,
      ...rest
    }) => {
      const { answerQuestion } = this;
      const { serviceAccounts, setAccountId, accountId } = this.props;
      const addConnectionAction = this.getConnectAction(
        questionId,
        nextId,
        callback,
        shouldBeReverted
      );

      let autoAction;
      let answerDropdownOptions;
      let answerDropdownAction;

      // auto action when user has accounts
      if (serviceAccounts.length > 0) {
        autoAction = () => {
          const selectedAccount = serviceAccounts.find(account => account.id === accountId);
          const latestAccount = maxBy(serviceAccounts, 'connected_at');
          // Pick automatically selected / first account
          const autoActionAccount = selectedAccount || latestAccount;

          setAccountId(autoActionAccount.id);

          return answerQuestion({
            response: {
              text: `${accountPrefix}${autoActionAccount.name} connected`,
              value: autoActionAccount.id,
              account: autoActionAccount,
            },
            nextId,
            questionId: questionId,
            disableValueTracking: true,
          }).then(() => isFunction(callback) && callback(autoActionAccount.id));
        };
      }

      // Dropdown actions on multiple service accounts
      if (serviceAccounts.length > 1) {
        answerDropdownOptions = serviceAccounts
          .map(account => ({
            text: `${accountPrefix}${account.name}`,
            value: account.id,
            account: account,
          }))
          .concat([{ text: 'Add another account…', value: 'ADD_ANOTHER_ACCOUNT' }]);

        answerDropdownAction = value => {
          // Add another account
          if (value === 'ADD_ANOTHER_ACCOUNT') {
            return addConnectionAction();
          }

          const selectedOption = serviceAccounts.find(account => `${account.id}` === `${value}`);

          // This should not happen
          if (!selectedOption) {
            return;
          }

          const maybeRevertToQuestion = () => {
            if (shouldBeReverted) {
              return Promise.resolve(this.revertToQuestion(questionId));
            }
            return Promise.resolve();
          };

          setAccountId(selectedOption.id);

          return maybeRevertToQuestion()
            .then(() =>
              answerQuestion({
                response: {
                  text: `${accountPrefix}${selectedOption.name} connected`,
                  value: selectedOption.id,
                  account: selectedOption,
                },
                nextId,
                questionId,
                disableValueTracking: true,
              })
            )
            .then(() => isFunction(callback) && callback(selectedOption.id));
        };
      }

      return {
        answerDropdownAction,
        answerDropdownOptions,
        autoAction,
        id: questionId,
        response: EMPTY_RESPONSE,
        undoText: 'Add another account…',
        undoAction: addConnectionAction,
        ...rest,
      };
    };

    getConnectAction = (questionId, nextId, callback, shouldBeReverted) => () => {
      return this.connectAccount().then(latestAccount => {
        this.props.setAccountId(latestAccount.id);

        const maybeRevertToQuestion = () => {
          if (shouldBeReverted) {
            return Promise.resolve(this.revertToQuestion(questionId));
          }
          return Promise.resolve();
        };

        return maybeRevertToQuestion()
          .then(() =>
            this.answerQuestion({
              response: {
                value: latestAccount.id,
                text: `${accountPrefix}${latestAccount.name} connected`,
                account: latestAccount,
              },
              nextId,
              questionId,
              disableValueTracking: true,
            })
          )
          .then(() => isFunction(callback) && callback(latestAccount.id));
      });
    };

    connectAccount = () => {
      if (!opts.service) {
        return Promise.reject();
      }

      return this.props.connectServiceAccount(opts.service);
    };

    revertToQuestion = questionId => {
      this.props.setChannelConversationStatus(false);
      return this.props.revertToQuestion(questionId);
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          answerQuestion={this.answerQuestion}
          getConnectAction={this.getConnectAction}
          connectAccount={this.connectAccount}
          createAccountConnectQuestion={this.createAccountConnectQuestion}
          initialAccountCount={this.state.initialAccountCount}
          revertToQuestion={this.revertToQuestion}
        />
      );
    }
  }

  ConversationAboutService.propTypes = {
    accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    answers: PropTypes.array,
    answerQuestion: PropTypes.func,
    connectServiceAccount: PropTypes.func,
    conversationTemplate: PropTypes.object,
    feedConfig: PropTypes.object,
    revertToQuestion: PropTypes.func,
    serviceAccounts: PropTypes.array,
    selectedAccount: PropTypes.object,
    selectedFeedType: PropTypes.string,
    setAccountId: PropTypes.func,
    setChannelConversationStatus: PropTypes.func,
  };

  ConversationAboutService.defaultProps = {
    selectedAccount: {},
  };

  const mapStateToProps = state => ({
    accountId: getAccountId(state),
    answers: getAnswers(state),
    feedConfig: getFeedConfig(state),
    serviceAccounts: getServiceAccounts(opts.service)(state),
    selectedAccount: getSelectedAccount(state),
    selectedFeedType: getConversationFeedType(state),
  });

  const mapDispatchToProps = {
    answerQuestion,
    connectServiceAccount,
    revertToQuestion,
    setAccountId,
    setChannelConversationStatus,
    setFeedType,
  };

  return connect(mapStateToProps, mapDispatchToProps)(ConversationAboutService);
};

export default wrapComponent;
