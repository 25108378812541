// Concept for Twitter redux logic
import { createSelector } from 'reselect';
import isNil from 'lodash/isNil';

import { fetchTwitterUserSearch } from 'services/api';

// Selectors
export const getSearchedUsers = state => state.twitter.users;
export const getSearchLoadingTerm = state => state.twitter.userSearchTerm;

export const getUserSearchLoading = createSelector(
  getSearchLoadingTerm,
  term => !isNil(term)
);

// Action Types
export const SEARCH_USERS = 'twitter/SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'twitter/SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAIL = 'twitter/SEARCH_USERS_FAIL';
export const CLEAR_SEARCHED_USERS = 'twitter/CLEAR_SEARCHED_USERS';

// Action Creators
export const searchUsers = searchTerm => dispatch => {
  // Start searching
  dispatch({ type: SEARCH_USERS, payload: { searchTerm } });

  // API call to search Pages
  return fetchTwitterUserSearch(searchTerm)
    .then(response => {
      const users = response.data.results;
      // save to store
      dispatch({ type: SEARCH_USERS_SUCCESS, payload: { searchTerm, users } });

      return users;
    })
    .catch(() => dispatch({ type: SEARCH_USERS_FAIL, payload: { searchTerm } }));
};

export const clearUserSearch = () => ({ type: CLEAR_SEARCHED_USERS });

export const initialState = Object.freeze({
  users: [],
  userSearchTerm: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_USERS: {
      return {
        ...state,
        users: [],
        userSearchTerm: action.payload.searchTerm,
      };
    }

    case SEARCH_USERS_SUCCESS: {
      const isLatestSearch = state.userSearchTerm === action.payload.searchTerm;
      if (!isLatestSearch) {
        return state;
      }

      return {
        ...state,
        users: action.payload.users || [],
        userSearchTerm: null,
      };
    }

    case SEARCH_USERS_FAIL: {
      const isLatestSearch = state.userSearchTerm === action.payload.searchTerm;
      if (!isLatestSearch) {
        return state;
      }

      return {
        ...state,
        users: [],
        userSearchTerm: null,
      };
    }

    case CLEAR_SEARCHED_USERS: {
      return {
        ...state,
        users: [],
        userSearchTerm: null,
      };
    }

    default: {
      return state;
    }
  }
}
