import React from 'react';

import config from 'config';
import Conversation from 'containers/Conversation';
import DebugPanel from 'containers/DebugPanel';
import FeedsAppContainer from 'containers/FeedsAppContainer';
import SignupTracker from 'containers/SignupTracker';

class FeedConversation extends React.Component {
  render() {
    return (
      <FeedsAppContainer>
        {config.showFeedDebug === 'true' && <DebugPanel />}
        <SignupTracker />
        <Conversation />
      </FeedsAppContainer>
    );
  }
}

export default FeedConversation;
