import { useState } from 'react';
import './CookiePopup.scss';
import { CONSENT_TYPES, getConsentCookieValues, useConsent } from 'services/cookie-policy';

const CookiePopup = () => {
  const [step, setStep] = useState(1);
  const [consent, setConsent] = useConsent();

  const getInitalValues = consentValues =>
    getConsentCookieValues(consentValues ? Object.keys(consentValues) : ['functional']);
  const [editValues, setEditValues] = useState(getInitalValues(consent));

  return (
    <div className="fl-cookie-banner">
      <div className="fl-cookie-banner__scroll">
        <div className="fl-cookie-banner__popup">
          <h2 className="fl-cookie-banner__title">This website uses cookies 🍪</h2>

          <div className="fl-cookie-banner__content">
            <div className="fl-cookie-banner__text">
              We use cookies to improve our site performance, store site analytics, and provide you
              a customer support chat. In addition, social media and advertising cookies of third
              parties are used to offer you social media content and personalised ads.
            </div>

            {step === 1 && (
              <>
                <div className="fl-cookie-banner__text">
                  To disable any of the cookies, click the 'No, I’d like to edit' button. To get
                  more information about all these cookies and the processing of your personal data,
                  check our{' '}
                  <a
                    href="https://flockler.com/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cookie Policy
                  </a>
                  . Do you accept all these cookies and the processing of personal data involved?
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <div className="fl-cookie-banner__edit">
                  <div className="fl-cookie-banner__edit__field">
                    <label className="fl-cookie-banner__edit__field__label">
                      <span className="fl-cookie-banner__edit__field__label__input">
                        <input
                          type="checkbox"
                          className="fl-cookie-banner__consent"
                          name="fl_cookie_banner_preference"
                          value="functional"
                          checked
                          disabled
                        />
                      </span>
                      <span className="fl-cookie-banner__edit__field__label__text">
                        Functional (required)
                      </span>
                    </label>
                    <span className="fl-cookie-banner__edit__field__text">
                      These cookies are required for basic site functionality and are therefore
                      always enabled. These include cookies that allow you to be remembered as you
                      explore the site within a single session and allow us to monitor our website
                      traffic for security and performance reasons.
                      <br />
                      <a
                        href="https://flockler.com/cookie-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        (See details)
                      </a>
                    </span>
                  </div>

                  <div className="fl-cookie-banner__edit__field">
                    <label className="fl-cookie-banner__edit__field__label">
                      <span className="fl-cookie-banner__edit__field__label__input">
                        <input
                          type="checkbox"
                          className="fl-cookie-banner__consent"
                          name="fl_cookie_banner_preference"
                          value="analytics"
                          checked={!!editValues['analytics']}
                          onChange={e =>
                            setEditValues({ ...editValues, analytics: e.target.checked })
                          }
                        />
                      </span>
                      <span className="fl-cookie-banner__edit__field__label__text">Analytics</span>
                    </label>
                    <span className="fl-cookie-banner__edit__field__text">
                      These cookies allow us to improve the site’s functionality by tracking usage
                      on the website.
                      <br />
                      <a
                        href="https://flockler.com/cookie-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        (See details)
                      </a>
                    </span>
                  </div>

                  <div className="fl-cookie-banner__edit__field">
                    <label className="fl-cookie-banner__edit__field__label">
                      <span className="fl-cookie-banner__edit__field__label__input">
                        <input
                          type="checkbox"
                          className="fl-cookie-banner__consent"
                          name="fl_cookie_banner_preference"
                          value="customer_support"
                          checked={!!editValues['customer_support']}
                          onChange={e =>
                            setEditValues({ ...editValues, customer_support: e.target.checked })
                          }
                        />
                      </span>
                      <span className="fl-cookie-banner__edit__field__label__text">
                        Customer support chat
                      </span>
                    </label>
                    <span className="fl-cookie-banner__edit__field__text">
                      These cookies allow us to provide you a customer support chat on our website.
                      Disabling these cookies will significantly decrease your experience and limit
                      the contact methods available.
                      <br />
                      <a
                        href="https://flockler.com/cookie-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        (See details)
                      </a>
                    </span>
                  </div>

                  <div className="fl-cookie-banner__edit__field">
                    <label className="fl-cookie-banner__edit__field__label">
                      <span className="fl-cookie-banner__edit__field__label__input">
                        <input
                          type="checkbox"
                          className="fl-cookie-banner__consent"
                          name="fl_cookie_banner_preference"
                          value="social_media_and_marketing"
                          checked={!!editValues['social_media_and_marketing']}
                          onChange={e =>
                            setEditValues({
                              ...editValues,
                              social_media_and_marketing: e.target.checked,
                            })
                          }
                        />
                      </span>
                      <span className="fl-cookie-banner__edit__field__label__text">
                        Social media content and marketing
                      </span>
                    </label>
                    <span className="fl-cookie-banner__edit__field__text">
                      Social media content is a key part of any modern website. Advertising cookies
                      (from third parties) collect information to better tailor advertising to your
                      interests, both within and beyond Flockler websites.
                      <br />
                      <a
                        href="https://flockler.com/cookie-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        (See details)
                      </a>
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="fl-cookie-banner__footer">
            {step === 1 && (
              <div className="fl-cookie-banner__buttons">
                <button
                  className="fl-cookie-banner__button"
                  onClick={() => setConsent(getConsentCookieValues(CONSENT_TYPES))}
                >
                  Yes, I accept all
                </button>

                <button
                  className="fl-cookie-banner__button"
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  No‚ I’d like to edit
                </button>
              </div>
            )}

            {step === 2 && (
              <div className="fl-cookie-banner__edit__buttons">
                <button
                  className="fl-cookie-banner__button fl-cookie-banner__button--plain"
                  onClick={() => setStep(1)}
                >
                  Back
                </button>

                <button className="fl-cookie-banner__button" onClick={() => setConsent(editValues)}>
                  Save my preferences
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
