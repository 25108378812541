import { createSelector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';

import { getAnswers, getFeedConfig } from 'concepts/feed-builder';
import { getServiceSocialMediaAccounts } from 'concepts/social-media-account';

import { getBusinessAccountPages } from 'concepts/instagram';

import {
  INSTAGRAM_BUSINESS_ACCOUNT_TYPE,
  SOURCES as INSTAGRAM_SOURCES,
} from 'services/media-trackers/instagram';

import {
  QUESTION_IDS as FACEBOOK_QUESTIONS,
  SOURCES as FACEBOOK_SOURCES,
} from 'services/media-trackers/facebook';

import { SERVICES } from 'constants/Services';

// This returns based on answers and feed config if feed is from
// "Own account", so that e.g. moderation is not necessary
export const getFeedOwnAccountInfo = createSelector(
  getAnswers,
  getFeedConfig,
  getServiceSocialMediaAccounts(SERVICES.TWITTER),
  getBusinessAccountPages,
  (answers, feedConfig, twitterSocialMediaAccounts, instagramPages) => {
    const { service } = feedConfig;

    if (!service) {
      return false;
    }

    // # LinkedIn
    if (service === SERVICES.LINKEDIN) {
      return true;
    }

    // # TikTok
    if (service === SERVICES.TIKTOK) {
      return true;
    }

    // # Instagram
    if (service === SERVICES.INSTAGRAM) {
      const filters = get(feedConfig, ['filters', 0]) || {};
      const include = get(filters, 'include') || [];
      const usernames = get(filters, 'usernames') || [];

      const isBusinessAccountFeed = filters.account_type === INSTAGRAM_BUSINESS_ACCOUNT_TYPE;
      const isOwnAccountInstagramFeed =
        include[0] === INSTAGRAM_SOURCES.ACCOUNT && !isBusinessAccountFeed;

      const isOwnUsernameFilter =
        usernames.length === 1 &&
        instagramPages.length > 0 &&
        instagramPages.some(page => page.username === usernames[0]);

      return isOwnAccountInstagramFeed || isOwnUsernameFilter;
    }

    // # Facebook
    if (service === SERVICES.FACEBOOK) {
      const isNonAdminFbPage = find(answers, [
        'questionId',
        FACEBOOK_QUESTIONS.CAN_COLLECT_ONLY_ADMIN,
      ]);

      const isCollectingOnlyAdminPosts = find(answers, answer => {
        if (answer.questionId !== FACEBOOK_QUESTIONS.WHAT_TO_COLLECT) {
          return false;
        }

        const sources = get(answer, ['response', 'value'], []);
        return sources && sources.length === 1 && sources[0] === FACEBOOK_SOURCES.ADMIN_POSTS;
      });

      return !isNonAdminFbPage && isCollectingOnlyAdminPosts;
    }

    // # Twitter
    if (service === SERVICES.TWITTER) {
      const usernameFilter = get(feedConfig, ['filters', 0, 'usernames', 0]);
      const isOwnTwitterFeed =
        usernameFilter &&
        twitterSocialMediaAccounts.some(account => account.name === usernameFilter);

      return isOwnTwitterFeed;
    }

    return false;
  }
);

export const getFeedAccountName = createSelector(getFeedConfig, feedConfig => {
  const filters = get(feedConfig, ['filters', 0]);

  if (!filters) {
    return null;
  }

  const username = get(filters, ['usernames', 0]) || get(filters, 'username');
  const formattedUsername = username ? `@${username}` : null;

  return filters.name || filters.page_name || filters.account_name || formattedUsername;
});
