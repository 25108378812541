import React from 'react';

import styles from './LoadingIndicator.module.scss';

const LoadingIndicator = ({ infinite }) => (
  <div className={styles.loadingIndicator}>
    <div />
    <div />
    <div />
  </div>
);

export default LoadingIndicator;
