import React from 'react';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { SERVICE_TO_FEED_TYPE } from 'constants/Services';

import {
  answerQuestion,
  getAnswers,
  getQuestionAnswer,
  revertToQuestion,
} from 'concepts/feed-builder';
import {
  createSection,
  getFeedInfo,
  getSectionCreationStatus,
  getSectionUpdateLoading,
  getSiteSections,
  updateSection,
} from 'concepts/update-section';
import { getMessengerOpenState } from 'concepts/messenger';
import { redirectToFlocklerFeedsList } from 'concepts/redirect-flockler';
import { getConversationState } from 'services/conversation-updater';
import Question from 'components/Question';
import SectionCreateInput from 'components/SectionCreateInput';

import styles from './UpdateSectionConversation.module.scss';

const QUESTION_CHOOSE_SECTION = 'RECONNECT/UPDATE_SECTION';
const QUESTION_CHOOSE_MODERATION = 'RECONNECT/CHOOSE_MODERATION';
const QUESTION_UPDATE_DONE = 'RECONNECT/UPDATE_DONE';
const QUESTION_UPDATE_FAILED = 'RECONNECT/UPDATE_FAILED';
const QUESTION_UPDATE_LOADING = 'RECONNECT/UPDATE_LOADING';

const EMPTY_RESPONSE = { value: null, text: null };

class UpdateSectionConversation extends React.Component {
  submitSectionChange = isModerated => {
    const { answerToSectionQuestion, updateSection } = this.props;

    return updateSection(answerToSectionQuestion.value, isModerated);
  };

  getConversationTemplate = () => {
    const {
      answerQuestion,
      createSection,
      feed,
      isCreatingSection,
      isUpdating,
      redirectToFlocklerFeedsList,
      siteSections,
    } = this.props;

    return [
      {
        select: {
          title: 'Select Section',
          options: orderBy(siteSections, 'name').map(section => ({
            action: () =>
              answerQuestion({
                response: {
                  value: section.id,
                  text: `Section "${section.name}"`,
                },
                questionId: QUESTION_CHOOSE_SECTION,
                nextId: QUESTION_CHOOSE_MODERATION,
              }),
            id: section.id,
            name: section.name,
          })),
          children: (
            <SectionCreateInput
              noMargin={!siteSections || siteSections.length === 0}
              title={siteSections.length > 0 ? 'Or create new section' : 'Create new section'}
              onSubmit={sectionName =>
                createSection(sectionName).then(section =>
                  answerQuestion({
                    response: {
                      value: section.id,
                      text: `Section "${section.name}"`,
                    },
                    questionId: QUESTION_CHOOSE_SECTION,
                    nextId: QUESTION_CHOOSE_MODERATION,
                  })
                )
              }
              isLoading={isCreatingSection}
            />
          ),
        },
        id: QUESTION_CHOOSE_SECTION,
        messages: [
          <span>
            Your {SERVICE_TO_FEED_TYPE[feed.service] || ''} feed <i>{feed.name || ''}</i> needs some
            tuning 😊 Let’s update section for it!
          </span>,
        ],
        response: EMPTY_RESPONSE,
        visible: true,
      },

      // Moderation question
      {
        actions: [
          {
            action: () => {
              const response = {
                value: true,
                text: 'Save for moderation',
              };

              this.submitSectionChange(true)
                .then(() =>
                  answerQuestion({
                    response,
                    nextId: QUESTION_UPDATE_DONE,
                    questionId: QUESTION_CHOOSE_MODERATION,
                  })
                )
                .catch(() =>
                  answerQuestion({
                    response,
                    nextId: QUESTION_UPDATE_FAILED,
                    questionId: QUESTION_CHOOSE_MODERATION,
                  })
                );
            },
            id: 'moderate',
            name: 'Save for moderation',
          },
          {
            action: () => {
              const response = {
                value: false,
                text: 'Display automatically',
              };

              this.submitSectionChange(false)
                .then(() =>
                  answerQuestion({
                    response,
                    nextId: QUESTION_UPDATE_DONE,
                    questionId: QUESTION_CHOOSE_MODERATION,
                  })
                )
                .catch(() =>
                  answerQuestion({
                    response,
                    nextId: QUESTION_UPDATE_FAILED,
                    questionId: QUESTION_CHOOSE_MODERATION,
                  })
                );
            },
            id: 'auto_publish',
            name: 'Display automatically',
          },
        ],
        id: QUESTION_CHOOSE_MODERATION,
        messages: [
          'Almost ready!',
          'Should I display all matching posts automatically or save them for moderation?',
        ],
      },

      // Error state for update failure
      {
        actions: [
          {
            action: () => redirectToFlocklerFeedsList(),
            id: 'back_to_app',
            name: 'Get me back to app',
          },
        ],
        id: QUESTION_UPDATE_FAILED,
        isBotMessageDisabled: isUpdating,
        isUserReplyDisabled: isUpdating,
        messages: ['Couldn’t update your feed'],
        disableAnimation: true,
      },

      // Loading state for updating
      {
        id: QUESTION_UPDATE_LOADING,
        isUserReplyDisabled: true,
        isBotMessageDisabled: !isUpdating && !isCreatingSection, // visible only when loading
        isLoading: true,
        messages: ['Loading...'],
        visible: true,
      },

      // Update done
      {
        actions: [
          {
            action: () => redirectToFlocklerFeedsList(),
            id: 'back_to_app',
            name: 'Show me the list of feeds',
          },
        ],
        id: QUESTION_UPDATE_DONE,
        messages: ['Feed is now updated 👌'],
        disableAnimation: true, // loader message will be animated
      },
    ];
  };

  renderConversation(conversationState) {
    return conversationState
      .filter(question => question.visible)
      .map(question => (
        <Question
          key={question.id}
          question={question}
          revertToQuestion={() => this.props.revertToQuestion(question.id)}
        />
      ));
  }

  render() {
    const { answers } = this.props;

    const conversationTemplate = this.getConversationTemplate();
    const conversationState = getConversationState(conversationTemplate, answers);

    return (
      <div
        className={classnames(styles.conversation, {
          [styles.dodgeMessenger]: this.props.isMessengerOpen,
        })}
      >
        <div className={styles.conversationWrapper}>
          <div className={styles.conversationPart}>
            {this.renderConversation(conversationState)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  answers: getAnswers,
  answerToSectionQuestion: getQuestionAnswer(QUESTION_CHOOSE_SECTION),
  feed: getFeedInfo,
  siteSections: getSiteSections,
  isCreatingSection: getSectionCreationStatus,
  isMessengerOpen: getMessengerOpenState,
  isUpdating: getSectionUpdateLoading,
});

const mapDispatchToProps = {
  answerQuestion,
  createSection,
  updateSection,
  redirectToFlocklerFeedsList,
  revertToQuestion,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSectionConversation);
