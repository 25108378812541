import React from 'react';

import { getConversationState } from 'services/conversation-updater';
import { QUESTION_IDS as QUESTION } from 'services/media-trackers/rss';
import { SERVICES } from 'constants/Services';
import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';
import RssLinkInput from 'components/RssLinkInput';

const EMPTY_RESPONSE = { value: null, text: null };

class ConversationAboutRSS extends React.Component {
  getConversationTemplate = () => {
    const { answerQuestion } = this.props;

    return [
      // Add new content source
      {
        input: (
          <RssLinkInput
            action={({ url, name }) => {
              answerQuestion({
                response: {
                  value: { url, name },
                  text: name || url,
                },
                questionId: QUESTION.ADD_SOURCE_URL,
              });
            }}
            placeholder="https://website.com/feed.rss"
          />
        ),

        id: QUESTION.ADD_SOURCE_URL,
        messages: ['RSS, got it! 👍', 'Just provide a URL to RSS content and we are ready to go.'],
        response: EMPTY_RESPONSE,
        visible: true,
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

export default serviceConversation(ConversationAboutRSS, {
  service: SERVICES.RSS,
});
