import includes from 'lodash/includes';
import { setAccountId, setFilter, setKeywords } from './shared';
import { SERVICES } from 'constants/Services';

export const FILTERS = {
  // actual values
  EXCLUDE_REPLIES: 'no_replies',
  EXCLUDE_RETWEETS: 'no_republished_media',
  IMAGES_ONLY: 'pictures_only',
  VIDEOS_ONLY: 'videos_only',
  // only-view values
  INCLUDE_REPLIES: 'inlclude_replies',
  INCLUDE_RETWEETS: 'include_retweets',
};

export const TWITTER_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.TWITTER,
  filters: [
    {
      keywords: null,
      usernames: null,
      match_any: false,
      [FILTERS.EXCLUDE_REPLIES]: true,
      [FILTERS.EXCLUDE_RETWEETS]: true,
      [FILTERS.IMAGES_ONLY]: false,
      [FILTERS.VIDEOS_ONLY]: false,
    },
  ],
};

export const QUESTION_IDS = {
  CHOOSE_KEYWORDS_FOR_USERS: 'TWITTER/CHOOSE_KEYWORDS_FOR_USERS',
  CHOOSE_KEYWORDS: 'TWITTER/CHOOSE_KEYWORDS',
  CHOOSE_KEYWORDS_OR_USERS: 'TWITTER/CHOOSE_KEYWORDS_OR_USERS',
  CHOOSE_MODERATION: 'TWITTER/CHOOSE_MODERATION',
  CHOOSE_USERNAMES: 'TWITTER/CHOOSE_USERNAMES',
  CONFIRM_IMAGES_ONLY: 'TWITTER/CONFIRM_IMAGES_ONLY',
  CONNECT_ACCOUNT: 'TWITTER/CONNECT_ACCOUNT',
  KEYWORDS_OR_USERS: 'TWITTER/KEYWORDS_OR_USERS',
  LIMIT_TWEETS_BY_USERS_TO_KEYWORDS: 'TWITTER/LIMIT_TWEETS_BY_USERS_TO_KEYWORDS',
  NO_ACCOUNT_CONNECTED_YET: 'TWITTER/NO_ACCOUNT_CONNECTED_YET',
  ACCOUNT_CONNECTED_ALREADY: 'TWITTER/ACCOUNT_CONNECTED_ALREADY',
  ADD_VIEW_FILTERS: 'TWITTER/ADD_VIEW_FILTERS',
};

// "Exclude" answers need opposite value updates from "Include" options
function getViewFilterUpdate(selectedValues = []) {
  return {
    [FILTERS.EXCLUDE_REPLIES]: !includes(selectedValues, FILTERS.INCLUDE_REPLIES),
    [FILTERS.EXCLUDE_RETWEETS]: !includes(selectedValues, FILTERS.INCLUDE_RETWEETS),
    [FILTERS.IMAGES_ONLY]: includes(selectedValues, FILTERS.IMAGES_ONLY),
    [FILTERS.VIDEOS_ONLY]: includes(selectedValues, FILTERS.VIDEOS_ONLY),
  };
}

// Returns updated feed config state
export function twitterFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.ACCOUNT_CONNECTED_ALREADY:
    case QUESTION_IDS.NO_ACCOUNT_CONNECTED_YET: {
      return setAccountId(state, response.value);
    }

    case QUESTION_IDS.CHOOSE_KEYWORDS:
    case QUESTION_IDS.CHOOSE_KEYWORDS_FOR_USERS: {
      return setKeywords(state, response.value);
    }

    case QUESTION_IDS.CHOOSE_USERNAMES: {
      return setUsername(state, response.value);
    }

    case QUESTION_IDS.CONFIRM_IMAGES_ONLY: {
      if (!response.value) {
        return setFilter(state, { [FILTERS.IMAGES_ONLY]: false });
      }

      return state;
    }

    case QUESTION_IDS.KEYWORDS_OR_USERS: {
      return {
        ...state,
        service: SERVICES.TWITTER_V2,
        account_id: null,
        filters: [
          {
            ...state.filters[0],
            usernames: response.value.usernames || state.filters[0]?.usernames,
          },
        ],
      };
    }

    case QUESTION_IDS.ADD_VIEW_FILTERS: {
      return setFilter(state, getViewFilterUpdate(response.value));
    }

    default: {
      return state;
    }
  }
}

function setUsername(prevConfig, usernames) {
  return {
    ...prevConfig,
    filters: [
      {
        ...prevConfig.filters[0],
        usernames: usernames,
      },
    ],
  };
}
