import { setAccountId, pipe, updateFilter, updatePage, setFilter } from './shared';
import { SERVICES } from 'constants/Services';

export const LINKEDIN_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.LINKEDIN,
  filters: [{}],
};

export const QUESTION_IDS = {
  CHOOSE_PAGE: 'LINKEDIN/CHOOSE_PAGE',
  CHOOSE_MODERATION: 'LINKEDIN/CHOOSE_MODERATION',
  WANT_TO_USE_TARGET_FILTER: 'LINKEDIN/WANT_TO_USE_TARGET_FILTER',
  CHOOSE_TARGET_FILTERS: 'LINKEDIN/CHOOSE_TARGET_FILTERS',

  CONNECT_ACCOUNT: 'LINKEDIN/CONNECT_ACCOUNT',
  ERROR_LOADING_PAGES: 'LINKEDIN/ERROR_LOADING_PAGES',
  NO_PAGES_FOUND: 'LINKEDIN/NO_PAGES_FOUND',

  INFORM_ABOUT_TARGET_FILTERING: 'LINKEDIN/INFORM_ABOUT_TARGET_FILTERING',

  NOT_ALLOWED_INFO: 'LINKEDIN/NOT_ALLOWED_INFO',
  NOT_ANY_PAGE_ADMIN: 'LINKEDIN/NOT_ANY_PAGE_ADMIN',
  NO_ACCOUNT_CONNECTED_YET: 'LINKEDIN/NO_ACCOUNT_CONNECTED_YET',
  ACCOUNT_CONNECTED_ALREADY: 'LINKEDIN/ACCOUNT_CONNECTED_ALREADY',
  LOADING_PAGES: 'LINKEDIN/LOADING_PAGES',
  LOADING_REDIRECT: 'LINKEDIN/LOADING_REDIRECT',
};

// Returns updated feed config state
export function linkedinFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.ACCOUNT_CONNECTED_ALREADY:
    case QUESTION_IDS.NO_ACCOUNT_CONNECTED_YET: {
      return setAccountId(state, response.value);
    }

    case QUESTION_IDS.CHOOSE_PAGE: {
      return pipe(
        updatePage(response.value),
        updateFilter({ name: response.text, image_url: response.image })
      )(state);
    }

    case QUESTION_IDS.CHOOSE_TARGET_FILTERS: {
      return setFilter(state, response.value);
    }

    default: {
      return state;
    }
  }
}
