import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { createDataMapSelector } from 'services/domain-data-selector';
import { fetchYoutubeChannelSearch } from 'services/api';
import { getAccountId } from 'concepts/feed-builder';
import { SOURCES } from 'services/media-trackers/youtube';

// Domain utils
// Transform YouTube API data transfer object (DTO) to application format
function youtubeFromDto(item) {
  const type =
    get(item, ['id', 'kind'], '').indexOf('playlist') >= 0 ? SOURCES.PLAYLIST : SOURCES.CHANNEL;

  return {
    id: get(item, ['id', 'channelId']) || get(item, ['id', 'playlistId']) || get(item, ['id']),
    type,
    image:
      get(item, ['snippet', 'thumbnails', 'high', 'url']) ||
      get(item, ['snippet', 'thumbnails', 'default', 'url']),
    name: get(item, ['snippet', 'title']),
  };
}

const createFromDtoSelector = createDataMapSelector(youtubeFromDto);

// Selectors
export const getSearchedChannelsState = state => state.youtube.searchedChannels;
export const getSearchLoadingTerm = state => state.youtube.loadingSearchTerm;

export const getSearchedChannels = createFromDtoSelector(getSearchedChannelsState);
export const getSearchLoadingStatus = createSelector(
  getSearchLoadingTerm,
  term => !isNil(term)
);

// Action Types
export const ACTIONS = Object.freeze({
  CLEAR_SEARCH_CHANNELS: 'YOUTUBE/CLEAR_SEARCH_CHANNELS',
  SEARCH_CHANNELS: 'YOUTUBE/SEARCH_CHANNELS',
  SEARCH_CHANNELS_SUCCESS: 'YOUTUBE/SEARCH_CHANNELS_SUCCESS',
  SEARCH_CHANNELS_FAIL: 'YOUTUBE/SEARCH_CHANNELS_FAIL',
});

// Action Creators
export const searchChannels = searchTerm => (dispatch, getState) => {
  // Start searching
  dispatch({ type: ACTIONS.SEARCH_CHANNELS, payload: { searchTerm } });

  const accountId = getAccountId(getState());
  if (isNil(accountId)) {
    return dispatch({ type: ACTIONS.SEARCH_CHANNELS_FAIL, payload: { searchTerm } });
  }

  // API call to search Channels
  return fetchYoutubeChannelSearch(accountId, searchTerm)
    .then(response => {
      const { items } = response.data;
      // save to store
      return dispatch({ type: ACTIONS.SEARCH_CHANNELS_SUCCESS, payload: { searchTerm, items } });
    })
    .catch(() => dispatch({ type: ACTIONS.SEARCH_CHANNELS_FAIL, payload: { searchTerm } }));
};

export const clearChannelSearchResults = () => ({ type: ACTIONS.CLEAR_SEARCH_CHANNELS });

export const initialState = Object.freeze({
  loadingSearchTerm: null,
  searchedChannels: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SEARCH_CHANNELS: {
      return {
        ...state,
        searchedChannels: [],
        loadingSearchTerm: get(action, ['payload', 'searchTerm']),
      };
    }

    case ACTIONS.SEARCH_CHANNELS_SUCCESS: {
      const isLatestSearch = state.loadingSearchTerm === get(action, ['payload', 'searchTerm']);
      if (!isLatestSearch) {
        return state;
      }

      return {
        ...state,
        searchedChannels: action.payload.items,
        loadingSearchTerm: null,
      };
    }

    case ACTIONS.SEARCH_CHANNELS_FAIL: {
      const isLatestSearch = state.loadingSearchTerm === get(action, ['payload', 'searchTerm']);
      if (!isLatestSearch) {
        return state;
      }

      return {
        ...state,
        loadingSearchTerm: null,
      };
    }

    case ACTIONS.CLEAR_SEARCH_CHANNELS: {
      return {
        ...state,
        searchedChannels: [],
        loadingSearchTerm: null,
      };
    }

    case ACTIONS.SET_PAGE_TYPE: {
      return {
        ...state,
        pageType: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
