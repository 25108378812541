import React from 'react';

import { feedConfigToDto } from 'services/media-tracker-dto';
import styles from './Sidebar.module.scss';

class Sidebar extends React.Component {
  render() {
    const { feedConfig } = this.props;

    return (
      <div className={styles.sidebar}>
        <h3>Feed Config</h3>
        <pre>{JSON.stringify(feedConfig, null, 2)}</pre>

        <hr />
        <h3>Media Tracker</h3>
        <pre>{JSON.stringify(feedConfigToDto(feedConfig), null, 2)}</pre>
      </div>
    );
  }
}

export default Sidebar;
