import React from 'react';
import classnames from 'classnames';
import Autocomplete from 'react-autocomplete';
import debounce from 'lodash/debounce';
import { ReactComponent as SearchSVG } from 'assets/svgs/icon-search.svg';
import LoadingIndicator from 'components/LoadingIndicator';

import styles from './PageSearch.module.scss';
const SEARCH_TEXT_MIN = 2;
const DEFAULT_MAX_RESULTS = 8;

class PageSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchText: '', debouncedSearch: null };
  }

  updateSearchText = e => {
    const searchText = e.target.value;

    // Cancel debounced search if it has been set
    if (this.state.debouncedSearch) {
      this.state.debouncedSearch.cancel();
    }

    // update state
    this.setState({ searchText, debouncedSearch: null });

    if (searchText.length > SEARCH_TEXT_MIN) {
      // Create new debounced search, which clears deboucened search function from state after search execution
      const debouncedSearch = debounce(() => {
        // execute search
        this.props.onSearch(searchText);
        // also clear debounced search from state
        this.setState({ debouncedSearch: null });
      }, this.props.searchDebounceWait || 250);

      // Update state with debounced search function
      this.setState({ debouncedSearch });

      // Start debounced search
      debouncedSearch();
    } else {
      this.props.onClear();
    }
  };

  componentWillUnmount() {
    this.props.onClear();
  }

  onSelect = id => {
    const selectedItem = this.props.items.find(item => item.id === id);

    if (!selectedItem || selectedItem.isDisabled) {
      return;
    }

    this.props.onSelect(selectedItem);
  };

  renderEmptyState = () => {
    const { searchText } = this.state;
    const { renderEmptyResult } = this.props;

    return (
      <div className={styles.emptyResult}>
        <div>
          <span className={styles.infoIcon}>i</span>
        </div>
        <div>{renderEmptyResult(searchText)}</div>
      </div>
    );
  };

  render() {
    const { searchText, debouncedSearch } = this.state;
    const { title, noMargin, items, isSearching, placeholder, autoFocus } = this.props;

    const isTooShortSearchText = searchText.length <= SEARCH_TEXT_MIN;
    const renderedItems =
      isSearching || isTooShortSearchText ? [] : items.slice(0, DEFAULT_MAX_RESULTS);

    const isEmptyResultVisible =
      !debouncedSearch && !isTooShortSearchText && !items.length && !isSearching;
    const isLoadingVisible = isSearching || !!debouncedSearch;

    return (
      <div className={classnames(styles.pageSearch, { [styles.noMargin]: noMargin })}>
        {title && <span className={styles.title}>{title}</span>}
        <label className={styles.pageSearchLabel}>
          <SearchSVG className={styles.searchIcon} />

          {isLoadingVisible && (
            <div className={styles.loading}>
              <LoadingIndicator />
            </div>
          )}

          <Autocomplete
            getItemValue={item => item.id}
            items={renderedItems}
            value={searchText}
            onChange={this.updateSearchText}
            onSelect={this.onSelect}
            inputProps={{
              className: styles.searchInput,
              placeholder,
              autoFocus,
            }}
            open
            renderItem={(item, active) => (
              <div
                key={item.id}
                className={classnames(styles.menuItem, {
                  [styles.active]: active,
                  [styles.disabled]: item.isDisabled,
                })}
                title={item.title}
              >
                {!!item.image && (
                  <div
                    style={{ backgroundImage: `url('${item.image}')` }}
                    className={styles.itemImage}
                  />
                )}
                <div className={styles.itemInfo}>
                  <div className={styles.itemName}>{item.name}</div>
                  <div className={styles.itemDescription}>{item.description || ''}</div>
                </div>

                {item.additionalInfo && (
                  <div className={styles.additionalInfo}>{item.additionalInfo}</div>
                )}
              </div>
            )}
            renderMenu={(items, value, style) => (
              <div
                style={{ ...style, ...this.menuStyle }}
                className={classnames(styles.menu, {
                  [styles.emptyResultMenu]: isEmptyResultVisible,
                })}
              >
                {isEmptyResultVisible ? this.renderEmptyState() : items}
              </div>
            )}
            wrapperStyle={{}}
          />
        </label>
      </div>
    );
  }
}

export default PageSearch;
