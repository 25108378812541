import React from 'react';
import LogoLoader from 'components/LogoLoader';
import styles from './PageLoader.module.scss';

const PageLoader = () => (
  <div className={styles.loadingWrapper}>
    <LogoLoader />
  </div>
);

export default PageLoader;
