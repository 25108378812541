import { combineReducers } from 'redux';

import app from 'concepts/app';
import error from 'concepts/error';
import facebook from 'concepts/facebook';
import feedBuilder from 'concepts/feed-builder';
import i18n from 'concepts/i18n';
import instagram from 'concepts/instagram';
import linkedin from 'concepts/linkedin';
import mediaTracker from 'concepts/media-tracker';
import messenger from 'concepts/messenger';
import reconnectAccount from 'concepts/reconnect-account';
import section from 'concepts/section';
import site from 'concepts/site';
import socialMediaAccount from 'concepts/social-media-account';
import twitter from 'concepts/twitter';
import updateSection from 'concepts/update-section';
import user from 'concepts/user';
import youtube from 'concepts/youtube';
import featureFlags from 'concepts/feature-flags';

export const reducers = combineReducers({
  app,
  error,
  facebook,
  feedBuilder,
  i18n,
  instagram,
  linkedin,
  mediaTracker,
  messenger,
  reconnectAccount,
  section,
  site,
  socialMediaAccount,
  twitter,
  updateSection,
  user,
  youtube,
  featureFlags,
});
