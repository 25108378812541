import React from 'react';
import PropTypes from 'prop-types';

import PageSearch from 'components/PageSearch';
import UserMessageActionCard from 'components/UserMessageActionCard';

const UserMessageSearchAction = ({ title, searchComponent, ...rest }) => {
  return (
    <UserMessageActionCard title={title}>
      {searchComponent ? React.createElement(searchComponent, rest) : <PageSearch {...rest} />}
    </UserMessageActionCard>
  );
};

UserMessageSearchAction.defaultProps = {
  title: undefined,
};

UserMessageSearchAction.propTypes = {
  title: PropTypes.string,
};

export default React.memo(UserMessageSearchAction);
