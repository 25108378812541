import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import replace from 'lodash/replace';

import { getQuestionAnswer } from 'concepts/feed-builder';
import { QUESTION_IDS as QUESTION, FILTERS } from 'services/media-trackers/twitter';
import { SERVICES } from 'constants/Services';
import { getConversationState } from 'services/conversation-updater';
import { joinWithAnd } from 'utils/string';

import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';
import WordInput from 'components/WordInput';
import TwitterUserSearch from 'components/TwitterUserSearch';

const EMPTY_RESPONSE = { value: null, text: null };

const hashtagKeywordInstructionMessage = (
  <span>
    To display posts by <i>#hashtag</i>, include # to your search term. To display posts mentioning
    a keyword, type a search term without #.
  </span>
);

function tagList(tags) {
  return tags.map(tag => tag.text);
}

const formatResponse = (values, prefix = '') => {
  return joinWithAnd(values.map(val => `${prefix}${val}`));
};

const getViewFilterOptions = ({ hashtags, usernames }) => [
  {
    value: FILTERS.INCLUDE_REPLIES,
    text: `Include replies
    ${usernames.length ? ` from ${formatResponse(usernames, '@')}` : ''}
    ${hashtags.length ? ` mentioning ${formatResponse(hashtags)}` : ''}`,
  },
  {
    value: FILTERS.IMAGES_ONLY,
    text: 'Only store posts with an uploaded image',
  },
  {
    value: FILTERS.VIDEOS_ONLY,
    text: 'Only store posts with a video',
  },
];

function getViewFiltersSelectionText(values, options) {
  const textValues = options
    .map(option => {
      const chosen = values.find(value => option.value === value);

      return !!chosen ? option.text : option.oppositeText;
    })
    .filter(value => !isNil(value));

  if (textValues.length === 0) {
    return 'No optional filters';
  }

  return textValues;
}

class ConversationAboutTwitter extends React.Component {
  getConversationTemplate = () => {
    const {
      answerQuestion,

      // question answers
      hashtags,
      hashtagsForUser,
      usernames,
      keywordOrUsersAnswer,
    } = this.props;

    const ownAccountFilter = get(keywordOrUsersAnswer, ['value', 'usernames']);

    const givenHashtags = get(hashtags, 'value') || get(hashtagsForUser, 'value') || [];
    const givenUsernames = ownAccountFilter || get(usernames, 'value', []);

    const viewFilterOptions = getViewFilterOptions({
      hashtags: givenHashtags,
      usernames: givenUsernames,
    });

    return [
      // Keywords or users
      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'keyword',
                  text: 'Posts with a #hashtag or a keyword:',
                },
                nextId: QUESTION.CHOOSE_KEYWORDS,
                questionId: QUESTION.KEYWORDS_OR_USERS,
              });
            },
            id: 'keyword',
            name: 'Posts with a #hashtag or a keyword',
          },
          {
            action: () =>
              answerQuestion({
                response: {
                  value: 'username',
                  text: 'Posts by:',
                },
                nextId: QUESTION.CHOOSE_USERNAMES,
                questionId: QUESTION.KEYWORDS_OR_USERS,
              }),
            id: 'username',
            name: 'Posts by username',
          },
        ],
        id: QUESTION.KEYWORDS_OR_USERS,
        messages: ['What would you like to collect from X?'],
        response: EMPTY_RESPONSE,
        visible: true,
      },

      // User input
      {
        actions: [],
        input: (
          <TwitterUserSearch
            onSelect={selectedUser =>
              answerQuestion({
                response: {
                  value: [replace(selectedUser.name, '@', '')],
                  text: [selectedUser.name],
                  image: selectedUser.image,
                },
                nextId: QUESTION.LIMIT_TWEETS_BY_USERS_TO_KEYWORDS,
                questionId: QUESTION.CHOOSE_USERNAMES,
              })
            }
          />
        ),
        id: QUESTION.CHOOSE_USERNAMES,
        messages: [],
        response: EMPTY_RESPONSE,
        undoText: 'Edit username…',
        visible: false,
      },

      // Keyword input
      {
        actions: [],
        input: (
          <WordInput
            action={keywords => {
              answerQuestion({
                response: {
                  value: tagList(keywords),
                  text: tagList(keywords),
                  isTags: true,
                },
                questionId: QUESTION.CHOOSE_KEYWORDS,
                nextId: QUESTION.ADD_VIEW_FILTERS,
              });
            }}
            autoActionOnMaxTags
            isQuotedMultiWordTagAllowed
            maxTags={1}
            placeholder="#hashtag or keyword"
          />
        ),
        id: QUESTION.CHOOSE_KEYWORDS,
        messages: [hashtagKeywordInstructionMessage],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Limit to users?
      {
        actions: [
          {
            action: () =>
              answerQuestion({
                response: {
                  value: 'yes',
                  text: 'Posts should also mention:',
                },
                nextId: QUESTION.CHOOSE_KEYWORDS_FOR_USERS,
                questionId: QUESTION.LIMIT_TWEETS_BY_USERS_TO_KEYWORDS,
              }),
            id: 'keyword',
            name: 'No, posts need to also mention…',
          },
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'no',
                  text: 'Collect all of their posts',
                },
                nextId: QUESTION.ADD_VIEW_FILTERS,
                questionId: QUESTION.LIMIT_TWEETS_BY_USERS_TO_KEYWORDS,
              });
            },
            id: 'username',
            name: 'Yes, display all',
          },
        ],
        id: QUESTION.LIMIT_TWEETS_BY_USERS_TO_KEYWORDS,
        messages: [
          `Would you like to display all posts by ${
            givenUsernames.length ? `${formatResponse(givenUsernames, '@')}` : 'them'
          }?`,
        ],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Keyword input for users
      {
        actions: [],
        input: (
          <WordInput
            action={keywords => {
              answerQuestion({
                response: {
                  value: tagList(keywords),
                  text: tagList(keywords),
                  isTags: true,
                },
                questionId: QUESTION.CHOOSE_KEYWORDS_FOR_USERS,
                nextId: QUESTION.ADD_VIEW_FILTERS,
              });
            }}
            autoActionOnMaxTags
            isQuotedMultiWordTagAllowed
            maxTags={1}
            placeholder="#hashtag or keyword"
          />
        ),
        id: QUESTION.CHOOSE_KEYWORDS_FOR_USERS,
        messages: [hashtagKeywordInstructionMessage],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Fine-tuning filters
      {
        checkboxes: {
          title: 'Add filters…',
          options: viewFilterOptions,
          action: values => {
            answerQuestion({
              response: {
                value: values,
                text: getViewFiltersSelectionText(values, viewFilterOptions),
                isTags: true,
              },
              questionId: QUESTION.ADD_VIEW_FILTERS,
              nextId:
                (values || []).includes(FILTERS.IMAGES_ONLY) &&
                !(values || []).includes(FILTERS.VIDEOS_ONLY)
                  ? QUESTION.CONFIRM_IMAGES_ONLY
                  : null,
            });
          },
          getActionButtonText: selectedCount => {
            return selectedCount > 0
              ? 'Save my preferences and start gathering content'
              : "I'm good. Start gathering content";
          },
        },

        id: QUESTION.ADD_VIEW_FILTERS,
        messages: [
          `${givenUsernames.length ? 'Alright' : 'By default'}, we gather posts
              ${givenUsernames.length ? ` from ${formatResponse(givenUsernames, '@')}` : ''}${
                givenHashtags.length ? ` mentioning ${formatResponse(givenHashtags)}` : ''
              }, but there are some optional filters:`,
        ],
        response: EMPTY_RESPONSE,
      },

      {
        actions: [
          {
            action: () =>
              answerQuestion({
                response: {
                  value: true,
                  text: 'Yes, only posts with a separately uploaded image',
                },
                nextId: null,
                questionId: QUESTION.CONFIRM_IMAGES_ONLY,
              }),
            id: 'confirm_yes',
            name: 'Yes',
          },

          {
            action: () =>
              answerQuestion({
                response: {
                  value: false,
                  text: 'No, gather all posts',
                },
                nextId: null,
                questionId: QUESTION.CONFIRM_IMAGES_ONLY,
              }),
            id: 'confirm_no',
            name: 'No',
          },
        ],
        id: QUESTION.CONFIRM_IMAGES_ONLY,
        messages: [
          'Just a heads up; the image needs to be uploaded to the post, and the preview images of a link aren’t available outside Twitter.com services.',
          'Would you like to gather only posts with a separately uploaded image?',
        ],
        response: EMPTY_RESPONSE,
        visible: false,
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

const mapStateToProps = state => ({
  hashtags: getQuestionAnswer(QUESTION.CHOOSE_KEYWORDS)(state),
  hashtagsForUser: getQuestionAnswer(QUESTION.CHOOSE_KEYWORDS_FOR_USERS)(state),
  keywordOrUsersAnswer: getQuestionAnswer(QUESTION.KEYWORDS_OR_USERS)(state),
  usernames: getQuestionAnswer(QUESTION.CHOOSE_USERNAMES)(state),
});

const ConnectedConversationAboutTwitter = connect(mapStateToProps)(ConversationAboutTwitter);

export default serviceConversation(ConnectedConversationAboutTwitter, {
  service: SERVICES.TWITTER,
});
