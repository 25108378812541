import React from 'react';
import PropTypes from 'prop-types';

import styles from './UserMessageActionCard.module.scss';

const UserMessageActionCard = ({ title, children }) => {
  return (
    <div className={styles.card}>
      {!!title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );
};

UserMessageActionCard.defaultProps = {
  title: undefined,
  children: undefined,
};

UserMessageActionCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

export default React.memo(UserMessageActionCard);
