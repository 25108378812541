// # Feed builder concept
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { findById } from 'utils/common';

import { getMediaTrackers, updateMediaTracker } from 'concepts/media-tracker';
import { showError } from 'concepts/error';
import * as section from 'concepts/section';
import * as site from 'concepts/site';
import { getDisplayName } from 'utils/media-tracker-name';

// # Action types
export const SET_FEED_ID = 'UPDATE_SECTION/SET_FEED_ID';
export const UPDATE_SECTION_START = 'UPDATE_SECTION/UPDATE_SECTION_START';
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION/UPDATE_SECTION_SUCCESS';
export const UPDATE_SECTION_FAIL = 'UPDATE_SECTION/UPDATE_SECTION_FAIL';

// # Selectors
export const getSectionUpdateFeedId = state => state.updateSection.feedId;
export const getSectionUpdateLoading = state => state.updateSection.isUpdating;
export const getSectionUpdateStatus = state => state.updateSection.isFailed;
export const getSectionCreationStatus = section.getSectionCreationStatus;

const getFeed = createSelector(
  getMediaTrackers,
  getSectionUpdateFeedId,
  findById
);

export const getFeedInfo = createSelector(
  getFeed,
  feed => ({
    name: feed ? getDisplayName(feed) : null,
    service: get(feed, 'service'),
  })
);

export const getSiteSections = site.getSiteSections;

// # Action creators
export const createSection = section.createSection;

export const setFeedId = feedId => (dispatch, getState) => {
  const mediaTrackers = getMediaTrackers(getState());
  const isValidFeedId = findById(mediaTrackers, feedId);

  if (!isValidFeedId) {
    return dispatch(showError(`Invalid feed (${feedId})`));
  }

  return dispatch({
    type: SET_FEED_ID,
    payload: feedId,
  });
};

export const getSectionUpdatePayload = ({ feedId, sectionId, isModerated, subscription }) => {
  const hasPreviousSubscription = !isNil(subscription);

  if (hasPreviousSubscription) {
    const subscriptionId = get(subscription, 'id');

    return {
      media_tracker_subscriptions_attributes: [
        {
          id: subscriptionId,
          media_tracker_id: feedId,
          subscriber_id: sectionId,
          subscriber_type: 'Section',
          parameters_attributes: [
            {
              id: get(subscription, ['parameters', 0, 'id']),
              name: 'auto_publish',
              target_id: subscriptionId,
              _destroy: isModerated ? 1 : null,
            },
          ],
        },
      ],
    };
  }

  // Feed does not have prev section subscribe
  return {
    media_tracker_subscriptions_attributes: [
      {
        media_tracker_id: feedId,
        subscriber_id: sectionId,
        subscriber_type: 'Section',
        ...(isModerated === false
          ? {
              parameters_attributes: [
                {
                  name: 'auto_publish',
                },
              ],
            }
          : null),
      },
    ],
  };
};

export const updateSection = (sectionId, isModerated) => (dispatch, getState) => {
  const state = getState();
  const feedId = getSectionUpdateFeedId(state);
  const feed = getFeed(state);
  const subscription = get(feed, ['media_tracker_subscriptions', 0]);

  dispatch({ type: UPDATE_SECTION_START });

  // Update feed with new section id and moderated info
  if (sectionId) {
    const feedUpdate = getSectionUpdatePayload({ feedId, sectionId, isModerated, subscription });

    return dispatch(updateMediaTracker(feedId, feedUpdate))
      .then(() => dispatch({ type: UPDATE_SECTION_SUCCESS }))
      .catch(() => {
        dispatch({ type: UPDATE_SECTION_FAIL });
        return Promise.reject();
      });
  }

  dispatch({ type: UPDATE_SECTION_FAIL });
  return Promise.reject();
};

// # Reducer
export const initialState = Object.freeze({
  isFailed: false,
  isUpdating: false,
  feedId: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FEED_ID: {
      return {
        ...state,
        feedId: action.payload,
      };
    }

    case UPDATE_SECTION_START: {
      return {
        ...state,
        isUpdating: true,
      };
    }

    case UPDATE_SECTION_SUCCESS: {
      return {
        ...state,
        isFailed: false,
        isUpdating: false,
      };
    }

    case UPDATE_SECTION_FAIL: {
      return {
        ...state,
        isFailed: true,
        isUpdating: false,
      };
    }

    default: {
      return state;
    }
  }
}
