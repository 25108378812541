// # i18n concept
import { createSelector } from 'reselect';

// Action types
const INTERNATIONALIZATION_LOADED = 'I18N/INTERNATIONALIZATION_LOADED';

// Selectors
export const getInternationalization = state => state.i18n.internationalization;

export const getIsFromEu = createSelector(getInternationalization, internationalization => {
  if (!internationalization) {
    return undefined;
  }

  return internationalization['flockler-is-eu-member-state'];
});

// Action creators
export const fetchInternationalization = () => dispatch =>
  fetch('https://plugins.flockler.com/i18n')
    .then(res => res.json())
    .then(response =>
      dispatch({
        type: INTERNATIONALIZATION_LOADED,
        internationalization: response,
      })
    )
    .catch(console.log);

// Reducer
const initialState = Object.freeze({
  internationalization: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INTERNATIONALIZATION_LOADED: {
      return { ...state, internationalization: action.internationalization };
    }

    default:
      return state;
  }
}
