import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as BotSVG } from 'assets/svgs/bot.svg';
import styles from './BotMessage.module.scss';

const BotMessage = ({ disableAnimation, hideBotAvatar, style, contentStyle, children }) => {
  return (
    <div
      className={classnames(styles.botMessage, { [styles.disableAnimation]: disableAnimation })}
      style={style}
    >
      <BotSVG className={classnames(styles.botMessageAvatar, { [styles.hidden]: hideBotAvatar })} />
      <div className={styles.botMessageContents} style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

BotMessage.defaultProps = {
  disableAnimation: false,
  hideBotAvatar: false,
  style: undefined,
  contentStyle: undefined,
};

BotMessage.propTypes = {
  style: PropTypes.any,
  contentStyle: PropTypes.any,
  children: PropTypes.any.isRequired,
  disableAnimation: PropTypes.bool,
  hideBotAvatar: PropTypes.bool,
};

export default React.memo(BotMessage);
