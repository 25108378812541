const helpScoutAskViaChat = () => {
  window.Beacon('navigate', '/ask/chat');
  window.Beacon('open');
}

const helpScoutAskViaEmail = () => {
  window.Beacon('navigate', '/ask/message');
  window.Beacon('open');
}

export const helpScoutMessage = ({ subject, text = '', chat = true }) => {
    window.Beacon('prefill', {
        subject: subject,
        text: text
    });

    chat ? helpScoutAskViaChat() : helpScoutAskViaEmail()
}
