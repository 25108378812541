import isNil from 'lodash/isNil';
import { createDataMapSelector } from 'services/domain-data-selector';
import { fetchLinkedinCompanies } from 'services/api';

// Domain utils
// Transform YouTube API data transfer object (DTO) to application format
function linkedinFromDto(item) {
  return {
    id: item.id,
    image: item.logo,
    name: item.localized_name,
  };
}

const createFromDtoSelector = createDataMapSelector(linkedinFromDto);

// Selectors
export const getPagesState = state => state.linkedin.pages;
export const getPagesLoadingStatus = state => state.linkedin.isLoading;

export const getPages = createFromDtoSelector(getPagesState);

// Action Types
export const ACTIONS = Object.freeze({
  FETCH_PAGES: 'LINKEDIN/FETCH_PAGES',
  FETCH_PAGES_SUCCESS: 'LINKEDIN/FETCH_PAGES_SUCCESS',
  FETCH_PAGES_FAIL: 'LINKEDIN/FETCH_PAGES_FAIL',
});

// Action Creators
export const fetchPages = accountId => (dispatch, getState) => {
  // Start fetching
  dispatch({ type: ACTIONS.FETCH_PAGES });

  if (isNil(accountId)) {
    return dispatch({ type: ACTIONS.FETCH_PAGES_FAIL });
  }

  // Fetch Companies
  return fetchLinkedinCompanies(accountId)
    .then(response => {
      const { companies } = response.data;

      // save to store
      dispatch({ type: ACTIONS.FETCH_PAGES_SUCCESS, payload: companies });
      return companies;
    })
    .catch(() => {
      dispatch({ type: ACTIONS.FETCH_PAGES_FAIL });
      return Promise.reject();
    });
};

export const initialState = Object.freeze({
  pages: [],
  isLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.FETCH_PAGES: {
      return {
        ...state,
        pages: [],
        isLoading: true,
      };
    }

    case ACTIONS.FETCH_PAGES_SUCCESS: {
      return {
        ...state,
        pages: action.payload,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_PAGES_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}
