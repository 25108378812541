import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';

import { QUESTION_IDS as QUESTION } from 'services/media-trackers/google-review';
import { SERVICES } from 'constants/Services';
import { getConversationState } from 'services/conversation-updater';
import { fetchTokenService } from 'services/api';
import { getQuestionAnswer } from 'concepts/feed-builder';
import { helpScoutMessage } from 'utils/help-scout';

import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';

const EMPTY_RESPONSE = { value: null, text: null };

const fetchLocations = () => {
  return fetchTokenService('googlemybusiness')
    .then(response => {
      const token = get(response, ['data', 0]);
      const { accessToken } = token;

      // Fetch all accounts with user accessToken
      return fetch(`https://mybusinessaccountmanagement.googleapis.com/v1/accounts?pageSize=100`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(res => ({ accounts: res.accounts, accessToken }));
    })
    .then(response => {
      const { accessToken, accounts } = response;

      // Fetch locations for accounts
      return Promise.all(
        accounts.map(account =>
          fetch(
            `https://mybusinessbusinessinformation.googleapis.com/v1/${account.name}/locations?readMask=name,title,metadata&pageSize=100`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          ).then(res => res.json())
        )
      ).then(res => res.flatMap(locationResponse => locationResponse.locations || []));
    })
    .then(locations => {
      if (!locations || !locations.length) {
        return Promise.reject();
      }

      // Unique by "name" => "locations/123456789"
      return uniqBy(locations, 'name');
    });
};

// This function formats text for UI after user has selected locations
const getTextForChosenLocations = (values, options) => {
  const textValues = values
    .map(value => {
      const chosen = options.find(option => option.value === value) || {};
      return chosen.text;
    })
    .filter(value => !isNil(value));

  if (!textValues.length) return ['All locations']; // This shouldn't be possible with current UI
  if (textValues.length < 2) return textValues; // TODO change back to 10

  return [`${textValues.length} locations`];
};

// This function builds array of location objects from location ids which checkbox component provides
const getChosenLocations = (values, options) => {
  return values.map(value => options.find(option => option.value === value));
};

class ConversationAboutGoogleReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoadingLocations: false };
  }

  connectCallback = () => {
    const { answerQuestion } = this.props;
    this.setState({ isLoadingLocations: true });

    return fetchLocations()
      .then(locations => {
        this.setState({ isLoadingLocations: false });

        return answerQuestion({
          response: {
            value: locations,
            text: `${locations.length} Locations found`,
          },
          questionId: QUESTION.LOADING_LOCATIONS,
          nextId: locations.length > 0 ? QUESTION.CHOOSE_LOCATIONS : QUESTION.NO_LOCATIONS_FOUND,
        });
      })
      .catch(error => {
        this.setState({ isLoadingLocations: false });
        return answerQuestion({
          response: {
            value: null,
            text: 'Error loading locations',
          },
          questionId: QUESTION.LOADING_LOCATIONS,
          nextId: QUESTION.ERROR_LOADING_LOCATIONS,
        });
      });
  };

  reconnectAccount = () => {
    const { getConnectAction } = this.props;

    const reconnect = getConnectAction(
      QUESTION.ACCOUNT_CONNECTED_ALREADY,
      QUESTION.LOADING_LOCATIONS,
      null,
      true
    );
    reconnect();
  };

  getConversationTemplate = () => {
    const {
      answerQuestion,
      createAccountConnectQuestion,
      getConnectAction,
      initialAccountCount,
      loadedLocationlocationAnswer,
    } = this.props;

    const loadedLocations = get(loadedLocationlocationAnswer, ['value']) || [];

    const locationOptions = loadedLocations.map(location => ({
      value: location.name,
      text: location.title,
      url: location?.metadata?.mapsUri,
      isSelected: true,
    }));

    return [
      // Question for user who has no accounts yet for service
      createAccountConnectQuestion({
        actions: [
          {
            action: getConnectAction(QUESTION.NO_ACCOUNT_CONNECTED_YET, QUESTION.LOADING_LOCATIONS),
            id: QUESTION.CONNECT_ACCOUNT,
            name: 'Connect Google account…',
          },
        ],
        messages: ['Google Reviews it is then! Let’s start by connecting your Google account.'],
        nextId: QUESTION.LOADING_LOCATIONS,
        visible: initialAccountCount === 0,
        questionId: QUESTION.NO_ACCOUNT_CONNECTED_YET,
        callback: this.connectCallback,
        shouldBeReverted: true,
      }),

      // Question for user who has account(s) connected already
      createAccountConnectQuestion({
        actions: [],
        messages: [],
        nextId: QUESTION.LOADING_LOCATIONS,
        visible: initialAccountCount > 0,
        questionId: QUESTION.ACCOUNT_CONNECTED_ALREADY,
        callback: this.connectCallback,
        shouldBeReverted: true,
      }),

      // "Ghost" question when waiting for pages to load
      {
        id: QUESTION.LOADING_LOCATIONS,
        nextId: QUESTION.CHOOSE_LOCATIONS,
        isLoading: true,
        isUserReplyDisabled: true,
        isBotMessageDisabled: !this.state.isLoadingLocations,
        messages: [],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      {
        id: QUESTION.ERROR_LOADING_LOCATIONS,
        nextId: null,
        isUserReplyDisabled: true,
        messages: [
          'Unfortunately I couldn’t get Google Review Locations for your account.',
          <span>
            Please try{' '}
            <button onClick={this.reconnectAccount}>reconnecting your Google account</button> or{' '}
            <button
              onClick={() =>
                helpScoutMessage({ subject: 'Google Review Locations do not load correctly.' })
              }
            >
              chat with us
            </button>{' '}
            if problem persists.
          </span>,
        ],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      {
        id: QUESTION.NO_LOCATIONS_FOUND,
        nextId: null,
        isUserReplyDisabled: true,
        messages: [
          'Unfortunately I couldn’t find any Google Review Locations for your account.',
          <span>
            Please try{' '}
            <button onClick={this.reconnectAccount}>connecting another Google account</button> or{' '}
            <button
              onClick={() =>
                helpScoutMessage({ subject: 'Google Review Locations do not load correctly.' })
              }
            >
              chat with us
            </button>{' '}
            if problem persists.
          </span>,
        ],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      {
        autoAction:
          locationOptions.length === 1
            ? () => {
                return answerQuestion({
                  response: {
                    value: getChosenLocations([locationOptions[0].value], locationOptions),
                    text: [locationOptions[0].text],
                  },
                  nextId: null,
                  questionId: QUESTION.CHOOSE_LOCATIONS,
                });
              }
            : null,
        checkboxes: {
          title: 'Choose locations…',
          options: locationOptions,
          action: values => {
            answerQuestion({
              response: {
                value: getChosenLocations(values, locationOptions),
                text: getTextForChosenLocations(values, locationOptions),
                isTags: true,
              },
              questionId: QUESTION.CHOOSE_LOCATIONS,
              nextId: null,
            });
          },
          minSelected: 1,
          getActionButtonText: selectedCount => {
            return selectedCount > 0
              ? 'Save my preferences and start gathering reviews'
              : 'Choose locations for reviews';
          },
        },

        id: QUESTION.CHOOSE_LOCATIONS,
        messages:
          locationOptions.length === 1
            ? [`It looks like you have access to ${locationOptions[0].text} Google Reviews 👌`]
            : [
                `We found ${loadedLocations.length} Google Business locations for reviews.`,
                'From which locations you want to gather reviews?',
              ],
        response: EMPTY_RESPONSE,
        visible: false,
        disableUndo: locationOptions.length === 1,
        isUserReplyDisabled: locationOptions.length === 1,
        isUserAvatarHidden: locationOptions.length === 1,
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

const mapStateToProps = state => ({
  loadedLocationlocationAnswer: getQuestionAnswer(QUESTION.LOADING_LOCATIONS)(state),
});

const mapDispatchToProps = {};

const ConnectedConversationAboutGoogleReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationAboutGoogleReview);

export default serviceConversation(ConnectedConversationAboutGoogleReview, {
  service: SERVICES.GOOGLE_REVIEW,
});
