import Analytics from 'analytics';
import postHog from '@metro-fs/analytics-plugin-posthog';
import appConfig from 'config';

export const postHogAnalytics = () => {
    return Analytics({
        app: 'flockler',
        plugins: [
            postHog({
                token: appConfig.postHogApiKey,
                enabled: true,
                options: {
                    api_host: appConfig.postHogHost,
                    debug: process.env.REACT_APP_ENV !== 'production',
                    persistence: 'memory',
                    disable_session_recording: true,
                },
            }),
        ],
    });
}

export const postHogIdentifyUser = async (userId, siteId) => {
    const tracker = postHogAnalytics();
    const groupProperties = {$group_type: 'site', $group_key: `${siteId}`}
    return tracker.identify(`${userId}`)
        .then(await tracker.track('$groupidentify', groupProperties));
}

export const postHogTrackEvent = (event, options = {}) => {
    const tracker =  postHogAnalytics();
    return tracker.track(event, options);
}
