import { setAccountId, setKeywords, setFilter, setUsernames } from './shared';
import { SERVICES } from 'constants/Services';

export const SOURCES = Object.freeze({
  ACCOUNT: 'admin_posts',
  MENTIONS: 'public_mentions',
  STORIES: 'stories',
});

export const SOURCES_DTO_FORMAT = Object.freeze({
  [SOURCES.ACCOUNT]: 'feed',
  [SOURCES.MENTIONS]: 'mentions',
  [SOURCES.STORIES]: 'stories',
});

export const INSTAGRAM_BUSINESS_ACCOUNT_TYPE = 'business';

export const INSTAGRAM_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.INSTAGRAM,
  filters: [
    {
      keywords: null,
      match_any: false,
      account_id: null,
      include: [],
    },
  ],
};

export const QUESTION_IDS = Object.freeze({
  ADD_USERS_TO_HASHTAG_FILTER: 'INSTAGRAM/ADD_USERS_TO_HASHTAG_FILTER',
  ACCOUNT: 'INSTAGRAM/ACCOUNT',
  ACCOUNT_CONNECTED_ALREADY: 'INSTAGRAM/ACCOUNT_CONNECTED_ALREADY',
  ACCOUNT_SELECTED: 'INSTAGRAM/ACCOUNT_SELECTED',
  CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT: 'INSTAGRAM/CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT',
  CONNECT_ACCOUNT: 'INSTAGRAM/CONNECT_ACCOUNT',
  ERROR_LOADING_PAGES: 'INSTAGRAM/ERROR_LOADING_PAGES',

  HASHTAG_INPUT: 'INSTAGRAM/HASHTAG_INPUT',
  LOADING_PAGES: 'INSTAGRAM/LOADING_PAGES',
  MENTION: 'INSTAGRAM/MENTION',
  MENTIONS_SELECTED: 'INSTAGRAM/MENTIONS_SELECTED',
  NOT_ANY_INSTAGRAM_ACCOUNTS: 'INSTAGRAM/NOT_ANY_INSTAGRAM_ACCOUNTS',
  NO_BUSINESS_ACCOUNT_CONNECTED_YET: 'INSTAGRAM/NO_BUSINESS_ACCOUNT_CONNECTED_YET',
  STORIES_SELECTED: 'INSTAGRAM/STORIES_SELECTED',
  USER_INPUT: 'INSTAGRAM/USER_INPUT',
  WHAT_TO_COLLECT: 'INSTAGRAM/WHAT_TO_COLLECT',
  MEDIA_TYPES_TO_COLLECT: 'INSTAGRAM/MEDIA_TYPES_TO_COLLECT',
  WHAT_TYPE_OF_MEDIA_TO_COLLECT: 'INSTAGRAM/WHAT_TYPE_OF_MEDIA_TO_COLLECT',

  INITIAL_WHAT_TO_COLLECT: 'INSTAGRAM/INITIAL_WHAT_TO_COLLECT',
  CONNECT_BASIC_OR_BUSINESS: 'INSTAGRAM/CONNECT_BASIC_OR_BUSINESS',
  BASIC_ACCOUNT_CONNECTED_ALREADY: 'INSTAGRAM/BASIC_ACCOUNT_CONNECTED_ALREADY',
  CONNECT_ACCOUNT_BASIC_OR_BUSINESS: 'INSTAGRAM/CONNECT_ACCOUNT_BASIC_OR_BUSINESS',
  CREATE_BASIC_USER_FEED: 'INSTAGRAM/CREATE_BASIC_USER_FEED',
  SET_BASIC_USER_FEED_FILTERS: 'INSTAGRAM/SET_BASIC_USER_FEED_FILTERS',
});

// Returns updated feed config state
export function instagramFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.ACCOUNT_CONNECTED_ALREADY:
    case QUESTION_IDS.NO_BUSINESS_ACCOUNT_CONNECTED_YET:
    case QUESTION_IDS.CONNECT_BASIC_OR_BUSINESS: {
      return setAccountId(state, response.value);
    }

    // Set up the Facebook account used
    case QUESTION_IDS.CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT: {
      return setFilter(state, response.value);
    }

    case QUESTION_IDS.USER_INPUT: {
      return setUsernames(state, response.value);
    }

    case QUESTION_IDS.HASHTAG_INPUT: {
      return setKeywords(state, response.value);
    }

    case QUESTION_IDS.MEDIA_TYPES_TO_COLLECT: {
      return setFilter(state, { media_types: response.value ? [response.value] : null });
    }

    case QUESTION_IDS.INITIAL_WHAT_TO_COLLECT: {
      return {
        ...state,
        service: SERVICES.INSTAGRAM,
        filters: [
          {
            ...(state.filters?.[0] || {}),
            include: Object.values(SOURCES).includes(response.value) ? [response.value] : [],
          },
        ],
      };
    }

    case QUESTION_IDS.CREATE_BASIC_USER_FEED: {
      return {
        ...state,
        account_id: response.value.id,
        service: SERVICES.INSTAGRAM_BASIC,
        filters: [
          {
            username: response.value.name,
          },
        ],
      };
    }

    default: {
      return state;
    }
  }
}
