// # Media tracker accounts concepts

import { createSelector } from 'reselect';
import filter from 'lodash/fp/filter';
import includes from 'lodash/includes';
import { fetchUserSocialMediaAccounts } from 'services/api';
import { SERVICES } from 'constants/Services';
import { INSTAGRAM_MANAGE_INSIGHTS } from 'constants/FacebookScopes';

// Action Types
export const ACTIONS = Object.freeze({
  FETCH_ACCOUNTS: 'MT_ACCOUNT/FETCH_ACCOUNTS',
  FETCH_ACCOUNTS_SUCCESS: 'MT_ACCOUNT/FETCH_ACCOUNTS_SUCCESS',
  FETCH_ACCOUNTS_FAIL: 'MT_ACCOUNT/FETCH_ACCOUNTS_FAIL',
});

// Selectors
export const getSocialMediaAccountsState = state => state.socialMediaAccount.accounts;
export const getAccountsLoadingState = state => state.socialMediaAccount.isLoading;

export const getSocialMediaAccounts = createSelector(
  getSocialMediaAccountsState,
  filter(mt => !mt.status || mt.status.invalid !== true) // mt.channel !== 'facebook' && TEMP facebook filter
);

// Get social media account where channel value matches service parameter
export const getServiceSocialMediaAccounts = service =>
  createSelector(getSocialMediaAccounts, accounts => {
    if (!service) {
      return [];
    }

    // Instagram accounts are in Facebook accounts or basic Instagram accounts
    if (service === SERVICES.INSTAGRAM) {
      return accounts.filter(({ ready_to_use_for_instagram, facebook_scopes, channel }) => {
        const isBusinessAccount =
          // has to have a ready to use IG flag
          ready_to_use_for_instagram &&
          // needs to contain scope for managing insights
          includes(facebook_scopes, INSTAGRAM_MANAGE_INSIGHTS) &&
          // needs to be FacebookAccount
          channel === SERVICES.FACEBOOK;

        const isBasicInstagramAccount = channel === 'instagram';

        return isBusinessAccount || isBasicInstagramAccount;
      });
    }

    // For google review feeds service is 'google_review', but account channel is 'googlemybusiness'
    if (service === SERVICES.GOOGLE_REVIEW) {
      return accounts.filter(({ channel }) => channel === 'googlemybusiness');
    }

    return accounts.filter(({ channel }) => channel === service);
  });

// Action Creators
export const fetchSocialMediaAccounts = () => dispatch => {
  // Start fetching
  dispatch({ type: ACTIONS.FETCH_ACCOUNTS });

  // Fetch accounts
  return fetchUserSocialMediaAccounts()
    .then(response => dispatch({ type: ACTIONS.FETCH_ACCOUNTS_SUCCESS, payload: response.data }))
    .catch(() => dispatch({ type: ACTIONS.FETCH_ACCOUNTS_FAIL }));
};

// Reducer
export const initialState = Object.freeze({
  accounts: [],
  isLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.FETCH_ACCOUNTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ACTIONS.FETCH_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accounts: action.payload,
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_ACCOUNTS_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}
