// These are not valid in pinterest URLS
import replace from 'lodash/replace';
import includes from 'lodash/includes';
import trim from 'lodash/trim';

const FORBIDDEN_USERNAME_CANDIDATES = ['pin', 'news_hub', 'settings', 'search'];
const FORBIDDEN_BOARD_CANDIDATES = [
  '_activities',
  '_followers',
  '_following',
  '_pins',
  'boards',
  'topics',
  'pins',
  'tried',
];

const parseValid = (text, notValidTexts) => {
  if (!text || includes(text, '/') || includes(notValidTexts, text.toLowerCase())) {
    return null;
  }

  return text;
};

export const parsePinterestUrl = url => {
  // Because we are using rss feed to gather content, there is possibility that
  // someone uses rss-link directly as Pinterest URL.
  // "*.rss" cannot be name of board or user, so we need to remove it
  const nonRSSUrl = (url || '').replace('.rss', '');

  const match = /pinterest\.[^/]+\/([^/]+)(?:\/([^/]+))?\/?/.exec(nonRSSUrl);

  if (!match) {
    return null;
  }

  const user = parseValid(match[1], FORBIDDEN_USERNAME_CANDIDATES);
  const board = trim(parseValid(match[2], FORBIDDEN_BOARD_CANDIDATES));

  let parts = {};

  if (!user) {
    return { error: true };
  }

  // there needs to be a user
  parts = { ...parts, user };

  if (board) {
    parts = { ...parts, board };
  }

  return parts;
};

export const parsePinterestUser = usernameCandidate => {
  const processedCandidate = replace(trim(usernameCandidate), '@', '');
  return parseValid(processedCandidate, FORBIDDEN_USERNAME_CANDIDATES);
};

export const formPinterestRssUrl = opts => {
  const { user, board } = opts || {};

  if (!user) {
    return null;
  }

  const maybeBoardPart = board ? `/${board}` : '';

  return `https://pinterest.com/${user}${maybeBoardPart}.rss`;
};
