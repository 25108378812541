import React from 'react';
import LoadingIndicator from 'components/LoadingIndicator';
import styles from './LogoLoader.module.scss';

const LogoLoader = () => (
  <div className={styles.wrap}>
    <LoadingIndicator infinite />
    <div className={styles.logo}>
      <svg viewBox="0 0 257 60" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <g fill="currentColor">
            <path d="M123.496 44.473c-1.87 2.064-4.56 3.358-7.526 3.358-5.76 0-10.24-4.638-10.24-10.398s4.48-10.4 10.24-10.4c2.513 0 4.825.932 6.612 2.47l6.828-8.093c-3.547-2.98-8.17-4.78-13.52-4.78-11.52 0-20.72 9.282-20.72 20.802s9.2 20.8 20.72 20.8c5.54 0 10.305-1.927 13.898-5.1l-6.292-8.66z" />
            <path d="M80.93 37.432c0-5.68-4.48-10.4-10.08-10.4-5.68 0-10 4.72-10 10.4 0 5.68 4.32 10.4 10 10.4 5.6 0 10.08-4.72 10.08-10.4m-30.8 0c0-11.52 9.2-20.8 20.72-20.8s20.72 9.28 20.72 20.8c0 11.52-9.2 20.8-20.72 20.8s-20.72-9.28-20.72-20.8" />
            <path d="M149.49 41.432l-5.36 6.32v9.68h-10.96v-55.4h10.96v33.08l14.72-17.68h12.72l-14.56 17.28 15.36 22.72h-12l-10.88-16z" />
            <path d="M175.727 2.03h10.96v55.402h-10.96v-55.4z" />
            <path d="M256.025 17.453c-14.997.228-21.1 6.615-21.1 20.994v18.985h10.96v-18.88c0-7.44 2.56-10.48 8.4-10.48h1.74v-10.62z" />
            <path d="M202.257 33.717h17.494c-.978-5.056-4.037-7.91-8.603-7.91-5.017 0-7.952 3.017-8.89 7.91m28.177 3.67c0 1.223-.082 2.692-.203 3.59h-28.014c1.14 5.707 5.138 8.194 10.153 8.194 3.315 0 5.833-.83 8.58-3.066.09-.074 1.056-.94 1.148-1.018l5.535 7.98c-.168.14-.338.273-.51.405-4.237 3.257-9.427 4.754-15.365 4.754-12.11 0-20.51-8.237-20.51-20.674s8.154-20.918 19.98-20.918c11.376 0 19.165 8.44 19.206 20.755" />
            <path d="M44.807 38.42v-22c0-6.292-4.168-15.65-15.65-15.65h-.513c3.617 3.343 4.958 8.382 4.958 12.326V40.1c.636 14.537 4.687 19.396 17.104 19.396-5.083-2.4-5.9-11.043-5.9-21.077" />
            <path d="M24.715 2.763c-2.363-1.523-5.477-1.875-9.163-1.08C8.28 4.144 5.47 11.284 5.47 16.42v1.76H.12v11.3h5.35v27.94h11.3V29.48h7.35v-11.3h-7.35v-1.76c0-.402.035-.87.145-1.35.584-3.32 3.475-5.844 6.962-5.844 2.322 0 4.376 1.123 5.666 2.85h.012c-.23-3.3-1.578-7.213-4.84-9.313" />
          </g>
        </g>
      </svg>
    </div>
  </div>
);

export default LogoLoader;
