import React from 'react';
import PropTypes from 'prop-types';

import UserMessageActionCard from 'components/UserMessageActionCard';

const UserMessageCustomAction = ({ children, title }) => {
  return (
    <UserMessageActionCard title={title}>
      <React.Fragment>{children}</React.Fragment>
    </UserMessageActionCard>
  );
};

UserMessageCustomAction.defaultProps = {
  children: undefined,
  title: null,
};

UserMessageCustomAction.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default React.memo(UserMessageCustomAction);
