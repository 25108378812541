import { useCookies } from 'react-cookie';
import config from 'config';
import queryString from 'query-string';

export const CONSENT_COOKIE_NAME = 'flockler_website_consent';

export const CONSENT_TYPES = [
  'functional',
  'analytics',
  'customer_support',
  'social_media_and_marketing',
];

export const CONSENT_COOKIE_EXPIRES_IN_YEARS = 10;

export const getConsent = cookies => {
  const consentCookie = cookies[CONSENT_COOKIE_NAME];

  if (!consentCookie) {
    return undefined;
  }

  return consentCookie;
};

/**
 * Creates consent object for cookie from given consent types
 * @param {string[]} consent - Array of accepted consent types
 */
export const getConsentCookieValues = consent => {
  return CONSENT_TYPES.reduce((sum, current) => {
    sum[current] = !!consent.includes(current);
    return sum;
  }, {});
};

export const useConsent = () => {
  const [cookies, setCookie] = useCookies([CONSENT_COOKIE_NAME]);
  const consent = getConsent(cookies);

  const setCookieWithApiCall = consent => {
    var consentParams = queryString.stringify(consent);
    fetch(`${config.flocklerCookiesUrl}/service/cookie-consent?${consentParams}`, {
      credentials: 'include',
    });
  };

  const setConsent = consent => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + CONSENT_COOKIE_EXPIRES_IN_YEARS);

    // Set cookie with client-side implementation.
    // Calling this is required, because react-cookie is real-time
    // watching cookie changes that are only made with setCookie function.
    setCookie(CONSENT_COOKIE_NAME, consent, {
      secure: true,
      domain: config.flocklerBaseDomain,
      expires,
      path: '/',
    });

    // Set cookie with server-side implementation.
    // We get longer lifetime for cookie with all browsers!
    setCookieWithApiCall(consent);
  };

  return [consent, setConsent];
};
