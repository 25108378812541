import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import replace from 'lodash/replace';

import { fetchBusinessAccounts } from 'concepts/instagram';
import { ReactComponent as UserSVG } from 'assets/svgs/icon-user-circle.svg';
import { ReactComponent as AddSVG } from 'assets/svgs/icon-add.svg';
import { parseInstagramUserUrl } from 'services/instagram-url';
import styles from './InstagramUserInput.module.scss';
import LoadingIndicator from 'components/LoadingIndicator';
import EmptySearchResult from 'components/EmptySearchResult';

class InstagramUserInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputText: '',
      searchResult: null,
      isSearching: false,
      debouncedSearch: null,
      noUserFound: false,
    };
  }

  handleInputChange = e => {
    const inputText = e.target.value;
    const { username, error } = parseInstagramUserUrl(inputText) || { username: inputText };

    if (this.state.debouncedSearch) {
      this.state.debouncedSearch.cancel();
    }

    this.setState({ inputText, debouncedSearch: null, noUserFound: false });

    // Debounced Search logic
    if (!error && username.length >= 3) {
      const debouncedSearch = debounce(() => {
        this.setState({ isSearching: true });
        // execute search
        this.checkUsername(username);

        // also clear debounced search from state
        this.setState({ debouncedSearch: null });
      }, 400);

      // Update state with debounced search function
      this.setState({ debouncedSearch });

      // Start debounced search
      debouncedSearch();
    } else {
      this.setState({ searchResult: null, isSearching: false });
    }
  };

  checkUsername = username => {
    const { fetchBusinessAccounts } = this.props;
    const usernameCandidate = replace(username, '@', '');

    return fetchBusinessAccounts(usernameCandidate)
      .then(searchResult => this.setState({ searchResult, isSearching: false, noUserFound: false }))
      .catch(() => this.setState({ searchResult: null, isSearching: false, noUserFound: true }));
  };

  maybeSubmit = e => {
    e.preventDefault();
    const { searchResult } = this.state;
    const { action } = this.props;

    if (searchResult) {
      action(searchResult);
    }
  };

  renderEmptyState = () => {
    const { inputText } = this.state;
    const { username } = parseInstagramUserUrl(inputText) || { username: inputText };

    return (
      <div className={styles.emptyResult}>
        <div>
          <span className={styles.infoIcon}>i</span>
        </div>
        <div>
          <EmptySearchResult
            title={`No business account *${username}* found`}
            tipText={
              <>
                Make sure the URL is correct and that the account is switched to{' '}
                <a
                  href="https://www.facebook.com/business/learn/lessons/create-an-instagram-business-profile"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram Business version
                </a>
              </>
            }
          />
        </div>
      </div>
    );
  };

  render() {
    const { inputText, searchResult, isSearching, noUserFound } = this.state;
    const { action, ...rest } = this.props;

    const { username } = parseInstagramUserUrl(inputText) || { username: inputText };

    const isLinkValid = !!username;
    const isErrorMessageVisible = inputText.length > 0 && !isLinkValid;
    const errorMessage = isErrorMessageVisible ? (
      <span className={styles.inputValidationError}>Please enter URL to Instagram account</span>
    ) : null;

    const isNoResultsVisible = !!inputText && !isSearching && !searchResult && noUserFound;

    return (
      <div className={styles.userSearch}>
        <span className={styles.title}>…or search by profile link or username{errorMessage}</span>

        <form onSubmit={this.maybeSubmit}>
          <div className={styles.inputWrapper}>
            <UserSVG className={classnames(styles.linkIcon, { [styles.valid]: !!searchResult })} />
            <input
              autoFocus
              type="text"
              className={styles.input}
              onChange={this.handleInputChange}
              value={inputText}
              {...rest}
            />

            {isSearching && (
              <div className={styles.loading}>
                <LoadingIndicator />
              </div>
            )}
          </div>
        </form>

        {!!searchResult && (
          <button
            className={styles.userSearchResult}
            onClick={() => action(searchResult)}
            title={`From account @${searchResult.username}`}
          >
            {!!searchResult.profile_picture_url && (
              <span
                style={{ backgroundImage: `url(${searchResult.profile_picture_url})` }}
                className={styles.userImage}
              />
            )}
            <span className={styles.userInfo}>
              <span className={styles.userName}>{searchResult.name}</span>
              <span className={styles.userDescription}>@{searchResult.username}</span>
            </span>
            <span className={styles.plusSign}>
              <AddSVG className={styles.plusSignIcon} />
            </span>
          </button>
        )}
        {isNoResultsVisible && this.renderEmptyState()}
      </div>
    );
  }
}

InstagramUserInput.propTypes = {
  action: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  fetchBusinessAccounts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchBusinessAccounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstagramUserInput);
