const BASE_PAGE_TITLE = 'Flockler Feeds';

export const updatePageTitle = ({ feedType } = {}) => {
  const titleParts = [BASE_PAGE_TITLE];

  if (feedType) {
    titleParts.push(`Create ${feedType} feed`);
  }

  document.title = titleParts.join(' | ');
};
