import { setAccountId, setFilter } from './shared';
import { SERVICES } from 'constants/Services';

export const GOOGLE_REVIEW_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.GOOGLE_REVIEW,
  filters: [{}],
};

export const QUESTION_IDS = {
  CHOOSE_MODERATION: 'GOOGLE_REVIEW/CHOOSE_MODERATION',
  CONNECT_ACCOUNT: 'GOOGLE_REVIEW/CONNECT_ACCOUNT',
  NO_ACCOUNT_CONNECTED_YET: 'GOOGLE_REVIEW/NO_ACCOUNT_CONNECTED_YET',
  ACCOUNT_CONNECTED_ALREADY: 'GOOGLE_REVIEW/ACCOUNT_CONNECTED_ALREADY',

  LOADING_LOCATIONS: 'GOOGLE_REVIEW/LOADING_LOCATIONS',
  CHOOSE_LOCATIONS: 'GOOGLE_REVIEW/CHOOSE_LOCATIONS',
  NO_LOCATIONS_FOUND: 'GOOGLE_REVIEW/NO_LOCATIONS_FOUND',
  ERROR_LOADING_LOCATIONS: 'GOOGLE_REVIEW/ERROR_LOADING_LOCATIONS',
};

// Returns updated feed config state
export function googleReviewFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.ACCOUNT_CONNECTED_ALREADY:
    case QUESTION_IDS.NO_ACCOUNT_CONNECTED_YET: {
      let newState = setAccountId(state, response.value);
      newState = setAuthor(newState, response.account?.name);
      return newState;
    }

    case QUESTION_IDS.CHOOSE_LOCATIONS: {
      const locationFilters = response.value.map(locationFilter => ({
        location_id: locationFilter.value,
        name: locationFilter.text,
        location_url: locationFilter.url,
      }));

      return setLocationFilters(state, locationFilters);
    }

    default: {
      return state;
    }
  }
}

function setAuthor(prevConfig, author) {
  return {
    ...prevConfig,
    filters: [
      {
        ...prevConfig.filters[0],
        author,
      },
    ],
  };
}

export function setLocationFilters(prevConfig, locations) {
  return setFilter(prevConfig, { locations });
}
