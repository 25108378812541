import React from 'react';
import PropTypes from 'prop-types';

import styles from './EmptySearchResult.module.scss';

const EmptyResult = ({ title, tipText }) => (
  <div>
    <div className={styles.title}>{title}</div>
    {!!tipText && (
      <div className={styles.tip}>
        <span className={styles.tipTitle}>Tip:</span> {tipText}
      </div>
    )}
  </div>
);

EmptyResult.propTypes = {
  title: PropTypes.string.isRequired,
  tipText: PropTypes.string,
};

EmptyResult.defaultProps = {
  tipText: undefined,
};

export default React.memo(EmptyResult);
