import { connect } from 'react-redux';
import { getUserAvatar, getUsername } from 'concepts/user';
import UserMessage from 'components/UserMessage';

const mapStateToProps = state => ({
  avatarSrc: getUserAvatar(state),
  username: getUsername(state),
});

export default connect(mapStateToProps)(UserMessage);
