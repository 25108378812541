import { setAccountId } from './shared';
import { SERVICES } from 'constants/Services';

export const TIKTOK_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.TIKTOK,
  filters: [{}],
};

export const QUESTION_IDS = {
  NO_ACCOUNT_CONNECTED_YET: 'TIKTOK/NO_ACCOUNT_CONNECTED_YET',
  ACCOUNT_CONNECTED_ALREADY: 'TIKTOK/ACCOUNT_CONNECTED_ALREADY',
  INVALID_TOKEN: 'TIKTOK/INVALID_TOKEN',
  CHECKING_TOKEN: 'TIKTOK/CHECKING_TOKEN',
};

// Returns updated feed config state
export function tiktokFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.ACCOUNT_CONNECTED_ALREADY:
    case QUESTION_IDS.NO_ACCOUNT_CONNECTED_YET: {
      let newState = setAccountId(state, response.value);
      newState = setUsername(newState, response.account?.name);
      return newState;
    }

    default: {
      return state;
    }
  }
}

function setUsername(prevConfig, username) {
  return {
    ...prevConfig,
    filters: [
      {
        ...prevConfig.filters[0],
        username,
      },
    ],
  };
}
