import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { useConsent } from 'services/cookie-policy';
import { fetchSignupTracking } from 'services/api';
import { getInternationalization } from 'concepts/i18n';
import { getSiteId } from 'concepts/site';
import loadSignupTrackingGtm from 'services/load-signup-tracking-gtm';

const SignupTracker = ({ siteId, internationalization }) => {
  const [consent] = useConsent();
  const [hasCheckedSignupTracking, setHasCheckedSignupTracking] = useState(false);

  useEffect(() => {
    if (siteId && !hasCheckedSignupTracking && internationalization) {
      fetchSignupTracking(siteId).then(response => {
        if (response.status === 202) {
          const isConsentRequired = internationalization['flockler-is-eu-member-state'];
          loadSignupTrackingGtm(isConsentRequired, consent);
        }
      });

      setHasCheckedSignupTracking(true);
    }
  }, [siteId, hasCheckedSignupTracking, internationalization, consent]);

  return null;
};

const mapStateToProps = state => ({
  siteId: getSiteId(state),
  internationalization: getInternationalization(state),
});

export default connect(mapStateToProps)(SignupTracker);
