export function joinWithAnd(arrayOfItems) {
  if (!arrayOfItems || !arrayOfItems.length) {
    return '';
  }

  // no-mutating reverse
  const [last, ...rest] = [...arrayOfItems].reverse();

  return arrayOfItems.length > 1 ? `${rest.reverse().join(', ')} and ${last}` : last;
}
