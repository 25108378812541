// This service handles formatting simple media-tracker objects to extended data transfer object (DTO) format.
//
// See documentation in:
// https://github.com/flockler/Flockler-Magazine/issues/10351

import get from 'lodash/get';
import isNil from 'lodash/isNil';
import toString from 'lodash/toString';
import includes from 'lodash/includes';
import { SERVICES } from 'constants/Services';
import { SOURCES_DTO_FORMAT as FACEBOOK_SOURCES_DTO_FORMAT } from 'services/media-trackers/facebook';
import {
  SOURCES_DTO_FORMAT as INSTAGRAM_SOURCES_DTO_FORMAT,
  INSTAGRAM_BUSINESS_ACCOUNT_TYPE,
} from 'services/media-trackers/instagram';
import { SOURCES as YOUTUBE_SOURCES } from 'services/media-trackers/youtube';

// Helpers
const when = (condition, value, defaultValue = null) => (!!condition ? value : defaultValue);
const createFilter = (name, rest) => ({ filter_type: name, ...rest });
const notNull = val => !isNil(val);

// Formats ('foo', 'bar') => { name: 'foo', value: 'bar'}
const attribute = (name, value) => ({ name, value });

const createKeywordFilters = (keywords = []) => {
  if (!keywords) {
    return [];
  }

  return keywords.map(keyword =>
    createFilter('match_tag', { parameters_attributes: [attribute('name', keyword)] })
  );
};

const createUsernameFilters = (usernames = []) =>
  (usernames || []).map(username =>
    createFilter('match_author', { parameters_attributes: [attribute('name', username)] })
  );

const createSimpleUsernameFilters = (usernames = []) =>
  (usernames || []).map(username =>
    createFilter('match_simple_author', {
      parameters_attributes: [attribute('name', username)],
    })
  );

const createMediaTypeFilters = (media_types = []) =>
  (media_types || []).map(mediaType =>
    createFilter('match_media_type', {
      parameters_attributes: [attribute('media_type', mediaType)],
    })
  );

const createFacebookPageFilter = filters => {
  const hasKeywords = filters.keywords && filters.keywords.length > 0;

  return createFilter('match_author', {
    parameters_attributes: [
      attribute('name', filters.page_id),
      attribute('visible_name', filters.page_name),
      attribute('type', 'page'),
      attribute(
        'endpoints',
        filters.include.map(source => FACEBOOK_SOURCES_DTO_FORMAT[source]).join(',')
      ),
      ...(hasKeywords ? filters.keywords.map(keyword => attribute('keyword', keyword)) : []),

      when(hasKeywords, attribute('match_any_keyword', filters.match_any ? '1' : '0')),
    ].filter(notNull),
  });
};

const getInstagramAccountFeedVisibleName = filters => {
  return filters.account_name || `@${filters.account_username}`;
};

const createInstagramAccountFilter = filters => {
  const { keywords, include } = filters;
  const hasKeywords = !!keywords && keywords.length > 0;
  const hasInclude = !!include && hasKeywords;

  const isKeywordFilterRelatedToAccount = hasInclude && hasKeywords;

  return createFilter('match_author', {
    parameters_attributes: [
      attribute('name', filters.page_id),
      attribute('user_id', filters.account_id),
      attribute('visible_name', getInstagramAccountFeedVisibleName(filters)),
      attribute('username', filters.account_username),
      when(filters.access_token, attribute('facebook_page_access_token', filters.access_token)),
      attribute(
        'endpoints',
        filters.include.map(source => INSTAGRAM_SOURCES_DTO_FORMAT[source]).join(',')
      ),
      // Conditional keywords
      ...(isKeywordFilterRelatedToAccount
        ? filters.keywords.map(keyword => attribute('keyword', keyword))
        : []),

      // Conditional match_any_keyword
      when(
        isKeywordFilterRelatedToAccount,
        attribute('match_any_keyword', filters.match_any ? '1' : '0')
      ),
    ].filter(notNull),
  });
};

const createInstagramBusinessAccountFilter = filters => {
  return createFilter('match_business_author', {
    parameters_attributes: [
      attribute('name', filters.account_username),
      when(filters.account_name, attribute('visible_name', filters.account_name)),
    ].filter(notNull),
  });
};

const createYouTubeFilter = filters => {
  const { type, channel_id, playlist_id, name, image_url, keywords, match_any } = filters;

  const isPlaylist = type === YOUTUBE_SOURCES.PLAYLIST;
  const hasKeywords = !!keywords && keywords.length > 0;
  const filterName = isPlaylist ? 'match_playlist' : 'match_channel';

  return createFilter(filterName, {
    parameters_attributes: [
      attribute('name', isPlaylist ? playlist_id : channel_id),
      attribute('visible_name', name),
      attribute('thumb_url', image_url),

      // Conditional keywords
      ...(hasKeywords ? keywords.map(keyword => attribute('keyword', keyword)) : []),

      // Conditional match_any_keyword
      when(hasKeywords, attribute('match_any_keyword', match_any ? '1' : '0')),
    ].filter(notNull),
  });
};

const createLinkedInAuthorFilter = filters => {
  const { page_id, name, image_url } = filters;

  return createFilter('match_author', {
    parameters_attributes: [
      attribute('name', toString(page_id)),
      attribute('visible_name', name),
      attribute('logo', image_url),
      attribute('square_logo', image_url),
    ],
  });
};

const createPinterestBoardFilter = filters => {
  const { author, category } = filters;

  return createFilter('match_category_by_author', {
    parameters_attributes: [
      attribute('author_name', author),
      attribute('category_name', category), // board name
    ],
  });
};

const createPinterestUserFilter = filters => {
  const { author } = filters;

  return createFilter('match_author', {
    parameters_attributes: [attribute('name', author)],
  });
};

const createRssUrlFilter = filters => {
  const { rss_url } = filters;

  return createFilter('match_url', {
    parameters_attributes: [attribute('url', rss_url)],
  });
};

const createFilterSet = (mtObjectFilter, ruleSet) =>
  ruleSet.map(rule => when(mtObjectFilter[rule], createFilter(rule)));

const getMediaTrackerFiltersForService = (service, filters) => {
  let serviceFilters;
  switch (service) {
    case SERVICES.INSTAGRAM: {
      const isInstagramBusinessAccountFeed =
        filters.account_type === INSTAGRAM_BUSINESS_ACCOUNT_TYPE;
      const isInstagramAccountFeed =
        !isInstagramBusinessAccountFeed && filters.include && filters.include.length > 0;
      const isHashtagFeed = !isInstagramAccountFeed && !isInstagramBusinessAccountFeed;

      serviceFilters = [
        ...(filters.media_types && filters.media_types.length > 0
          ? createMediaTypeFilters(filters.media_types)
          : []),
        isInstagramAccountFeed ? createInstagramAccountFilter(filters) : null,
        isInstagramBusinessAccountFeed ? createInstagramBusinessAccountFilter(filters) : null,
        ...(isHashtagFeed || isInstagramBusinessAccountFeed
          ? createKeywordFilters(filters.keywords)
          : []),
        ...(isHashtagFeed ? createSimpleUsernameFilters(filters.usernames) : []),
      ];
      break;
    }

    case SERVICES.INSTAGRAM_BASIC: {
      serviceFilters = [
        createFilter('match_author', {
          parameters_attributes: [attribute('name', filters.username)],
        }),

        ...(filters.media_types && filters.media_types.length > 0
          ? createMediaTypeFilters(filters.media_types)
          : []),

        ...(createKeywordFilters(filters.keywords) || []),
      ];

      break;
    }

    case SERVICES.FACEBOOK: {
      serviceFilters = [createFacebookPageFilter(filters)];
      break;
    }

    case SERVICES.GOOGLE_REVIEW: {
      const locationFilters = (filters.locations || []).map(location =>
        createFilter('match_location', {
          parameters_attributes: [
            attribute('name', location.name),
            attribute('location_id', location.location_id),
            attribute('location_url', location.location_url),
          ],
        })
      );

      serviceFilters = [...locationFilters];
      break;
    }

    case SERVICES.TIKTOK: {
      serviceFilters = [
        createFilter('match_author', {
          parameters_attributes: [attribute('name', filters.username)],
        }),
      ];

      break;
    }

    case SERVICES.TWITTER_V2:
    case SERVICES.TWITTER: {
      serviceFilters = [
        ...createFilterSet(filters, [
          'no_replies',
          'no_republished_media',
          'pictures_only',
          'videos_only',
        ]),
        ...(filters.usernames ? createUsernameFilters(filters.usernames) : []),
        ...(filters.keywords ? createKeywordFilters(filters.keywords) : []),
      ];
      break;
    }

    case SERVICES.LINKEDIN: {
      serviceFilters = [
        createLinkedInAuthorFilter(filters),

        ...(filters.locations
          ? (filters.locations || []).map(location =>
              createFilter('match_location', {
                parameters_attributes: [
                  attribute('name', location.name),
                  attribute('location_id', location.id),
                ],
              })
            )
          : []),

        ...(filters.locales
          ? (filters.locales || []).map(locale =>
              createFilter('match_locale', {
                parameters_attributes: [
                  attribute('name', locale.name),
                  ...(locale.nativeName ? [attribute('native_name', locale.nativeName)] : []),
                  attribute('locale_code', locale.code),
                ],
              })
            )
          : []),
      ];
      break;
    }

    case SERVICES.YOUTUBE: {
      const isChannelOrPlaylistFilter =
        filters.type && includes([YOUTUBE_SOURCES.PLAYLIST, YOUTUBE_SOURCES.CHANNEL], filters.type);

      serviceFilters = [
        // keyword only
        ...when(!isChannelOrPlaylistFilter, createKeywordFilters(filters.keywords), []),

        // channel/playlist
        isChannelOrPlaylistFilter ? createYouTubeFilter(filters) : null,
      ];
      break;
    }

    case SERVICES.PINTEREST: {
      const isBoardFeed = !isNil(filters.category);

      serviceFilters = [
        isBoardFeed ? createPinterestBoardFilter(filters) : createPinterestUserFilter(filters),
      ];

      break;
    }

    case SERVICES.RSS: {
      serviceFilters = [createRssUrlFilter(filters)];

      break;
    }

    default: {
      serviceFilters = [];
      break;
    }
  }

  return serviceFilters.filter(notNull);
};

export function feedConfigToDto(mt, opts = {}) {
  const { service, name } = mt;
  // currently we support only first item of array in filters
  const mtFilters = get(mt, ['filters', 0], {});
  const hasKeywords = mtFilters.keywords && mtFilters.keywords.length > 0;

  return {
    media_tracker: {
      name: name || '',
      soft_immutable: false,
      currently_fetching_history: false,
      history_post_processors_running: false,
      social_media_account_id: mt.account_id,
      service: mt.service,

      // Subscription attributes
      media_tracker_subscriptions_attributes: [
        {
          subscriber_id: opts.isPreview ? null : toString(get(mt, ['section_ids', 0], '')),
          subscriber_type: 'Section',
          ...when(mt.moderated && mt.moderated !== 'moderation', {
            parameters_attributes: [
              {
                name: mt.moderated,
              },
            ],
          }),
        },
      ],

      // Filters
      media_tracker_filters_attributes: getMediaTrackerFiltersForService(service, mtFilters),

      // Preview key is created with history_preview
      // For this request media_tracker is not enabled yet
      preview_key: opts.isPreview ? null : mt.preview_key || null,
      media_tracker_setting_attributes: {
        enabled: !opts.isPreview,
      },

      parameters_attributes: [
        attribute('fetch_old_content', '1'),

        // Add this filter only if keyword filter exists
        when(hasKeywords, attribute('match_any_keyword', mtFilters.match_any ? '1' : '0')),
      ].filter(notNull),
    },
  };
}

export function previewConfigToDto(mtObjectFilter) {
  return feedConfigToDto(mtObjectFilter, { isPreview: true });
}
