import React from 'react';
import PropTypes from 'prop-types';

import config from 'config';
import BotMessage from 'components/BotMessage';
import BotMessageContent from 'components/BotMessageContent';
import UserMessage from 'containers/UserMessageWithAvatar';
import UserMessageActions from 'components/UserMessageActions';
import PersistentUserMessageActions from 'components/UserMessageActions/Persistent';
import UserMessageAutoAction from 'components/UserMessageAutoAction';
import UserMessageCheckboxActions from 'components/UserMessageCheckboxActions';
import UserMessageSearchAction from 'components/UserMessageSearchAction';
import UserMessageSelectActions from 'components/UserMessageSelectActions';
import UserMessageCustomAction from 'components/UserMessageCustomAction';
import UserMessageResponse from 'components/UserMessageResponse';
import UserMessageResponseDropdown from 'components/UserMessageResponseDropdown';
import SecondaryActionButton from '../SecondaryActionButton';

const REPLY_ACTION_DISPLAY_DELAY_MS = config.questionDelay;

function getIsQuestionAnswered(response) {
  return !!response && response.value !== undefined && response.value !== null;
}

class Question extends React.Component {
  state = {
    isUserReplyVisible: false,
  };

  questionBottomRef = React.createRef();

  componentDidMount() {
    const messageCount = this.props.question.messages.length;

    if (messageCount) {
      this.replyDisplayDelay = setTimeout(() => {
        this.setState({ isUserReplyVisible: true });
        this.scrollToBottom();
      }, REPLY_ACTION_DISPLAY_DELAY_MS * messageCount + 500);
    } else {
      this.setState({ isUserReplyVisible: true });
      this.scrollToBottom();
    }

    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    if (!this.questionBottomRef.current) {
      return;
    }

    this.questionBottomRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  }

  componentWillUnmount() {
    clearTimeout(this.replyDisplayDelay);
  }

  renderUserMainActions(question) {
    if (!!question.input) {
      return question.input;
    }

    if (!!question.custom) {
      return <UserMessageCustomAction {...question.custom} />;
    }

    if (!!question.select) {
      return <UserMessageSelectActions {...question.select} />;
    }

    if (!!question.search) {
      return <UserMessageSearchAction {...question.search} />;
    }

    if (!!question.autoAction) {
      return <UserMessageAutoAction action={question.autoAction} />;
    }

    if (!!question.checkboxes) {
      return <UserMessageCheckboxActions {...question.checkboxes} />;
    }

    // Keep options visible even after answer
    // - needs to revert question if answered
    // - supports only basic button actions
    if (question.forceOptionsVisible) {
      return (
        <PersistentUserMessageActions
          actions={question.actions}
          actionsTitle={question.actionsTitle}
          actionButtonType={question.actionButtonType}
          answer={question.response}
          undoAction={question.undoAction || this.props.revertToQuestion}
          actionButtonsDirection={question.actionButtonsDirection}
        />
      );
    }

    return (
      <UserMessageActions
        actions={question.actions}
        actionButtonsDirection={question.actionButtonsDirection}
      />
    );
  }

  renderOnLoad(question) {
    if (!question.onLoad) {
      return null;
    }

    return <UserMessageAutoAction action={question.onLoad} />;
  }

  renderUserActions = () => {
    const { question } = this.props;
    return (
      <React.Fragment>
        {this.renderUserMainActions(question)}
        {!!question.secondaryAction && (
          <SecondaryActionButton
            onClick={question.secondaryAction.action}
            buttonClassName={question.secondaryAction.buttonClassName}
          >
            {question.secondaryAction.name}
          </SecondaryActionButton>
        )}
      </React.Fragment>
    );
  };

  renderUserResponse = () => {
    const { question, revertToQuestion } = this.props;
    const isDropdownAnswer = !!question.answerDropdownOptions && !!question.answerDropdownAction;

    if (isDropdownAnswer) {
      return (
        <UserMessageResponseDropdown
          disableUndo={question.disableUndo}
          undoText={question.undoText}
          undoAction={question.undoAction || revertToQuestion}
          options={question.answerDropdownOptions}
          onChange={question.answerDropdownAction}
          selectedOption={question.response}
          customUndoAction={question.customUndoAction}
        />
      );
    }

    return (
      <UserMessageResponse
        disableUndo={question.disableUndo}
        undoText={question.undoText}
        undoAction={question.undoAction || revertToQuestion}
        customUndoAction={question.customUndoAction}
        image={question.response.image}
        response={question.response.text}
        isTags={question.response.isTags}
        heading={question.response.heading}
      />
    );
  };

  renderBotMessages = () => {
    const { question } = this.props;

    if (question.isLoading) {
      return <BotMessageContent isLoading scrollToBottom={() => this.scrollToBottom()} />;
    }

    return (question.messages || []).map((q, i) => (
      <div key={i}>
        <BotMessageContent
          disableLoading={i === 0 && question.disableAnimation}
          index={i + 1}
          scrollToBottom={() => this.scrollToBottom()}
        >
          {q}
        </BotMessageContent>
      </div>
    ));
  };

  render() {
    const { question } = this.props;
    const { isUserReplyVisible } = this.state;

    const isQuestionAnswered = getIsQuestionAnswered(question.response);

    // autoActions need to be rendered
    const isUnansweredAutoAction = !!question.autoAction && !isQuestionAnswered;
    const isUserMessageVisible =
      (isUserReplyVisible && !question.isUserReplyDisabled) || isUnansweredAutoAction;

    const isBotMessagesVisible =
      (question.isLoading || !!question.messages.length) && !question.isBotMessageDisabled;

    return (
      <React.Fragment>
        <div>
          {isBotMessagesVisible && (
            <BotMessage
              style={question.botMessageStyle}
              contentStyle={question.botMessageContentStyle}
              hideBotAvatar={question.isBotAvatarHidden}
              disableAnimation={question.disableAnimation}
            >
              {this.renderBotMessages()}
            </BotMessage>
          )}

          {!isQuestionAnswered && this.renderOnLoad(question)}

          {isUserMessageVisible && (
            <UserMessage hideAvatar={question.isUserAvatarHidden || !question.messages.length}>
              {isQuestionAnswered && !question.forceOptionsVisible
                ? this.renderUserResponse()
                : this.renderUserActions()}
            </UserMessage>
          )}
        </div>

        {!isQuestionAnswered && (
          <div ref={this.questionBottomRef} style={{ position: 'relative', top: '6rem' }} />
        )}
      </React.Fragment>
    );
  }
}

Question.propTypes = {
  revertToQuestion: PropTypes.func.isRequired,
  question: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        messages: PropTypes.array,
        id: PropTypes.any,
        response: PropTypes.any,
        nextId: PropTypes.string,
      })
    ),
    actionsTitle: PropTypes.string,
    actionButtonType: PropTypes.string,
    actionButtonsDirection: PropTypes.string,
    errorMessage: PropTypes.string,
    forceOptionsVisible: PropTypes.bool,
  }).isRequired,
};

export default Question;
