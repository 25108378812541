import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ActionButton from 'components/ActionButton';
import styles from './UserMessageActions.module.scss';

const ServiceSelectButton = ({ isSelected, isAnswered, option, ...rest }) => {
  const [isHovered, setHovered] = React.useState(false);

  return (
    <div
      className={classnames(styles.serviceButton, styles[`serviceButton--${option.id}`], {
        [styles.selected]: isSelected,
        [styles.answered]: isAnswered,
      })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <ActionButton isSelected={isSelected} {...rest} className={styles.serviceSelectButton}>
        <span className={styles.icon}>
          {isSelected || !isAnswered || isHovered ? option.activeIcon : option.icon}
        </span>
      </ActionButton>
      <span className={styles.label}>{option.name}</span>
    </div>
  );
};

ServiceSelectButton.defaultProps = {
  isSelected: false,
  isAnswered: false,
  option: {},
};

ServiceSelectButton.propTypes = {
  isSelected: PropTypes.bool,
  isAnswered: PropTypes.bool,
  option: PropTypes.shape({
    messages: PropTypes.array,
    icon: PropTypes.any,
    activeIcon: PropTypes.any,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default ServiceSelectButton;
