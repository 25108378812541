import { SERVICES } from 'constants/Services';

export const REVIEWS_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.REVIEWS,
  filters: [{}],
};

export const QUESTION_IDS = {
  INITIAL_CHECK_REVIEW_FORMS: 'REVIEWS/INITIAL_CHECK_REVIEW_FORMS',
  ALREADY_HAS_REVIEW_FORM: 'REVIEWS/ALREADY_HAS_REVIEW_FORM',
  INTRODUCTION: 'REVIEWS/INTRODUCTION',
  CONFIRM_START: 'REVIEWS/CONFIRM_START',
  CONFIRM_DPA: 'REVIEWS/CONFIRM_DPA',
  CREATE_DEMO_REVIEWS: 'REVIEWS/CREATE_DEMO_REVIEWS',
  CREATE_REVIEW_FORM: 'REVIEWS/CREATE_REVIEW_FORM',
  EMBED_REVIEW_FORM: 'REVIEWS/EMBED_REVIEW_FORM',
  WHAT_TO_DO_NEXT: 'REVIEWS/WHAT_TO_DO_NEXT',
  TRANSITION_TO_APP: 'REVIEWS/TRANSITION_TO_APP',
};

// Returns updated feed config state
export function reviewsFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    default: {
      return state;
    }
  }
}
