// Concept for Facebook redux logic
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { getFacebookPageImage } from 'services/image';
import { QUESTION_IDS } from 'services/media-trackers/instagram';
import { getQuestionAnswer, getSelectedAccount } from 'concepts/feed-builder';
import {
  fetchFacebookPagesWithInstagramAccess,
  fetchInstagramBusinessAccounts,
} from 'services/api';

// Action types
export const ACTIONS = Object.freeze({
  FETCH_BUSINESS_ACCOUNTS: 'INSTAGRAM/FETCH_BUSINESS_ACCOUNTS',
  FETCH_BUSINESS_ACCOUNTS_SUCCESS: 'INSTAGRAM/FETCH_BUSINESS_ACCOUNTS_SUCCESS',
  FETCH_BUSINESS_ACCOUNTS_FAIL: 'INSTAGRAM/FETCH_BUSINESS_ACCOUNTS_FAIL',
});

// Selectors
export const getBusinessAccounts = state => state.instagram.businessAccounts;
export const getBusinessAccountsLoadingStatus = state => state.instagram.isLoading;

// Parses Instagram business accounts from Facebook Pages
function parseInstagramBusinessAccounts(pages) {
  return pages
    .filter(page => !isNil(page.instagram_business_account))
    .map(page => ({
      access_token: page.access_token,
      account_id: page.instagram_business_account.id,
      image: getFacebookPageImage(page.id),
      name: page.instagram_business_account.name,
      page_id: page.id,
      username: page.instagram_business_account.username,
    }));
}

export const getBusinessAccountPages = createSelector(
  getBusinessAccounts,
  parseInstagramBusinessAccounts
);

export const getSelectedInstagramUsername = createSelector(
  getQuestionAnswer(QUESTION_IDS.CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT),
  (response = {}) => response.username
);

export const getAnswerValueToWhatToCollect = createSelector(
  getQuestionAnswer(QUESTION_IDS.WHAT_TO_COLLECT),
  (response = {}) => response.value
);

export const getAnswerValueToHashtag = createSelector(
  getQuestionAnswer(QUESTION_IDS.HASHTAG_INPUT),
  (response = {}) => response.value
);

// Action creators
const fetchPagesWithInstagramAccessAction = socialMediaAccountId => dispatch => {
  // Start fetching
  dispatch({ type: ACTIONS.FETCH_BUSINESS_ACCOUNTS });

  if (isNil(socialMediaAccountId)) {
    return dispatch({ type: ACTIONS.FETCH_BUSINESS_ACCOUNTS_FAIL });
  }

  // Fetch Business accounts
  return fetchFacebookPagesWithInstagramAccess(socialMediaAccountId)
    .then(response => {
      const businessAccounts = response.data;
      // save to store
      dispatch({ type: ACTIONS.FETCH_BUSINESS_ACCOUNTS_SUCCESS, payload: businessAccounts });
      return businessAccounts;
    })
    .catch(() => {
      dispatch({ type: ACTIONS.FETCH_BUSINESS_ACCOUNTS_FAIL });
      return Promise.reject();
    });
};

export const fetchPagesWithInstagramAccess = socialMediaAccountId => dispatch => {
  return dispatch(fetchPagesWithInstagramAccessAction(socialMediaAccountId)).then(
    parseInstagramBusinessAccounts
  );
};

export const fetchBusinessAccounts = username => (dispatch, getState) => {
  const account = getSelectedAccount(getState());
  return fetchInstagramBusinessAccounts(username, account.id).then(res =>
    get(res, ['data', 'business_discovery'])
  );
};

export const initialState = Object.freeze({
  businessAccounts: [],
  isLoading: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.FETCH_BUSINESS_ACCOUNTS: {
      return {
        ...state,
        businessAccounts: [],
        isLoading: true,
      };
    }

    case ACTIONS.FETCH_BUSINESS_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        businessAccounts: action.payload || [],
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_BUSINESS_ACCOUNTS_FAIL: {
      return {
        ...state,
        businessAccounts: [],
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}
