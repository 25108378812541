import React from 'react';
import { connect } from 'react-redux';
import replace from 'lodash/replace';
import maxBy from 'lodash/maxBy';

import config from 'config';
import {
  getAnswerValueToHashtag,
  getBusinessAccountPages,
  getBusinessAccountsLoadingStatus,
  getSelectedInstagramUsername,
  fetchPagesWithInstagramAccess,
} from 'concepts/instagram';
import { connectServiceAccount, setAccountId, getQuestionAnswerValue } from 'concepts/feed-builder';
import {
  SOURCES,
  QUESTION_IDS as QUESTION,
  INSTAGRAM_BUSINESS_ACCOUNT_TYPE,
} from 'services/media-trackers/instagram';
import { getConversationState } from 'services/conversation-updater';

import { findByKey } from 'utils/common';
import { STEPS } from 'services/media-trackers/common';
import { SERVICES, FEED_TYPES } from 'constants/Services';
import { helpScoutMessage } from 'utils/help-scout';
import serviceConversation from 'components/ConversationAboutServiceHOC';
import WordInput from 'components/WordInput';
import ConversationMessageList from 'components/ConversationMessageList';
import InstagramUserInput from 'components/InstagramUserInput';
import LearnMoreLink from 'components/LearnMoreLink';
import regex from 'utils/regex';
import styles from './ConversationAboutInstagram.module.scss';

const EMPTY_RESPONSE = { value: null, text: null };

function tagList(tags) {
  return tags.map(tag => tag.text);
}

function getInstagramAnswerResponse(page, isPageAccessTokenNeeded) {
  return {
    image: page.image,
    text: page.name || page.username,
    username: page.username,
    value: {
      ...(isPageAccessTokenNeeded ? { access_token: page.access_token } : {}),
      account_id: page.account_id,
      account_username: page.username,
      account_name: page.name,
      page_id: page.page_id,
    },
  };
}

function getInstagramBusinessAccountAnswerResponse(businessAccount) {
  return {
    image: businessAccount.profile_picture_url,
    text: businessAccount.name || businessAccount.username,
    username: businessAccount.username,
    value: {
      account_username: businessAccount.username,
      account_name: businessAccount.name,
      account_type: INSTAGRAM_BUSINESS_ACCOUNT_TYPE,
    },
  };
}

function getInstagramPageMessages(pages, isSearchAvailable) {
  if (!pages.length) {
    return [];
  }

  if (pages.length > 1) {
    return [
      'Thanks! It looks like you have access to multiple Instagram Business Accounts.',
      'Which one would you like to use?',
    ];
  }

  // access to one page
  if (isSearchAvailable) {
    return [
      `Thanks! It looks like you have access to ${pages[0].name} Instagram Account.`,
      'You can also add another Instagram Business account with URL.',
    ];
  }

  // only one and search not available
  return [
    `Thanks! It looks like you have access to ${pages[0].name} Instagram account. Let’s use that! 👌`,
  ];
}

class ConversationAboutInstagram extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usernameTags: [],
      hashtagTags: [],
      isCustomUndoConnectActionVisible: false,
      initiaBusinessAccountCount: props.serviceAccounts.filter(
        account => account.channel === SERVICES.FACEBOOK
      ).length,
    };
  }

  connectBusinessAccountCallback = id => {
    const { answerQuestion, fetchPagesWithInstagramAccess, initialWhatToCollect } = this.props;

    return fetchPagesWithInstagramAccess(id)
      .then(pages => {
        if (pages.length > 0) {
          let nextId = QUESTION.CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT;

          if (initialWhatToCollect === 'hashtag') {
            nextId = QUESTION.HASHTAG_INPUT;
          }

          return answerQuestion({
            response: {
              value: 'Pages found',
              text: 'Pages found',
            },
            questionId: QUESTION.LOADING_PAGES,
            nextId,
          });
        } else {
          return answerQuestion({
            response: {
              value: 'Pages not found',
              text: 'Pages not found',
            },
            questionId: QUESTION.LOADING_PAGES,
            nextId: QUESTION.NOT_ANY_INSTAGRAM_ACCOUNTS,
          });
        }
      })
      .catch(() =>
        answerQuestion({
          response: {
            value: 'Error loading pages',
            text: 'Error loading pages',
          },
          questionId: QUESTION.LOADING_PAGES,
          nextId: QUESTION.ERROR_LOADING_PAGES,
        })
      );
  };

  revertToQuestion = value => {
    this.props.revertToQuestion(value);

    // Hide custom connect action on revert
    if (this.state.isCustomUndoConnectActionVisible) {
      this.setState({ isCustomUndoConnectActionVisible: false });
    }
  };

  getConversationTemplate = () => {
    const {
      answerQuestion,
      accountId,
      connectServiceAccount,
      getConnectAction,
      initialAccountCount,
      isLoadingBusinessAccounts,
      pages,
      serviceAccounts,
      selectedHashtags,
      selectedUsername,
      setAccountId,
      initialWhatToCollect,
    } = this.props;

    const isCollectingMentions = initialWhatToCollect === SOURCES.MENTIONS;
    const isCollectingStories = initialWhatToCollect === SOURCES.STORIES;
    const isCollectingHashtag = initialWhatToCollect === 'hashtag';
    const isCollectingAccountPosts = initialWhatToCollect === SOURCES.ACCOUNT;
    const isCollectingOwnAccountSource = isCollectingMentions || isCollectingStories;
    const hasOnlyOneAccountToSelect = pages.length === 1 && isCollectingOwnAccountSource;

    const instagramBusinessAccounts = (serviceAccounts || []).filter(
      account => account.channel === SERVICES.FACEBOOK
    );
    const instagramBasicAccounts = (serviceAccounts || []).filter(
      account => account.channel === 'instagram'
    );

    const hasBasicAccounts = instagramBasicAccounts?.length > 0;
    const hasBusinessAccounts = instagramBusinessAccounts?.length > 0;
    const hasAnyAccounts = serviceAccounts?.length > 0;

    return [
      // # INITIAL_WHAT_TO_COLLECT
      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: SOURCES.ACCOUNT,
                  text: 'Images and videos',
                },
                nextId: QUESTION.CONNECT_BASIC_OR_BUSINESS,
                questionId: QUESTION.INITIAL_WHAT_TO_COLLECT,
              });
            },
            id: 'images',
            name: 'Images and videos from a user',
          },
          {
            action: () => {
              const nextId = hasBusinessAccounts
                ? QUESTION.CONNECT_BASIC_OR_BUSINESS
                : QUESTION.NO_BUSINESS_ACCOUNT_CONNECTED_YET;

              answerQuestion({
                response: {
                  value: 'hashtag',
                  text: 'Hashtag mentions',
                },
                nextId,
                questionId: QUESTION.INITIAL_WHAT_TO_COLLECT,
              });
            },
            id: 'hashtag',
            name: 'Hashtag mentions',
          },
          {
            action: () => {
              const nextId = hasBusinessAccounts
                ? QUESTION.CONNECT_BASIC_OR_BUSINESS
                : QUESTION.NO_BUSINESS_ACCOUNT_CONNECTED_YET;

              answerQuestion({
                response: {
                  value: SOURCES.MENTIONS,
                  text: 'Mentions of my Business',
                },
                nextId,
                questionId: QUESTION.INITIAL_WHAT_TO_COLLECT,
              });
            },
            id: 'mentions',
            name: 'Mentions of my Business',
          },
          {
            action: () => {
              const nextId = hasBusinessAccounts
                ? QUESTION.CONNECT_BASIC_OR_BUSINESS
                : QUESTION.NO_BUSINESS_ACCOUNT_CONNECTED_YET;

              answerQuestion({
                response: {
                  value: SOURCES.STORIES,
                  text: 'Stories by my Business',
                },
                nextId,
                questionId: QUESTION.INITIAL_WHAT_TO_COLLECT,
              });
            },
            id: 'stories',
            name: 'Stories by my Business',
          },
        ],
        id: QUESTION.INITIAL_WHAT_TO_COLLECT,
        messages: [
          ...(!initialAccountCount ? ['Oh, I love Instagram! 😍'] : []),
          'What would you like to collect from Instagram?',
        ],
        response: EMPTY_RESPONSE,
        visible: true,
      },

      // # CONNECT_BASIC_OR_BUSINESS
      {
        autoAction: hasAnyAccounts
          ? () => {
              let account;

              const selectedAccount = accountId
                ? serviceAccounts.find(account => account.id === accountId)
                : null;

              // Auto connect business account
              if (
                hasBusinessAccounts &&
                (!selectedAccount ||
                  selectedAccount?.channel === SERVICES.FACEBOOK ||
                  !isCollectingAccountPosts)
              ) {
                account =
                  selectedAccount?.channel === SERVICES.FACEBOOK
                    ? selectedAccount
                    : maxBy(instagramBusinessAccounts, 'connected_at');

                setAccountId(account.id);

                answerQuestion({
                  response: {
                    value: account.id,
                    text: `${account.name} connected`,
                    account: account,
                  },
                  nextId: QUESTION.LOADING_PAGES,
                  questionId: QUESTION.CONNECT_BASIC_OR_BUSINESS,
                  disableValueTracking: true,
                });

                this.connectBusinessAccountCallback(account.id);

                return;
              }

              // Auto connect basic account
              if (hasBasicAccounts) {
                // This assumes that only one basic account is possible!
                account = instagramBasicAccounts[0];
                setAccountId(account.id);

                answerQuestion({
                  response: {
                    text: `@${account.name} connected`,
                    value: account.id,
                    account: account,
                  },
                  nextId: QUESTION.CREATE_BASIC_USER_FEED,
                  questionId: QUESTION.CONNECT_BASIC_OR_BUSINESS,
                });

                return;
              }
            }
          : undefined,

        actions: [
          {
            action: () => {
              connectServiceAccount(SERVICES.INSTAGRAM).then(account => {
                answerQuestion({
                  response: {
                    value: account.id,
                    text: `${account.name} connected`,
                    account: account,
                  },
                  nextId: QUESTION.LOADING_PAGES,
                  questionId: QUESTION.CONNECT_BASIC_OR_BUSINESS,
                  disableValueTracking: true,
                });

                setAccountId(account.id);

                this.connectBusinessAccountCallback(account.id);
              });
            },
            id: QUESTION.CONNECT_BASIC_OR_BUSINESS,
            name: 'Connect Instagram Business account via Facebook…',
          },
          {
            action: () => {
              connectServiceAccount('instagram').then(account => {
                answerQuestion({
                  response: {
                    value: account.id,
                    text: `@${account.name} connected`,
                    account: account,
                  },
                  nextId: QUESTION.CREATE_BASIC_USER_FEED,
                  questionId: QUESTION.CONNECT_BASIC_OR_BUSINESS,
                  disableValueTracking: true,
                });
              });
            },
            id: QUESTION.CONNECT_ACCOUNT,
            name: 'Connect your Instagram account with email and password…',
          },
        ],
        messages:
          initialAccountCount > 0 || !isCollectingAccountPosts
            ? []
            : [
                'If you connect an Instagram Business account, you’ll be able to show the number of likes and comments on your social media feed.',
              ],
        id: QUESTION.CONNECT_BASIC_OR_BUSINESS,
        response: EMPTY_RESPONSE,
        visible: false,

        // # Dropdown options
        answerDropdownOptions:
          serviceAccounts.length > 1
            ? serviceAccounts.map(account => ({
                text: `${account.channel === 'instagram' ? '@' : ''}${account.name}`,
                value: account.id,
                account: account,
              }))
            : undefined,

        // # Dropdown action
        answerDropdownAction: value => {
          const selectedOption = serviceAccounts.find(account => `${account.id}` === `${value}`);
          setAccountId(selectedOption.id);
          const isBasicAccountSelected = selectedOption.channel === 'instagram';

          let questionRevertingTo = QUESTION.CONNECT_BASIC_OR_BUSINESS;
          if (isBasicAccountSelected) {
            questionRevertingTo = isCollectingAccountPosts
              ? QUESTION.CONNECT_BASIC_OR_BUSINESS
              : QUESTION.INITIAL_WHAT_TO_COLLECT; // Basic account cannot collect hashtag/mentions/stories
          }

          this.revertToQuestion(questionRevertingTo);
        },

        // # Custom undo
        undoText: this.state.isCustomUndoConnectActionVisible ? 'Cancel…' : 'Add another account…',
        undoAction: () => {
          this.setState({
            isCustomUndoConnectActionVisible: !this.state.isCustomUndoConnectActionVisible,
          });
        },

        customUndoAction: this.state.isCustomUndoConnectActionVisible ? (
          <div className={styles.customSecondaryAction}>
            <button
              className={styles.customSecondaryButton}
              onClick={() => {
                connectServiceAccount(SERVICES.INSTAGRAM).then(account => {
                  setAccountId(account.id);
                  this.revertToQuestion(QUESTION.CONNECT_BASIC_OR_BUSINESS);
                  this.connectBusinessAccountCallback(account.id);
                });
              }}
            >
              Instagram Business account via Facebook…
            </button>
            <button
              className={styles.customSecondaryButton}
              onClick={() => {
                connectServiceAccount('instagram').then(account => {
                  setAccountId(account.id);

                  // Revert back to this question when new account is connected.
                  // This will trigger autoAction, which changes latest account username for the feed…
                  // However if user has selected hashtag/mentions/stories
                  // -> we cannot continue with basic account and we revert to first question
                  const questionRevertingTo = isCollectingAccountPosts
                    ? QUESTION.CONNECT_BASIC_OR_BUSINESS
                    : QUESTION.INITIAL_WHAT_TO_COLLECT;
                  this.revertToQuestion(questionRevertingTo);
                });
              }}
            >
              Instagram account with email and password…
            </button>
          </div>
        ) : null,
      },

      // # CREATE_BASIC_USER_FEED
      {
        autoAction: () => {
          this.props.setFeedType(FEED_TYPES.INSTAGRAM_BASIC);

          setTimeout(() => {
            answerQuestion({
              response: { value: instagramBasicAccounts[0], text: 'Images' },
              nextId: QUESTION.SET_BASIC_USER_FEED_FILTERS,
              questionId: QUESTION.CREATE_BASIC_USER_FEED,
            });
          }, config.questionDelay);
        },

        messages: [],
        isUserReplyDisabled: true,
        isUserAvatarHidden: true,
        id: QUESTION.CREATE_BASIC_USER_FEED,
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Collect posts by account
      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'needsToMention',
                  text: 'Posts needs to mention:',
                },
                nextId: QUESTION.HASHTAG_INPUT,
                questionId: QUESTION.SET_BASIC_USER_FEED_FILTERS,
              });
            },
            id: 0,
            name: 'No, posts need to mention…',
          },
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'collectAll',
                  text: 'Collect all posts',
                },
                nextId: QUESTION.MEDIA_TYPES_TO_COLLECT,
                questionId: QUESTION.SET_BASIC_USER_FEED_FILTERS,
              });
            },
            id: 1,
            name: 'Yes, display all',
          },
        ],
        id: QUESTION.SET_BASIC_USER_FEED_FILTERS,
        messages: [
          `Alrighty, I’ll look for posts by @${instagramBasicAccounts?.[0]?.name} 👍`,
          `Shall I display all the posts by @${instagramBasicAccounts?.[0]?.name}?`,
        ],
        response: EMPTY_RESPONSE,
      },

      // # NO_BUSINESS_ACCOUNT_CONNECTED_YET
      {
        actions: [
          {
            action: getConnectAction(
              QUESTION.NO_BUSINESS_ACCOUNT_CONNECTED_YET,
              QUESTION.LOADING_PAGES
            ),
            id: QUESTION.CONNECT_ACCOUNT,
            name: 'Connect Instagram account via Facebook…',
          },
        ],
        messages: [
          <>
            To display {isCollectingHashtag ? 'hashtag feeds from Instagram' : ''}
            {isCollectingMentions ? 'mentions of your Business from Instagram' : ''}
            {isCollectingStories ? 'Instagram stories' : ''}
            {isCollectingAccountPosts ? 'content from Instagram' : ''}, you’ll need to connect your
            personal Facebook account that has access to{' '}
            <strong>Instagram Business account.</strong>
            <br />
            <LearnMoreLink href="https://flockler.com/how-to-create-a-hashtag-feed-from-instagram" />
          </>,
          <>
            Don’t worry; we don’t display any of your personal Facebook posts or store any personal
            data about you.{' '}
            <strong style={{ background: 'yellow', padding: '0 1px' }}>
              After connecting your account, you can select any Instagram Business account or a
              hashtag feed to display.
            </strong>
            <br />
            <LearnMoreLink href="https://flockler.help/en/articles/3558307-why-do-i-have-to-connect-my-personal-facebook-account" />
          </>,
        ],
        visible: false,
        id: QUESTION.NO_BUSINESS_ACCOUNT_CONNECTED_YET,

        // # answer dropdown
        answerDropdownOptions: serviceAccounts.map(account => ({
          text: `${account.channel === 'instagram' ? '@' : ''}${account.name}`,
          value: account.id,
          account: account,
        })),

        answerDropdownAction: value => {
          const { serviceAccounts, answerQuestion } = this.props;
          const selectedOption = serviceAccounts.find(account => `${account.id}` === `${value}`);

          // This should not happen
          if (!selectedOption) return;

          setAccountId(selectedOption.id);

          const isBasicAccountSelected = selectedOption.channel === 'instagram';

          const questionRevertingTo = isBasicAccountSelected
            ? QUESTION.INITIAL_WHAT_TO_COLLECT
            : QUESTION.NO_BUSINESS_ACCOUNT_CONNECTED_YET;

          return Promise.resolve(this.revertToQuestion(questionRevertingTo)).then(() => {
            if (isBasicAccountSelected) return;

            answerQuestion({
              response: {
                text: `${selectedOption.name} connected`,
                value: selectedOption.id,
                account: selectedOption,
              },
              nextId: QUESTION.LOADING_PAGES,
              questionId: QUESTION.NO_BUSINESS_ACCOUNT_CONNECTED_YET,
              disableValueTracking: true,
            });

            this.connectBusinessAccountCallback(selectedOption.id);
          });
        },

        // # Custom undo
        undoText: this.state.isCustomUndoConnectActionVisible ? 'Cancel…' : 'Add another account…',
        undoAction: () => {
          this.setState({
            isCustomUndoConnectActionVisible: !this.state.isCustomUndoConnectActionVisible,
          });
        },

        customUndoAction: this.state.isCustomUndoConnectActionVisible ? (
          <div className={styles.customSecondaryAction}>
            <button
              className={styles.customSecondaryButton}
              onClick={() => {
                connectServiceAccount(SERVICES.INSTAGRAM).then(account => {
                  setAccountId(account.id);
                  this.revertToQuestion(QUESTION.CONNECT_BASIC_OR_BUSINESS);
                  this.connectBusinessAccountCallback(account.id);
                });
              }}
            >
              Instagram Business account via Facebook…
            </button>
            <button
              className={styles.customSecondaryButton}
              onClick={() => {
                connectServiceAccount('instagram').then(account => {
                  setAccountId(account.id);

                  // Revert back to this question when new account is connected.
                  // This will trigger autoAction, which changes latest account username for the feed…
                  // However if user has selected hashtag/mentions/stories
                  // -> we cannot continue with basic account and we revert to first question
                  const questionRevertingTo = isCollectingAccountPosts
                    ? QUESTION.CONNECT_BASIC_OR_BUSINESS
                    : QUESTION.INITIAL_WHAT_TO_COLLECT;
                  this.revertToQuestion(questionRevertingTo);
                });
              }}
            >
              Instagram account with email and password…
            </button>
          </div>
        ) : null,
      },

      // "Ghost" question when waiting for pages to load
      // # LOADING_PAGES
      {
        id: QUESTION.LOADING_PAGES,
        isUserReplyDisabled: true,
        isBotMessageDisabled: !isLoadingBusinessAccounts, // Only visible while loading business accounts
        isLoading: true, // shows just loader
        messages: [],
        response: EMPTY_RESPONSE,
      },

      // Not any admin rights
      // # NOT_ANY_INSTAGRAM_ACCOUNTS
      {
        actions: [
          {
            action: getConnectAction(
              initialAccountCount > 0
                ? QUESTION.ACCOUNT_CONNECTED_ALREADY
                : QUESTION.NO_ACCOUNT_CONNECTED_YET,
              QUESTION.LOADING_PAGES,
              this.connectCallback,
              true
            ),
            id: 'reconnect_instagram_account',
            name: 'Reconnect your Facebook account',
          },
          {
            action: () => {
              // Clear conversation state to step 1
              this.props.setFeedType(null);
              this.props.setChannelConversationStatus(false);
              this.revertToQuestion(STEPS.CHOOSE_SERVICE);
            },
            id: 'choose_another_service',
            name: 'Create a feed from another source',
          },
          {
            action: () =>
              helpScoutMessage({ subject: "I don't have Instagram Business account connected with the organisation’s Facebook Page." }),
            id: 'back_to_flockler_app',
            name: 'Ask for help',
          },
        ],
        id: QUESTION.NOT_ANY_INSTAGRAM_ACCOUNTS,
        messages: [
          'You need to have Instagram Business account connected with the organisation’s Facebook Page to get content from Instagram.',
          <span>
            Here is information about Instagram Business account{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://business.instagram.com/getting-started"
            >
              https://business.instagram.com/getting-started
            </a>
          </span>,
        ],
        response: EMPTY_RESPONSE,
        visible: false,
        // hides drop animation and loading, it's already shown in last "ghost" loading question,
        // which is loading phase for page load
        disableAnimation: true,
      },

      // Error loading pages
      // # ERROR_LOADING_PAGES
      {
        actions: [
          {
            action: getConnectAction(
              this.state.initiaBusinessAccountCount || isCollectingAccountPosts
                ? QUESTION.CONNECT_BASIC_OR_BUSINESS
                : QUESTION.NO_BUSINESS_ACCOUNT_CONNECTED_YET,
              QUESTION.LOADING_PAGES,
              this.connectBusinessAccountCallback,
              true
            ),
            id: 'reconnect_instagram_account',
            name: 'Reconnect your Facebook account',
          },
          {
            action: () => {
              // Clear conversation state to step 1
              this.props.setFeedType(null);
              this.props.setChannelConversationStatus(false);
              this.revertToQuestion(STEPS.CHOOSE_SERVICE);
            },
            id: 'choose_another_service',
            name: 'Create a feed from another source',
          },
          {
            action: () =>
              helpScoutMessage({ subject: 'I have problems with loading Instagram Business account.' }),
            id: 'back_to_flockler_app',
            name: 'Ask for help',
          },
        ],
        response: EMPTY_RESPONSE,
        visible: false,
        messages: [
          'Unfortunately I couldn’t get Instagram Business accounts with your Facebook account.',
        ],
        id: QUESTION.ERROR_LOADING_PAGES,
        disableAnimation: true,
      },

      // Select Instagram account to use
      // # CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT
      {
        // Hide question and answer if only one account is available
        // but question itself is still required for setting access_token
        isBotMessageDisabled: hasOnlyOneAccountToSelect,
        isUserReplyDisabled: hasOnlyOneAccountToSelect,
        isUserAvatarHidden: hasOnlyOneAccountToSelect,

        // with one account autoselect
        autoAction: hasOnlyOneAccountToSelect
          ? () => {
              let nextId = QUESTION.ACCOUNT_SELECTED;

              if (isCollectingMentions) {
                nextId = QUESTION.MENTIONS_SELECTED;
              }

              if (isCollectingStories) {
                nextId = QUESTION.STORIES_SELECTED;
              }

              return answerQuestion({
                response: getInstagramAnswerResponse(pages[0], isCollectingMentions),
                nextId,
                questionId: QUESTION.CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT,
              });
            }
          : null,

        // select-input
        select: !hasOnlyOneAccountToSelect
          ? {
              title: 'Select an Instagram account',
              options: pages.map(page => ({
                action: () => {
                  let nextId = isCollectingMentions
                    ? QUESTION.MENTIONS_SELECTED
                    : QUESTION.ACCOUNT_SELECTED;

                  if (isCollectingStories) {
                    nextId = QUESTION.STORIES_SELECTED;
                  }

                  answerQuestion({
                    response: getInstagramAnswerResponse(page, isCollectingMentions),
                    nextId,
                    questionId: QUESTION.CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT,
                  });
                },
                id: page.page_id,
                name: page.name,
                logoUrl: page.image,
                description: `@${page.username}`,
              })),
              children: initialWhatToCollect === SOURCES.ACCOUNT && (
                <InstagramUserInput
                  action={businessAccount => {
                    // Check if searched account is already one of user's admin accounts
                    const accountFromPages = findByKey('account_id', pages, businessAccount.id);

                    const response = accountFromPages
                      ? getInstagramAnswerResponse(accountFromPages)
                      : getInstagramBusinessAccountAnswerResponse(businessAccount);

                    answerQuestion({
                      response: response,
                      nextId: QUESTION.ACCOUNT_SELECTED,
                      questionId: QUESTION.CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT,
                    });
                  }}
                  placeholder="https://www.instagram.com/username/"
                />
              ),
            }
          : null,
        id: QUESTION.CHOOSE_INSTAGRAM_BUSINESS_ACCOUNT,
        messages: getInstagramPageMessages(pages, !isCollectingOwnAccountSource),
        response: EMPTY_RESPONSE,
        disableUndo: hasOnlyOneAccountToSelect,
      },

      // Collect posts by account
      // # ACCOUNT_SELECTED
      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'needsToMention',
                  text: 'Posts needs to mention:',
                },
                nextId: QUESTION.HASHTAG_INPUT,
                questionId: QUESTION.ACCOUNT_SELECTED,
              });
            },
            id: 0,
            name: 'No, posts need to mention…',
          },
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'collectAll',
                  text: 'Collect all posts',
                },
                nextId: QUESTION.MEDIA_TYPES_TO_COLLECT,
                questionId: QUESTION.ACCOUNT_SELECTED,
              });
            },
            id: 1,
            name: 'Yes, display all',
          },
        ],
        id: QUESTION.ACCOUNT_SELECTED,
        messages: [
          `Alrighty, I’ll look for posts by @${selectedUsername} 👍`,
          `Shall I display all the posts by @${selectedUsername}?`,
        ],
        response: EMPTY_RESPONSE,
      },

      // Collect posts with mentions
      // # MENTIONS_SELECTED
      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'needsToMention',
                  text: 'Posts needs to also mention:',
                },
                nextId: QUESTION.HASHTAG_INPUT,
                questionId: QUESTION.MENTIONS_SELECTED,
              });
            },
            id: 0,
            name: 'No, posts need to also mention…',
          },
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'collectAll',
                  text: 'Collect all posts',
                },
                nextId: QUESTION.MEDIA_TYPES_TO_COLLECT,
                questionId: QUESTION.MENTIONS_SELECTED,
              });
            },
            id: 1,
            name: 'Yes, show all',
          },
        ],
        id: QUESTION.MENTIONS_SELECTED,
        messages: [
          `Alrighty, I’ll look for posts that mention @${selectedUsername} 🕵️‍♀️`,
          `Should I show all the posts mentioning @${selectedUsername}?`,
        ],
        response: EMPTY_RESPONSE,
      },

      // Stories selected message
      {
        autoAction: () => {
          setTimeout(() => {
            // This could have been changed with UNDO during timeout
            if (initialWhatToCollect !== SOURCES.STORIES) {
              return;
            }

            answerQuestion({
              response: { value: true, text: 'Stories' },
              nextId: null,
              questionId: QUESTION.STORIES_SELECTED,
            });
          }, config.questionDelay);
        },
        actions: [],
        nextId: null,
        isUserReplyDisabled: true,
        isUserAvatarHidden: true,
        messages: [`Alrighty, I’ll look for Stories from account @${selectedUsername} 👌`],
        id: QUESTION.STORIES_SELECTED,
        response: EMPTY_RESPONSE,
      },

      // Keyword input
      // # HASHTAG_INPUT
      {
        actions: [],
        input: (
          <WordInput
            action={hashtags => {
              // save to state only for multiple hashtags possible
              if (isCollectingHashtag) {
                this.setState({ hashtagTags: hashtags });
              }

              answerQuestion({
                response: {
                  value: tagList(hashtags),
                  text: tagList(hashtags),
                  isTags: true,
                },
                nextId: isCollectingHashtag
                  ? QUESTION.ADD_USERS_TO_HASHTAG_FILTER
                  : QUESTION.MEDIA_TYPES_TO_COLLECT,
                questionId: QUESTION.HASHTAG_INPUT,
              });
            }}
            autoActionOnMaxTags
            placeholder="Type in a #hashtag"
            prepend="#"
            maxTags={1}
            tags={isCollectingHashtag ? [] : this.state.hashtagTags}
            validations={[
              {
                validate: text =>
                  text.match(regex.validTagOrKeyword) && text.match(regex.instagramHashtag),
                errorMessage: 'Invalid hashtag, only letters and numbers are allowed',
              },
            ]}
          />
        ),
        id: QUESTION.HASHTAG_INPUT,
        messages: [],
        response: EMPTY_RESPONSE,
        undoText: 'Edit hashtag…',
      },

      // Add users to hashtag filter?
      // # ADD_USERS_TO_HASHTAG_FILTER
      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'fromUsers',
                  text: 'Posts from users:',
                },
                nextId: QUESTION.USER_INPUT,
                questionId: QUESTION.ADD_USERS_TO_HASHTAG_FILTER,
              });
            },
            id: 0,
            name: 'No, only posts by…',
          },
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'fromAll',
                  text: 'Collect from all users',
                },
                nextId: QUESTION.MEDIA_TYPES_TO_COLLECT,
                questionId: QUESTION.ADD_USERS_TO_HASHTAG_FILTER,
              });
            },
            id: 1,
            name: 'Yes, display all',
          },
        ],
        id: QUESTION.ADD_USERS_TO_HASHTAG_FILTER,
        messages: [
          <p>
            Would you like to display posts from anyone mentioning <i>{selectedHashtags}</i>?
          </p>,
        ],
        response: EMPTY_RESPONSE,
      },

      // Users input
      // # USER_INPUT
      {
        actions: [],
        input: (
          <WordInput
            action={users => {
              this.setState({ usernameTags: users });

              answerQuestion({
                response: {
                  value: tagList(users).map(username => replace(username, '@', '')),
                  text: tagList(users),
                  isTags: true,
                },
                nextId: QUESTION.MEDIA_TYPES_TO_COLLECT,
                questionId: QUESTION.USER_INPUT,
              });
            }}
            placeholder="Type in a @username"
            prepend="@"
            maxLength={31}
            validations={[
              {
                validate: text => text.match(regex.instagramUsername),
                errorMessage: 'Invalid username',
              },
            ]}
            maxTags={999}
            // Username filters are kept in state, so "edit users" is able to actually edit usernames
            tags={this.state.usernameTags}
          />
        ),
        id: QUESTION.USER_INPUT,
        messages: [],
        response: EMPTY_RESPONSE,
        undoText: 'Edit usernames…',
      },

      // Media types to collect
      // # MEDIA_TYPES_TO_COLLECT
      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'image',
                  text: 'Only images',
                },
                nextId: null,
                questionId: QUESTION.MEDIA_TYPES_TO_COLLECT,
              });
            },
            id: 0,
            name: 'Only images',
          },

          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'video',
                  text: 'Only Reels and videos',
                },
                nextId: null,
                questionId: QUESTION.MEDIA_TYPES_TO_COLLECT,
              });
            },
            id: 1,
            name: 'Only Reels and videos',
          },

          {
            action: () => {
              answerQuestion({
                response: {
                  value: '',
                  text: 'All content types',
                },
                nextId: null,
                questionId: QUESTION.MEDIA_TYPES_TO_COLLECT,
              });
            },
            id: 2,
            name: 'All content types',
          },
        ],
        id: QUESTION.MEDIA_TYPES_TO_COLLECT,
        messages: [`Would you like to show all content types?`],
        response: EMPTY_RESPONSE,
      },
    ];
  };

  render() {
    const { answers } = this.props;
    const { revertToQuestion } = this;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

const mapStateToProps = state => ({
  pages: getBusinessAccountPages(state),
  selectedUsername: getSelectedInstagramUsername(state),
  selectedHashtags: getAnswerValueToHashtag(state),
  isLoadingBusinessAccounts: getBusinessAccountsLoadingStatus(state),
  initialWhatToCollect: getQuestionAnswerValue(QUESTION.INITIAL_WHAT_TO_COLLECT)(state),
});

const mapDispatchToProps = {
  connectServiceAccount,
  setAccountId,
  fetchPagesWithInstagramAccess,
};

const ConnectedConversationAboutInstagram = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationAboutInstagram);

export default serviceConversation(ConnectedConversationAboutInstagram, {
  service: SERVICES.INSTAGRAM,
});
