// # Feed builder concept
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import maxBy from 'lodash/maxBy';
import uniqueId from 'lodash/uniqueId';
import { findById } from 'utils/common';

import { SERVICES } from 'constants/Services';
import { getMediaTrackers, updateMediaTracker } from 'concepts/media-tracker';
import { showError } from 'concepts/error';
import { getSocialMediaAccounts, fetchSocialMediaAccounts } from 'concepts/social-media-account';
import { connectAccount } from 'services/connect-account';
import { fetchLinkedInOrganizations } from 'services/api';

// # Action types
export const SET_RECONNECT_TRIAL = 'RECONNECT_ACCOUNT/SET_RECONNECT_TRIAL';
export const SET_RECONNECT_FEED_ID = 'RECONNECT_ACCOUNT/SET_RECONNECT_FEED_ID';
export const RECONNECT_ACCOUNT_START = 'RECONNECT_ACCOUNT/RECONNECT_ACCOUNT_START';
export const RECONNECT_ACCOUNT_SUCCESS = 'RECONNECT_ACCOUNT/RECONNECT_ACCOUNT_SUCCESS';
export const RECONNECT_ACCOUNT_FAIL = 'RECONNECT_ACCOUNT/RECONNECT_ACCOUNT_FAIL';

// # Selectors
const getReconnectTrialId = state => state.reconnectAccount.reconnectTrialId;
export const getReconnectFeedId = state => state.reconnectAccount.reconnectFeedId;
export const getReconnectingLoading = state => state.reconnectAccount.isReconnecting;
export const getReconnectingStatus = state => state.reconnectAccount.isFailed;
export const getReconnectingError = state => state.reconnectAccount.reconnectError;

const getReconnectFeed = createSelector(getMediaTrackers, getReconnectFeedId, findById);

export const getReconnectFeedInfo = createSelector(
  getReconnectFeed,
  getSocialMediaAccounts,
  (feed, accounts) => {
    if (!feed) {
      return {
        service: null,
        accountId: null,
        accountName: null,
      };
    }

    const account = findById(accounts, feed.social_media_account_id);

    return {
      accountId: feed.social_media_account_id,
      accountName: feed.social_media_account_name,
      isMyAccount: !isNil(account),
      service: feed.service,
      connectedByUser: get(feed, ['connecting_user', 'fullname']),
      matchAuthorName: getMatchAuthorName(feed),
      matchAuthorVisibleName: getMatchAuthorVisibleName(feed),
    };
  }
);

// # Action creators
export const setReconnectFeedId = feedId => (dispatch, getState) => {
  const mediaTrackers = getMediaTrackers(getState());
  const isValidFeedId = findById(mediaTrackers, feedId);

  if (!isValidFeedId) {
    return dispatch(showError(`Invalid feed (${feedId})`));
  }

  return dispatch({
    type: SET_RECONNECT_FEED_ID,
    payload: feedId,
  });
};

const getMatchAuthorName = feed => {
  const matchAuthorFilter =
    feed.media_tracker_filters?.find(f => f.filter_type === 'match_author') || {};
  const matchAuthorName = (matchAuthorFilter.parameters || []).find(f => f.name === 'name') || {};
  return matchAuthorName.value;
};

const getMatchAuthorVisibleName = feed => {
  const matchAuthorFilter =
    feed.media_tracker_filters?.find(f => f.filter_type === 'match_author') || {};
  const matchAuthorVisibleName =
    (matchAuthorFilter.parameters || []).find(f => f.name === 'visible_name') || {};
  return matchAuthorVisibleName.value;
};

const maybeUpdateFeed = service => (dispatch, getState) => {
  const state = getState();
  const socialMediaAccounts = getSocialMediaAccounts(state);
  const feedToReconnect = getReconnectFeed(state);
  const feedId = getReconnectFeedId(state);

  const feedAccountId = get(feedToReconnect, 'social_media_account_id');
  const updatedAccount = maxBy(socialMediaAccounts, 'connected_at');
  const updatedAccountId = get(updatedAccount, 'id');
  const isAccountChanged = updatedAccountId !== feedAccountId;

  // Feed update which is sent only when social media account changes for the feed
  const feedUpdate = { social_media_account_id: updatedAccountId };
  if (feedToReconnect.media_tracker_account_id) feedUpdate.media_tracker_account_id = null;

  // Check that new account is valid
  // only case where it cannot be is with Instagram
  // because user could connect account which is FB-only
  if (service === SERVICES.INSTAGRAM && !get(updatedAccount, 'ready_to_use_for_instagram')) {
    return Promise.reject('notReadyForInstagram');
  }

  // Check that match_author is same than name of connected account for TikTok
  if (service === SERVICES.TIKTOK && getMatchAuthorName(feedToReconnect) !== updatedAccount.name) {
    return Promise.reject('authorNotMatch');
  }

  // Check that reconnected feed has access to organization of the feed
  if (service === SERVICES.LINKEDIN) {
    return fetchLinkedInOrganizations()
      .then(res => res.data)
      .then(({ organizations }) => {
        if (!organizations || !organizations.length) {
          return Promise.reject('linkedinNoOrganizations');
        }

        const feedOrgId = getMatchAuthorName(feedToReconnect);
        const hasAccessToFeedOrg = organizations.some(org => org.id === feedOrgId);

        if (!hasAccessToFeedOrg) return Promise.reject('authorNotMatch');
        if (isAccountChanged) return dispatch(updateMediaTracker(feedId, feedUpdate));

        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject('linkedinNoOrganizations');
      });
  }

  // New account connected! We need to update media tracker with new id.
  if (isAccountChanged) {
    return dispatch(updateMediaTracker(feedId, feedUpdate));
  }

  // Same account re-connected, no need to update media tracker
  return Promise.resolve();
};

const returnUpdatedAccountName = () => (dispatch, getState) => {
  const state = getState();
  const socialMediaAccounts = getSocialMediaAccounts(state);
  const updatedAccount = maxBy(socialMediaAccounts, 'connected_at');

  return get(updatedAccount, 'name');
};

export const reconnectAccount = () => (dispatch, getState) => {
  const reconnectFeed = getReconnectFeed(getState());

  if (!reconnectFeed) {
    return;
  }
  const service = reconnectFeed.service;

  const reconnectTrialId = uniqueId();
  dispatch({ type: SET_RECONNECT_TRIAL, payload: reconnectTrialId });

  return connectAccount(service).then(() => {
    // Only latests connect will apply
    const latestReconnectTrial = getReconnectTrialId(getState());
    if (latestReconnectTrial && latestReconnectTrial !== reconnectTrialId) {
      return Promise.reject();
    }

    dispatch({ type: RECONNECT_ACCOUNT_START });

    return dispatch(fetchSocialMediaAccounts())
      .then(() => dispatch(maybeUpdateFeed(service)))
      .then(() => dispatch({ type: RECONNECT_ACCOUNT_SUCCESS }))
      .then(() => dispatch(returnUpdatedAccountName()))
      .catch(error => {
        dispatch({ type: RECONNECT_ACCOUNT_FAIL, payload: error });
        return Promise.reject();
      });
  });
};

// # Reducer
export const initialState = Object.freeze({
  isFailed: false,
  isReconnecting: false,
  reconnectFeedId: null,
  reconnectedAccountName: null,
  reconnectError: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECONNECT_FEED_ID: {
      return {
        ...state,
        reconnectFeedId: action.payload,
      };
    }

    case SET_RECONNECT_TRIAL: {
      return {
        ...state,
        reconnectTrialId: action.payload,
      };
    }

    case RECONNECT_ACCOUNT_START: {
      return {
        ...state,
        reconnectedAccountName: null,
        isReconnecting: true,
      };
    }

    case RECONNECT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isFailed: false,
        isReconnecting: false,
        reconnectTrialId: null,
      };
    }

    case RECONNECT_ACCOUNT_FAIL: {
      return {
        ...state,
        isFailed: true,
        isReconnecting: false,
        reconnectTrialId: null,
        reconnectError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
