const redirect = (url, opts = { isBackDisabled: false }) => {
  if (opts && opts.isBackDisabled) {
    window.location.replace(url);
    return;
  }

  window.location.href = url;
};

export default redirect;
