import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setReconnectFeedId } from 'concepts/reconnect-account';
import ReconnectViewContainer from 'containers/reconnect/ReconnectViewContainer';
import ReconnectConversation from 'containers/reconnect/ReconnectConversation';

class ReconnectView extends Component {
  componentDidMount() {
    this.saveFeedId();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.feedId !== this.props.feedId) {
      this.saveFeedId();
    }
  }

  saveFeedId() {
    this.props.setReconnectFeedId(this.props.feedId);
  }

  render() {
    return (
      <ReconnectViewContainer>
        <ReconnectConversation />
      </ReconnectViewContainer>
    );
  }
}

const mapDispatchToProps = { setReconnectFeedId };

export default connect(
  null,
  mapDispatchToProps
)(ReconnectView);
