import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSectionId } from 'concepts/site';

const captureSectionId = WrappedComponent => {
  class RouteHOC extends Component {
    componentDidMount() {
      this.saveSectionId();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.sectionId !== this.props.sectionId) {
        this.saveSectionId();
      }
    }

    saveSectionId() {
      this.props.setSectionId(this.props.sectionId);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapDispatchToProps = {
    setSectionId,
  };

  return connect(
    null,
    mapDispatchToProps
  )(RouteHOC);
};

export default captureSectionId;
