import React from 'react';
import { connect } from 'react-redux';

import AppContainer from 'components/AppContainer';
import { getFlocklerSiteUrl } from 'concepts/redirect-flockler';

class ReconnectViewContainer extends React.Component {
  render() {
    const { children, flocklerSiteUrl } = this.props;

    return (
      <AppContainer title={<h1>Reconnect feed account</h1>} continueToAppUrl={flocklerSiteUrl}>
        {children}
      </AppContainer>
    );
  }
}

const mapStateToProps = state => ({
  flocklerSiteUrl: getFlocklerSiteUrl(state),
});

export default connect(mapStateToProps)(ReconnectViewContainer);
