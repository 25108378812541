import { pipe, updateName, updateFilter } from './shared';
import { SERVICES } from 'constants/Services';

export const RSS_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.RSS,
  filters: [{}],
};

export const QUESTION_IDS = {
  ADD_SOURCE_URL: 'RSS/ADD_SOURCE_URL',
};

// Returns updated feed config state
export function rssFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.ADD_SOURCE_URL: {
      const { url, name } = response.value;

      return pipe(
        updateName(name),
        updateFilter({ type: 'rss', rss_url: url })
      )(state);
    }

    default: {
      return state;
    }
  }
}
