import md5 from 'md5';

const DEFAULT_AVATAR_SIZE = 80;

/**
 * Generate Gravatar URL from email address
 *
 * See: https://en.gravatar.com/site/implement/images/
 *
 * @param {string} email - User’s email address
 */
export function getGravatarUrl(email, size = DEFAULT_AVATAR_SIZE) {
  const hashedEmail = email ? md5(email) : '';
  return `https://www.gravatar.com/avatar/${hashedEmail}?d=404&s=${size}`;
}
