const relativeTimeStrings = {
  seconds: {
    singular: '# sec ago',
    plural: '# secs ago',
  },
  minutes: {
    singular: '# min ago',
    plural: '# mins ago',
  },
  hours: {
    singular: '# hr ago',
    plural: '# hrs ago',
  },
  days: {
    singular: '# day ago',
    plural: '# days ago',
  },
};

const formattedRelativeTimeText = function formattedRelativeTimeText(value, type) {
  const key = value === 1 ? 'singular' : 'plural';
  return relativeTimeStrings[type][key].replace('#', value);
};

export const parseRelativeTimeText = function parseRelativeTimeText(dateString) {
  if (!dateString) {
    return '';
  }

  const date = new Date(dateString);

  const MINUTE_IN_SECONDS = 60;
  const HOUR_IN_SECONDS = 3600;
  const DAY_IN_SECONDS = 86400;
  const secondsNow = Math.floor(new Date().getTime() / 1000);
  const dateInSeconds = Math.floor(date.getTime() / 1000);
  const secondsBetween = secondsNow - dateInSeconds;
  let num;

  if (secondsBetween < MINUTE_IN_SECONDS) {
    return formattedRelativeTimeText(1, 'minutes');
  }

  if (secondsBetween < HOUR_IN_SECONDS) {
    num = Math.floor(secondsBetween / MINUTE_IN_SECONDS);
    return formattedRelativeTimeText(num, 'minutes');
  }

  if (secondsBetween < DAY_IN_SECONDS) {
    num = Math.floor(secondsBetween / HOUR_IN_SECONDS);
    return formattedRelativeTimeText(num, 'hours');
  }

  num = Math.floor(secondsBetween / DAY_IN_SECONDS);
  return formattedRelativeTimeText(num, 'days');
};
