import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './SecondaryActionButton.module.scss';

const SecondaryActionButton = ({ children, buttonClassName, ...rest }) => (
  <div className={styles.buttonWrap}>
    <button {...rest} className={classnames(styles.button, buttonClassName)}>
      {children}
    </button>
  </div>
);

SecondaryActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonClassName: PropTypes.string,
};

export default React.memo(SecondaryActionButton);
