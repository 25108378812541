import React from 'react';
import capitalize from 'lodash/capitalize';

import PageSearch from 'components/PageSearch';
import EmptySearchResult from 'components/EmptySearchResult';

// Autocomplete expect following values from items: id, name, image, description
const itemTransform = ({ id, image, type, name }) => ({
  id,
  description: capitalize(type),
  image,
  name,
  type,
});

const EmptyResult = searchText => (
  <EmptySearchResult
    title={`No results for: ${searchText}`}
    tipText="Try pasting a link of the playlist"
  />
);

const YoutubeChannelSearch = ({ items, ...rest }) => (
  <PageSearch
    items={items.map(itemTransform)}
    placeholder="Enter the name of a YouTube channel/playlist…"
    renderEmptyResult={EmptyResult}
    autoFocus
    {...rest}
  />
);

export default React.memo(YoutubeChannelSearch);
