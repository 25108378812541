// Concept for Facebook redux logic
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';

import { createDataMapSelector } from 'services/domain-data-selector';
import { getAccountId } from 'concepts/feed-builder';
import { fetchFacebookPages, fetchFacebookPageSearch } from 'services/api';
import { getFacebookPageImage } from 'services/image';
import { QUESTION_IDS } from 'services/media-trackers/facebook';
import { getQuestionAnswer } from 'concepts/feed-builder';

// Domain util
// Transform Facebook API data transfer object to application format
function fbFromDto(item) {
  const image =
    (item.picture && item.picture.data && item.picture.data.url) || getFacebookPageImage(item.id);
  const pickedFields = pick(item, ['id', 'location', 'category', 'link']);
  pickedFields.name = item.name_with_location_descriptor || item.name;
  return { ...pickedFields, image };
}

const createFromDtoSelector = createDataMapSelector(fbFromDto);

// Selectors
export const getPagesState = state => state.facebook.pages;
export const getPagesLoadingStatus = state => state.facebook.isLoading;
export const getSearchedPagesState = state => state.facebook.searchedPages;
export const getSearchLoadingTerm = state => state.facebook.loadingSearchTerm;
export const getSelectedPageType = state => state.facebook.pageType;

export const getPages = createFromDtoSelector(getPagesState);
export const getSearchedPages = createFromDtoSelector(getSearchedPagesState);

export const getSearchLoadingStatus = createSelector(getSearchLoadingTerm, term => !isNil(term));

export const getAnswerTextForFacebookPage = createSelector(
  getQuestionAnswer(QUESTION_IDS.CHOOSE_FACEBOOK_PAGE),
  (response = {}) => response.text
);

export const getAnswerForPageType = createSelector(
  getQuestionAnswer(QUESTION_IDS.CHOOSE_FACEBOOK_PAGE_TYPE),
  (response = {}) => response.value
);

export const getAnswersForFacebookPageFilters = createSelector(
  getQuestionAnswer(QUESTION_IDS.WHAT_TO_COLLECT),
  (response = {}) => response.value
);

export const getAnswersForFacebookHashtags = createSelector(
  getQuestionAnswer(QUESTION_IDS.HASHTAG_INPUT),
  (response = {}) => response.value
);

// Action Types
export const ACTIONS = Object.freeze({
  FETCH_PAGES: 'FACEBOOK/FETCH_PAGES',
  FETCH_PAGES_SUCCESS: 'FACEBOOK/FETCH_PAGES_SUCCESS',
  FETCH_PAGES_FAIL: 'FACEBOOK/FETCH_PAGES_FAIL',

  CLEAR_SEARCH_PAGES: 'FACEBOOK/CLEAR_SEARCH_PAGES',
  SEARCH_PAGES: 'FACEBOOK/SEARCH_PAGES',
  SEARCH_PAGES_SUCCESS: 'FACEBOOK/SEARCH_PAGES_SUCCESS',
  SEARCH_PAGES_FAIL: 'FACEBOOK/SEARCH_PAGES_FAIL',
  SET_PAGE_TYPE: 'FACEBOOK/SET_PAGE_TYPE',
});

// Action Creators
export const fetchPages = accountId => dispatch => {
  // Start fetching
  dispatch({ type: ACTIONS.FETCH_PAGES });

  if (isNil(accountId)) {
    return dispatch({ type: ACTIONS.FETCH_PAGES_FAIL });
  }

  // Fetch Pages
  return fetchFacebookPages(accountId)
    .then(response => {
      const pages = response.data;
      // save to store
      dispatch({ type: ACTIONS.FETCH_PAGES_SUCCESS, payload: pages });

      return pages;
    })
    .catch(error => {
      const errorResponse = get(error, 'response.data');
      dispatch({ type: ACTIONS.FETCH_PAGES_FAIL });
      return Promise.reject(errorResponse);
    });
};

export const searchPages = searchTerm => (dispatch, getState) => {
  // Start searching
  dispatch({ type: ACTIONS.SEARCH_PAGES, payload: { searchTerm } });

  const accountId = getAccountId(getState());
  if (isNil(accountId)) {
    return dispatch({ type: ACTIONS.SEARCH_PAGES_FAIL, payload: { searchTerm } });
  }

  // API call to search Pages
  return fetchFacebookPageSearch(accountId, searchTerm)
    .then(response => {
      const { pages } = response.data;
      // save to store
      dispatch({ type: ACTIONS.SEARCH_PAGES_SUCCESS, payload: { searchTerm, pages } });

      return pages;
    })
    .catch(() => dispatch({ type: ACTIONS.SEARCH_PAGES_FAIL, payload: { searchTerm } }));
};

export const clearPageSearch = () => ({ type: ACTIONS.CLEAR_SEARCH_PAGES });

export const setSelectedPageType = pageType => ({ type: ACTIONS.SET_PAGE_TYPE, payload: pageType });

export const initialState = Object.freeze({
  pages: [],
  isLoading: false,
  loadingSearchTerm: null,
  searchedPages: [],
  pageType: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.FETCH_PAGES: {
      return {
        ...state,
        pages: [],
        isLoading: true,
      };
    }

    case ACTIONS.FETCH_PAGES_SUCCESS: {
      return {
        ...state,
        pages: action.payload || [],
        isLoading: false,
      };
    }

    case ACTIONS.FETCH_PAGES_FAIL: {
      return {
        ...state,
        pages: [],
        isLoading: false,
      };
    }

    case ACTIONS.SEARCH_PAGES: {
      return {
        ...state,
        searchedPages: [],
        loadingSearchTerm: action.payload.searchTerm,
      };
    }

    case ACTIONS.SEARCH_PAGES_SUCCESS: {
      const isLatestSearch = state.loadingSearchTerm === action.payload.searchTerm;
      if (!isLatestSearch) {
        return state;
      }

      return {
        ...state,
        searchedPages: action.payload.pages || [],
        loadingSearchTerm: null,
      };
    }

    case ACTIONS.SEARCH_PAGES_FAIL: {
      const isLatestSearch = state.loadingSearchTerm === action.payload.searchTerm;
      if (!isLatestSearch) {
        return state;
      }

      return {
        ...state,
        searchedPages: [],
        loadingSearchTerm: null,
      };
    }

    case ACTIONS.CLEAR_SEARCH_PAGES: {
      return {
        ...state,
        searchedPages: [],
        loadingSearchTerm: null,
      };
    }

    case ACTIONS.SET_PAGE_TYPE: {
      return {
        ...state,
        pageType: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
