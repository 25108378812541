import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ActionButton.module.scss';

export const actionButtonize = WrappedComponent => {
  return ({ children, className, isSelected, isInactive, disabled, ...rest }) => (
    <WrappedComponent
      className={classnames(
        styles.actionButton,
        {
          [styles.selected]: isSelected,
          [styles.inactive]: isInactive,
          [styles.disabled]: disabled,
        },
        className
      )}
      {...rest}
    >
      {children}
    </WrappedComponent>
  );
};

const ActionButton = actionButtonize('button');

ActionButton.defaultProps = {
  children: undefined,
  isSelected: false,
};

ActionButton.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
};

export default React.memo(ActionButton);
