import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import {
  clearChannelSearchResults,
  getSearchedChannels,
  getSearchLoadingStatus,
  searchChannels,
} from 'concepts/youtube';
import { getQuestionAnswer } from 'concepts/feed-builder';
import { QUESTION_IDS as QUESTION } from 'services/media-trackers/youtube';
import { SERVICES } from 'constants/Services';
import { getConversationState } from 'services/conversation-updater';

import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';
import YoutubeChannelSearch from 'components/YoutubeChannelSearch';
import WordInput from 'components/WordInput';

const EMPTY_RESPONSE = { value: null, text: null };

function tagList(tags) {
  return tags.map(tag => tag.text);
}

class ConversationAboutYoutube extends React.Component {
  getConversationTemplate = () => {
    const {
      answerQuestion,
      channelAnswer,
      clearChannelSearchResults,
      createAccountConnectQuestion,
      getConnectAction,
      initialAccountCount,
      isSearching,
      searchChannels,
      searchedChannels,
    } = this.props;

    const channelName = get(channelAnswer, ['value', 'name']);

    return [
      // Question for user who has no accounts yet for service
      createAccountConnectQuestion({
        actions: [
          {
            action: getConnectAction(
              QUESTION.NO_ACCOUNT_CONNECTED_YET,
              QUESTION.KEYWORDS_OR_CHANNELS
            ),
            id: QUESTION.CONNECT_ACCOUNT,
            name: 'Connect YouTube account…',
          },
        ],
        messages: [
          'YouTube it is then! Let’s start by connecting your YouTube account.',
          <>
            You’ll need to connect a YouTube account so I can fetch content for you.{' '}
            <strong style={{ background: 'yellow', padding: '0 1px' }}>
              After you have connected an account, you can display YouTube videos from any channel
              and playlist or by any keyword/hashtag.
            </strong>
          </>,
        ],
        nextId: QUESTION.KEYWORDS_OR_CHANNELS,
        visible: initialAccountCount === 0,
        questionId: QUESTION.NO_ACCOUNT_CONNECTED_YET,
      }),

      // Question for user who has account(s) connected already
      createAccountConnectQuestion({
        actions: [],
        messages: [],
        nextId: QUESTION.KEYWORDS_OR_CHANNELS,
        visible: initialAccountCount !== 0,
        questionId: QUESTION.ACCOUNT_CONNECTED_ALREADY,
      }),

      // Keywords or users
      {
        actions: [
          {
            action: () =>
              answerQuestion({
                response: {
                  value: 'keyword',
                  text: 'Videos mentioning a keyword/hashtag:',
                },
                nextId: QUESTION.CHOOSE_KEYWORDS,
                questionId: QUESTION.KEYWORDS_OR_CHANNELS,
              }),
            id: 'keyword',
            name: 'Videos mentioning a keyword/hashtag',
          },
          {
            action: () =>
              answerQuestion({
                response: {
                  value: 'channel',
                  text: 'Videos by a specific channel/playlist:',
                },
                nextId: QUESTION.CHOOSE_CHANNEL_OR_PLAYLIST,
                questionId: QUESTION.KEYWORDS_OR_CHANNELS,
              }),
            id: 'channel',
            name: 'Videos by a specific channel/playlist',
          },
        ],
        id: QUESTION.KEYWORDS_OR_CHANNELS,
        messages: ['What would you like to collect from YouTube?'],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Add channel/playlist
      {
        search: {
          title: 'Find and add a YouTube channel/playlist',
          isSearching,
          items: searchedChannels,
          onClear: clearChannelSearchResults,
          onSearch: searchChannels,
          searchComponent: YoutubeChannelSearch,

          onSelect: channel => {
            answerQuestion({
              response: {
                value: {
                  id: channel.id,
                  image: channel.image,
                  name: channel.name,
                },
                text: `${channel.description}: ${channel.name}`,
                type: channel.type,
                image: channel.image,
              },
              questionId: QUESTION.CHOOSE_CHANNEL_OR_PLAYLIST,
              nextId: QUESTION.LIMIT_VIDEOS_BY_CHANNEL_TO_KEYWORDS,
            });
          },
        },
        id: QUESTION.CHOOSE_CHANNEL_OR_PLAYLIST,
        messages: [],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Ask if keyword input for channel/playlist
      {
        actions: [
          {
            action: () =>
              answerQuestion({
                response: {
                  value: 'yes',
                  text: 'Videos should also have a keyword:',
                },
                nextId: QUESTION.CHOOSE_KEYWORDS_FOR_CHANNEL_OR_PLAYLIST,
                questionId: QUESTION.LIMIT_VIDEOS_BY_CHANNEL_TO_KEYWORDS,
              }),
            id: 'keywordFilteredVideos',
            name: 'No, videos should also have a keyword…',
          },
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'no',
                  text: 'Collect all of the videos',
                },
                nextId: null,
                questionId: QUESTION.LIMIT_VIDEOS_BY_CHANNEL_TO_KEYWORDS,
              });
            },
            id: 'allVideos',
            name: 'Yes, display all',
          },
        ],
        id: QUESTION.LIMIT_VIDEOS_BY_CHANNEL_TO_KEYWORDS,
        messages: [`Would you like to display all videos from ${channelName}?`],
        response: EMPTY_RESPONSE,
      },

      // Keyword input for channel/playlist
      {
        actions: [],
        input: (
          <WordInput
            action={keywords => {
              answerQuestion({
                response: {
                  value: tagList(keywords),
                  text: tagList(keywords),
                  isTags: true,
                },
                nextId: null,
                questionId: QUESTION.CHOOSE_KEYWORDS_FOR_CHANNEL_OR_PLAYLIST,
              });
            }}
            maxTags={1}
            placeholder="Add keyword"
            isQuotedMultiWordTagAllowed
            autoActionOnMaxTags
          />
        ),
        id: QUESTION.CHOOSE_KEYWORDS_FOR_CHANNEL_OR_PLAYLIST,
        messages: [],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Keyword input
      {
        actions: [],
        input: (
          <WordInput
            action={keywords => {
              answerQuestion({
                response: {
                  value: tagList(keywords),
                  text: tagList(keywords),
                  isTags: true,
                },
                nextId: null,
                questionId: QUESTION.CHOOSE_KEYWORDS,
              });
            }}
            maxTags={1}
            placeholder="Add keyword"
            autoActionOnMaxTags
          />
        ),
        id: QUESTION.CHOOSE_KEYWORDS,
        messages: [],
        response: EMPTY_RESPONSE,
        visible: false,
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

const mapStateToProps = state => ({
  isSearching: getSearchLoadingStatus(state),
  keywordOrChannelAnswer: getQuestionAnswer(QUESTION.KEYWORDS_OR_CHANNELS)(state),
  channelAnswer: getQuestionAnswer(QUESTION.CHOOSE_CHANNEL_OR_PLAYLIST)(state),
  searchedChannels: getSearchedChannels(state),
});

const mapDispatchToProps = {
  clearChannelSearchResults,
  searchChannels,
};

const ConnectedConversationAboutYoutube = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationAboutYoutube);

export default serviceConversation(ConnectedConversationAboutYoutube, {
  service: SERVICES.YOUTUBE,
});
