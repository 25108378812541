import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.scss';
import store from 'redux/store';
import SentryBoundary from 'components/SentryBoundary';
import App from 'containers/App';
import { initializeSentry } from 'services/sentry';
import * as serviceWorker from './serviceWorker';

initializeSentry();

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    // the "I am a keyboard user" key
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

const container = document.getElementById('root');
const root = createRoot(container);

const render = Component => {
  root.render(
    <Provider store={store}>
      <SentryBoundary>
        <Component />
      </SentryBoundary>
    </Provider>
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    render(App);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
