import React from 'react';
import classnames from 'classnames';
import LoadingIndicator from 'components/LoadingIndicator';
import get from 'lodash/get';
import includes from 'lodash/includes';

import styles from './SectionCreateInput.module.scss';

const getErrorMessage = error => {
  const isReservedWord = includes(
    get(error, ['response', 'data', 'errors', 'section_url', 0]),
    'is a reserved word'
  );

  if (isReservedWord) {
    return 'Forbidden section name, please use another name...';
  }

  return null;
};

class SectionCreateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { inputText: '', errorWithCreation: false, errorMessage: null };
  }

  updateInputText = event => {
    this.setState({ inputText: event.target.value, errorWithCreation: false, errorMessage: null });
  };

  onSubmit = event => {
    const { inputText } = this.state;
    const { onSubmit } = this.props;

    if (event) {
      event.preventDefault();
    }

    if (inputText.length === 0) {
      return;
    }

    this.setState({ errorWithCreation: false, errorMessage: null });

    Promise.resolve(onSubmit(inputText)).catch(err => {
      const errorMessage = getErrorMessage(err);
      this.setState({ errorWithCreation: true, errorMessage });
    });
  };

  render() {
    const { title, isLoading, noMargin } = this.props;
    const { inputText, errorWithCreation, errorMessage } = this.state;

    const isSubmitButtonVisible = !isLoading && inputText.length > 0;

    return (
      <form
        className={classnames(styles.createSection, { [styles.noMargin]: noMargin })}
        onSubmit={this.onSubmit}
      >
        {title && <span className={styles.title}>{title}</span>}
        <label className={styles.createSectionLabel}>
          <input
            className={styles.createInput}
            onChange={this.updateInputText}
            placeholder="Section name..."
            type="text"
            value={inputText}
          />

          {isSubmitButtonVisible && (
            <button className={styles.plusButton} onClick={this.onSubmit} type="button">
              +
            </button>
          )}

          {isLoading && (
            <div className={styles.loading}>
              <LoadingIndicator />
            </div>
          )}
        </label>
        {errorWithCreation && (
          <div className={styles.error}>{errorMessage || 'Error creating new section...'}</div>
        )}
      </form>
    );
  }
}

SectionCreateInput.defaultProps = {
  isLoading: false,
  title: undefined,
  onSubmit: () => {},
};

export default SectionCreateInput;
