// # Service to build simple format feed config from answers

import { commonFeedConfigReducer } from 'services/media-trackers/common';
import { facebookFeedConfigReducer } from 'services/media-trackers/facebook';
import { instagramFeedConfigReducer } from 'services/media-trackers/instagram';
import { linkedinFeedConfigReducer } from 'services/media-trackers/linkedin';
import { pinterestFeedConfigReducer } from 'services/media-trackers/pinterest';
import { reviewsFeedConfigReducer } from 'services/media-trackers/reviews';
import { rssFeedConfigReducer } from 'services/media-trackers/rss';
import { tiktokFeedConfigReducer } from 'services/media-trackers/tiktok';
import { twitterFeedConfigReducer } from 'services/media-trackers/twitter';
import { youtubeFeedConfigReducer } from 'services/media-trackers/youtube';
import { googleReviewFeedConfigReducer } from 'services/media-trackers/google-review';

// HOX! These are not "redux" reducers
const feedConfigReducers = [
  commonFeedConfigReducer,
  facebookFeedConfigReducer,
  instagramFeedConfigReducer,
  linkedinFeedConfigReducer,
  pinterestFeedConfigReducer,
  reviewsFeedConfigReducer,
  rssFeedConfigReducer,
  tiktokFeedConfigReducer,
  twitterFeedConfigReducer,
  youtubeFeedConfigReducer,
  googleReviewFeedConfigReducer,
];

function combineFeedConfigReducers(updateFns, answer, feedConfig) {
  return updateFns.reduce((feedConfigSum, updateFn) => updateFn(feedConfigSum, answer), feedConfig);
}

// Build feedConfig with question updater functions
const createFeedConfigBuilder = reducers => answers =>
  answers.reduce(
    (feedConfigSum, answer) => combineFeedConfigReducers(reducers, answer, feedConfigSum),
    {}
  );

export const buildFeedConfig = createFeedConfigBuilder(feedConfigReducers);
