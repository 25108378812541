import { Component } from 'react';
import PropTypes from 'prop-types';

class UserMessageAutoAction extends Component {
  componentDidMount() {
    if (typeof this.props.action === 'function') {
      this.props.action();
    }
  }

  render() {
    // This does not render anything
    return null;
  }
}

UserMessageAutoAction.propTypes = {
  action: PropTypes.func.isRequired,
};

export default UserMessageAutoAction;
