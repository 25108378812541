import React from 'react';
import { connect } from 'react-redux';

import { getSite, initializeDefaultSite } from 'concepts/site';
import PageLoader from 'components/PageLoader';

class SiteLoading extends React.Component {
  componentDidMount() {
    const { initializeDefaultSite, site } = this.props;

    if (!site) {
      initializeDefaultSite();
    }
  }

  render() {
    return <PageLoader />;
  }
}

const mapStateToProps = (state) => ({
  site: getSite(state),
});

const mapDispatchToProps = { initializeDefaultSite };

export default connect(mapStateToProps, mapDispatchToProps)(SiteLoading);
