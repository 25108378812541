import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  clearUserSearch,
  getSearchedUsers,
  getUserSearchLoading,
  searchUsers,
} from 'concepts/twitter';
import PageSearch from 'components/PageSearch';
import EmptySearchResult from 'components/EmptySearchResult';
import UserMessageActionCard from 'components/UserMessageActionCard';
import { parseUsernameFromTwitterUrl } from 'services/twitter-url';

// Autocomplete expect following values from items: id, name, image, description
const itemTransform = ({
  id,
  screen_name,
  name,
  profile_image_url_https,
  profile_image_url,
  ...rest
}) => ({
  id: `${id}`,
  description: name,
  image: profile_image_url_https || profile_image_url,
  name: `@${screen_name}`,
  isDisabled: rest.protected,
  title: rest.protected
    ? `Only approved followers can see @${screen_name} posts. Therefore we won’t collect the posts for public display.`
    : null,
  additionalInfo: rest.protected ? 'Protected Posts 🔒' : null,
});

const EmptyResult = searchText => (
  <EmptySearchResult
    title={`No Twitter users found with *${searchText}*`}
    tipText="Try pasting a link to the Twitter user"
  />
);

class TwitterUserSearch extends Component {
  onSearch = searchTerm => {
    // Allow searching with Twitter URLs
    const usernameCandidate = parseUsernameFromTwitterUrl(searchTerm) || searchTerm;
    return this.props.searchUsers(usernameCandidate);
  };

  render() {
    const { clearUserSearch, isSearching, searchedUsers, searchUsers, ...rest } = this.props;

    return (
      <UserMessageActionCard title="Search for a Twitter user">
        <PageSearch
          noMargin
          autoFocus
          placeholder="@username"
          searchDebounceWait={400} // + minimize API calls - slower response time from input
          renderEmptyResult={EmptyResult}
          items={searchedUsers.map(itemTransform)}
          isSearching={isSearching}
          onClear={clearUserSearch}
          onSearch={this.onSearch}
          {...rest}
        />
      </UserMessageActionCard>
    );
  }
}

TwitterUserSearch.propTypes = {
  clearUserSearch: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  searchedUsers: PropTypes.array.isRequired,
  searchUsers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isSearching: getUserSearchLoading(state),
  searchedUsers: getSearchedUsers(state),
});

const mapDispatchToProps = {
  clearUserSearch,
  searchUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwitterUserSearch);
