import React, { useState } from 'react';
import classnames from 'classnames';
import noop from 'lodash/noop';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';

import ActionButton from 'components/ActionButton';
import UserMessageActionCard from 'components/UserMessageActionCard';
import styles from './UserMessageCheckboxActions.module.scss';

// HOX this uses React Hooks!
// https://reactjs.org/docs/hooks-intro.html
function UserMessageCheckboxActions({
  action,
  actionButtonText,
  getActionButtonText,
  title,
  options,
  minSelected,
}) {
  // Set all selected on start
  const initialState = options.filter(option => option.isSelected).map(option => option.value);
  const [selectedOptions, setSelectedOptions] = useState(initialState);

  function handleOptionChange(value) {
    // copy array from 'state' selectedOptions
    let nextState = [...selectedOptions];
    const selectedOptionIndex = selectedOptions.indexOf(value);

    // remove or add
    if (selectedOptionIndex >= 0) {
      nextState.splice(selectedOptionIndex, 1);
    } else {
      nextState.push(value);
    }

    setSelectedOptions(nextState);
  }

  const handleAction = () => action(selectedOptions);

  return (
    <UserMessageActionCard title={title}>
      <React.Fragment>
        <div className={styles.options}>
          {options.map(option => {
            const isSelected = includes(selectedOptions, option.value);
            const isDisabled = selectedOptions.length === 1;
            const isLastRemaining = minSelected && isSelected && isDisabled;
            const handleClick = !isLastRemaining
              ? () => handleOptionChange(option.value)
              : undefined;

            return (
              <div
                className={classnames(styles.option, {
                  [styles.selected]: isSelected,
                  [styles.disabled]: isLastRemaining,
                  [styles.hidden]: option.hidden,
                })}
                title={
                  isLastRemaining
                    ? `At least ${minSelected} selection is required. To remove this, please make another selection first.`
                    : null
                }
                key={option.value}
                onClick={handleClick}
              >
                <div className={styles.checkbox} />
                <div className={styles.label}>{option.text}</div>
              </div>
            );
          })}
        </div>

        <ActionButton onClick={handleAction}>
          {actionButtonText || getActionButtonText(selectedOptions.length)}
        </ActionButton>
      </React.Fragment>
    </UserMessageActionCard>
  );
}

UserMessageCheckboxActions.defaultProps = {
  options: [],
  getActionButtonText: noop,
};

UserMessageCheckboxActions.propTypes = {
  options: PropTypes.array.isRequired,
};

export default React.memo(UserMessageCheckboxActions);
