import React, { useState } from 'react';
import classnames from 'classnames';

import styles from './UserAvatar.module.scss';

const TextAvatar = ({ userName, charCount }) => {
  const nameWithoutAt =
    userName.indexOf('@') === 0 ? userName.substring(1, userName.length) : userName;
  const avatarChars = nameWithoutAt
    .split(' ')
    .map(part => part.substring(0, 1))
    .slice(0, charCount)
    .join('');

  return <span className={styles.textAvatar}>{avatarChars}</span>;
};

const UserAvatar = ({ userName, profilePictureUrl, className, charCount, ...rest }) => {
  const [isImageFound, setImageFoundStatus] = useState(!!profilePictureUrl);

  return (
    <div title={userName} className={classnames(className, styles.avatar)} {...rest}>
      {isImageFound && (
        <img
          onError={() => setImageFoundStatus(false)}
          src={profilePictureUrl}
          className={styles.imageAvatar}
          alt={userName}
        />
      )}
      {!isImageFound && <TextAvatar userName={userName} charCount={charCount} />}
    </div>
  );
};

UserAvatar.defaultProps = {
  profilePictureUrl: undefined,
  charCount: 2,
  userName: '',
};

export default React.memo(UserAvatar);
