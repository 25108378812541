// Concept for (Intercom) Messenger state

// Selectors
export const getMessengerOpenState = state => state.messenger.isOpen;

// Action Types
export const ACTIONS = Object.freeze({
  OPEN_MESSENGER: 'MESSENGER/OPEN_MESSENGER',
  CLOSE_MESSNGER: 'MESSENGER/CLOSE_MESSNGER',
});

// Action Creators
export const onOpenMessenger = () => ({ type: ACTIONS.OPEN_MESSENGER });
export const onCloseMessenger = () => ({ type: ACTIONS.CLOSE_MESSNGER });

export const initialState = Object.freeze({
  isOpen: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.OPEN_MESSENGER: {
      return {
        ...state,
        isOpen: true,
      };
    }

    case ACTIONS.CLOSE_MESSNGER: {
      return {
        ...state,
        isOpen: false,
      };
    }

    default: {
      return state;
    }
  }
}
