// # User concept
import { createSelector } from 'reselect';
import { getGravatarUrl } from 'services/gravatar';
import { fetchUser as apiFetchUser } from 'services/api';
import getOr from 'lodash/fp/getOr';
import get from 'lodash/get';
import redirect from 'services/redirect';
import config from 'config';

// Action types
const ACTIONS = Object.freeze({
  USER_LOADED: 'USER/USER_LOADED',
});

// Selectors
export const getUser = state => state.user.user;

export const getUserAvatar = createSelector(getUser, user => {
  const email = user ? user.email : '';
  return getGravatarUrl(email);
});

export const getUserId = createSelector(
    getUser, user => get(user, 'id')
);

export const getUsername = createSelector(
  getUser,
  user => get(user, 'username') || `${get(user, 'firstname')} ${get(user, 'lastname')}`
);

export const getUserFirstname = createSelector(
  getUser,
  user => get(user, 'firstname') || get(user, 'username') || ''
);

export const getUserHasCreatedFirstFeed = createSelector(
  getUser,
  getOr(false, 'first_tracker_created')
);

export const isAdminUser = createSelector(getUser, user => {
  if (!user || !user.user_roles) {
    return false;
  }

  return user.user_roles.some(role => role.role_name === 'admin');
});

// Action creators
const loadUserAction = user => ({ type: ACTIONS.USER_LOADED, user });

export const fetchUser = () => dispatch =>
  apiFetchUser()
    .then(response => dispatch(loadUserAction(response.data.user)))
    .catch(error => {
      redirect(`${config.loginUrl}?redirect=${window.location}`, { isBackDisabled: true });
    });

// Reducer
const initialState = Object.freeze({
  user: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.USER_LOADED: {
      return { ...state, user: action.user };
    }

    default:
      return state;
  }
}
