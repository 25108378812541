import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { createStructuredSelector } from 'reselect';

import { answerQuestion, getAnswers } from 'concepts/feed-builder';
import { SERVICE_TO_FEED_TYPE, SERVICES, FEED_TYPE_NAMES } from 'constants/Services';

import {
  getReconnectFeedInfo,
  getReconnectingLoading,
  getReconnectingStatus,
  reconnectAccount,
  getReconnectingError,
} from 'concepts/reconnect-account';
import { getMessengerOpenState } from 'concepts/messenger';
import { redirectToFlocklerFeedsList } from 'concepts/redirect-flockler';
import { getConversationState } from 'services/conversation-updater';
import Question from 'components/Question';

import styles from './ReconnectConversation.module.scss';

const QUESTION_RECONNECT_ACCOUNT = 'RECONNECT/RECONNECT_ACCOUNT';
const QUESTION_RECONNECT_DONE = 'RECONNECT/RECONNECT_DONE';
const QUESTION_RECONNECT_FAILED = 'RECONNECT/RECONNECT_FAILED';
const QUESTION_RECONNECT_LOADING = 'RECONNECT/RECONNECT_LOADING';

const EMPTY_RESPONSE = { value: null, text: null };

const getConnectButtonText = feed => {
  if (feed.service === SERVICES.TIKTOK) {
    return (
      <>
        Reconnect TikTok account <strong>{feed.matchAuthorName}</strong>
      </>
    );
  }

  const feedServiceName = SERVICE_TO_FEED_TYPE[feed.service];
  if (feedServiceName) {
    return `Reconnect ${SERVICE_TO_FEED_TYPE[feed.service]} account`;
  }

  return 'Connect your account';
};

const getReconnectBotMessages = feed => {
  if (feed.service === SERVICES.TIKTOK) {
    return [
      <>
        Seems like an account for TikTok feed <strong>@{feed.matchAuthorName}</strong> has expired.
        No worries, let’s fix it!
      </>,
    ];
  }

  if (feed.service === SERVICES.LINKEDIN) {
    return [
      <>
        Seems like an account for <strong>{feed.matchAuthorVisibleName}</strong> LinkedIn feed has
        expired. No worries, let’s fix it!
      </>,
      `Please reconnect LinkedIn account which has access to ${feed.matchAuthorVisibleName} LinkedIn Page.`,
    ];
  }

  return ['Seems like an account for your feed has expired. No worries, let’s fix it!'];
};

const getReconnectFailureMessages = (feed, reconnectError) => {
  if (feed.service === SERVICES.TIKTOK && reconnectError === 'authorNotMatch') {
    return [
      `The account you connected is not the same as the account collected by the feed.`,
      `Please connect the TikTok account with username: ${feed.matchAuthorName}`,
    ];
  }

  if (
    feed.service === SERVICES.LINKEDIN &&
    ['linkedinNoOrganizations', 'authorNotMatch'].includes(reconnectError)
  ) {
    return [
      `The account you connected doesn’t have access to ${feed.matchAuthorVisibleName} Linkedin Page.`,
      `Please connect a LinkedIn account which have access to that Page.`,
    ];
  }

  return ['Couldn’t reconnect your account, please try again'];
};

class ReconnectConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { initialFeed: null };
  }

  componentDidUpdate(prevProps) {
    // set initialFeed to state when first loaded to prevent feed updates from affecting conversation flow
    if (!this.state.initialFeed && prevProps.feed !== this.props.feed) {
      this.setState({ initialFeed: this.props.feed });
    }
  }

  getConversationTemplate = () => {
    const {
      answerQuestion,
      isReconnecting,
      isReconnectFailed,
      reconnectError,
      reconnectAccount,
      redirectToFlocklerFeedsList,
    } = this.props;
    const feed = this.state.initialFeed || {};
    const feedServiceName = FEED_TYPE_NAMES[SERVICE_TO_FEED_TYPE[feed.service]];

    return [
      {
        actions: [
          {
            action: () => {
              reconnectAccount()
                .then(accountName => {
                  answerQuestion({
                    questionId: QUESTION_RECONNECT_ACCOUNT,
                    response: {
                      value: 'done',
                      text: `Account ${accountName || ''} connected`,
                    },
                    nextId: QUESTION_RECONNECT_DONE,
                  });
                })
                .catch(() => {
                  // there was an error connecting account
                  // error will be handled with RECONNECT_FAILED message
                });
            },
            id: 'reconnect',
            name: getConnectButtonText(feed),
          },
        ],
        id: QUESTION_RECONNECT_ACCOUNT,
        messages: getReconnectBotMessages(feed),
        response: EMPTY_RESPONSE,
        visible: true,
        disableUndo: true,
      },

      // Error state for reconnect failure
      {
        id: QUESTION_RECONNECT_FAILED,
        isUserReplyDisabled: true,
        messages: getReconnectFailureMessages(feed, reconnectError),
        visible: isReconnectFailed && !isReconnecting,
      },

      // Loading state for reconnecting
      {
        id: QUESTION_RECONNECT_LOADING,
        isUserReplyDisabled: true,
        isBotMessageDisabled: !isReconnecting, // <- true when loading is ready! hides question
        isLoading: true, // shows just loader
        messages: ['Loading'],
        visible: true,
      },

      // Reconnect done
      {
        actions: [
          {
            action: () => redirectToFlocklerFeedsList(),
            id: 'back_to_app',
            name: 'Show me the list of feeds',
          },
        ],
        id: QUESTION_RECONNECT_DONE,
        messages: ['The account has been reconnected 👌'],
        visible: false,
        disableAnimation: true, // loader message will be animated
      },
    ];
  };

  renderConversation(conversationState) {
    return conversationState
      .filter(question => question.visible)
      .map(question => <Question key={question.id} question={question} />);
  }

  render() {
    const { answers } = this.props;

    const conversationTemplate = this.getConversationTemplate();
    const conversationState = getConversationState(conversationTemplate, answers);

    return (
      <div
        className={classnames(styles.conversation, {
          [styles.dodgeMessenger]: this.props.isMessengerOpen,
        })}
      >
        <div className={styles.conversationWrapper}>
          <div className={styles.conversationPart}>
            {this.renderConversation(conversationState)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  answers: getAnswers,
  feed: getReconnectFeedInfo,
  isMessengerOpen: getMessengerOpenState,
  isReconnecting: getReconnectingLoading,
  isReconnectFailed: getReconnectingStatus,
  reconnectError: getReconnectingError,
});

const mapDispatchToProps = {
  answerQuestion,
  reconnectAccount,
  redirectToFlocklerFeedsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReconnectConversation);
