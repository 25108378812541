import React from 'react';

import { connect } from 'react-redux';
import get from 'lodash/get';
import includes from 'lodash/includes';
import {
  clearPageSearch,
  getAnswerTextForFacebookPage,
  getAnswersForFacebookPageFilters,
  getAnswersForFacebookHashtags,
  getAnswerForPageType,
  getPages,
  getPagesLoadingStatus,
  getSearchLoadingStatus,
  getSearchedPages,
  getSelectedPageType,
  fetchPages,
  searchPages,
  setSelectedPageType,
} from 'concepts/facebook';
import { PAGE_TYPES, QUESTION_IDS as QUESTION, SOURCES } from 'services/media-trackers/facebook';
import { getConversationState } from 'services/conversation-updater';
import { containsId } from 'utils/common';
import { helpScoutMessage } from 'utils/help-scout';

import { SERVICES } from 'constants/Services';
import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';
import FacebookPageSearch from 'components/FacebookPageSearch';
import LearnMoreLink from 'components/LearnMoreLink';
import WordInput from 'components/WordInput';

const EMPTY_RESPONSE = { value: null, text: null };

const SOURCE_OPTIONS = [
  {
    value: SOURCES.ADMIN_POSTS,
    text: 'Company posts by Page admins/managers',
    shortText: 'admins',
    isSelected: true,
  },
  {
    value: SOURCES.MENTIONS,
    text: 'Public posts on Facebook mentioning your Company Page',
    shortText: 'your Page mentions',
    isSelected: false,
  },
  {
    value: SOURCES.RATINGS,
    text: 'Fans posting recommendations / reviews',
    shortText: 'your Page recommendations / reviews',
    isSelected: false,
  },
];

function getSourceSelectionText(values, options) {
  return options.filter(option => includes(values, option.value)).map(option => option.text);
}

function tagList(tags) {
  return tags.map(tag => tag.text);
}

function getPageTypeQuestionMessages(pages) {
  if (!pages.length) {
    return [];
  }

  if (pages.length > 1) {
    return [
      'Would you like to display a feed from one of the Pages that you manage or another public Page?',
    ];
  }

  return [`Would you like to display a feed from ${pages[0].name} or another public Page?`];
}

function pageFiltersInSentence(values = []) {
  const shortTexts = SOURCE_OPTIONS.filter(option => includes(values, option.value)).map(
    option => option.shortText
  );

  switch (shortTexts.length) {
    case 1:
      return shortTexts[0];
    case 2:
      return shortTexts.join(' and ');
    case 3:
      return `${shortTexts[0]}, ${shortTexts[1]}, and ${shortTexts[2]}`;
    default:
      return null;
  }
}

function getHashtagConfirmationMessage(whatToCollect, hashtags) {
  switch (whatToCollect) {
    case SOURCES.ADMIN_POSTS: {
      return (
        <>
          You decided to gather posts from your Page, but only if{' '}
          <strong>{hashtags.join(', ')}</strong> is mentioned. This will most likely limit the
          number of posts available for me to gather. Are you sure you’d like to continue?
        </>
      );
    }

    case SOURCES.FAN_POSTS: {
      return (
        <>
          You decided to gather posts from{' '}
          <strong>
            people visiting and leaving a post on your Company Page AND mentioning{' '}
            {hashtags.join(', ')}
          </strong>{' '}
          in the same post. This will most likely limit the number of posts available for me to
          gather. Are you sure you’d like to continue?
        </>
      );
    }

    case SOURCES.MENTIONS: {
      return (
        <>
          You decided to gather public posts by anyone{' '}
          <strong>mentioning your Company Page AND {hashtags.join(', ')}</strong> in the same post.
          This will most likely limit the number of posts available for me to gather. Are you sure
          you’d like to continue?
        </>
      );
    }

    case 'PUBLIC_PAGE_ADMIN_POSTS': {
      return (
        <>
          You decided to gather posts from a Page, but only if{' '}
          <strong>{hashtags.join(', ')}</strong> is mentioned. This will most likely limit the
          number of posts available for me to gather. Are you sure you’d like to continue?
        </>
      );
    }

    default: {
      return null;
    }
  }
}

class ConversationAboutFacebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collectFromSources: [] };
  }

  connectCallback = id => {
    const { answerQuestion, fetchPages } = this.props;

    return fetchPages(id)
      .then(pages => {
        return answerQuestion({
          response: {
            value: `${pages.length} Pages found`,
            text: `${pages.length} Pages found`,
          },
          questionId: QUESTION.LOADING_PAGES,
          nextId:
            pages.length > 0 ? QUESTION.CHOOSE_FACEBOOK_PAGE_TYPE : QUESTION.CHOOSE_FACEBOOK_PAGE,
        });
      })
      .catch(error => {
        return answerQuestion({
          response: {
            value: 'Error loading pages',
            text: 'Error loading pages',
          },
          questionId: QUESTION.LOADING_PAGES,
          nextId: QUESTION.ERROR_LOADING_PAGES,
        });
      });
  };

  reconnectAccount = () => {
    const { getConnectAction } = this.props;

    const reconnect = getConnectAction(
      QUESTION.ACCOUNT_CONNECTED_ALREADY,
      QUESTION.LOADING_PAGES,
      null,
      true
    );
    reconnect();
  };

  getConversationTemplate = () => {
    const {
      answerQuestion,
      clearPageSearch,
      createAccountConnectQuestion,
      getConnectAction,
      initialAccountCount,
      isLoadingPages,
      isSearching,
      pages,
      pageType,
      chosenPageTypeOption,
      revertToQuestion,
      searchedPages,
      searchPages,
      selectedFilters,
      selectedHashtags,
      selectedPage,
      setSelectedPageType,
    } = this.props;

    const hasAdminPages = pages.length > 0;
    const isPublicChosen = !hasAdminPages || chosenPageTypeOption === PAGE_TYPES.PUBLIC;
    const hasOnlyOnePageAndUsingIt = pages.length === 1 && !isPublicChosen;

    return [
      // Question for user who has no accounts yet for service
      createAccountConnectQuestion({
        actions: [
          {
            action: getConnectAction(QUESTION.NO_ACCOUNT_CONNECTED_YET, QUESTION.LOADING_PAGES),
            id: QUESTION.CONNECT_ACCOUNT,
            name: 'Connect Facebook account…',
          },
        ],
        messages: [
          'Facebook, got it! 👍',
          'To display content from Facebook, you’ll need to connect your personal Facebook account.',
          <>
            Don’t worry; we don’t display any of your personal Facebook posts or store any personal
            data about you.{' '}
            <strong style={{ background: 'yellow', padding: '0 1px' }}>
              After connecting your account, you can select any Company Page content to display.
            </strong>
            <br />
            <LearnMoreLink href="https://flockler.help/en/articles/3558307-why-do-i-have-to-connect-my-personal-facebook-account" />
          </>,
        ],
        nextId: QUESTION.LOADING_PAGES,
        visible: initialAccountCount === 0,
        questionId: QUESTION.NO_ACCOUNT_CONNECTED_YET,
        callback: this.connectCallback,
        shouldBeReverted: true,
      }),

      // Question for user who has account(s) connected already
      createAccountConnectQuestion({
        actions: [],
        messages: [],
        nextId: QUESTION.LOADING_PAGES,
        visible: initialAccountCount !== 0,
        questionId: QUESTION.ACCOUNT_CONNECTED_ALREADY,
        callback: this.connectCallback,
        shouldBeReverted: true,
      }),

      // "Ghost" question when waiting for pages to load
      {
        id: QUESTION.LOADING_PAGES,
        nextId: QUESTION.CHOOSE_FACEBOOK_PAGE_TYPE,
        isLoading: true,
        isUserReplyDisabled: true,
        isBotMessageDisabled: !isLoadingPages,
        messages: [],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Access token error
      {
        id: QUESTION.ERROR_LOADING_PAGES,
        nextId: QUESTION.CHOOSE_FACEBOOK_PAGE_TYPE,
        isUserReplyDisabled: true,
        messages: [
          'I could not fetch Facebook Pages at this time 🤔',
          <span>
            Please try{' '}
            <button onClick={this.reconnectAccount}>reconnecting your Facebook account</button> or{' '}
            <button
              onClick={() => helpScoutMessage({ subject: 'Facebook Pages do not load correctly.' })}
            >
              chat with us
            </button>{' '}
            if problem persists.
          </span>,
        ],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      {
        // no admin pages
        isBotMessageDisabled: !hasAdminPages,
        isUserReplyDisabled: !hasAdminPages,
        autoAction: !hasAdminPages
          ? () =>
              answerQuestion({
                response: {
                  value: 'public',
                  text: 'Another Page',
                },
                nextId: QUESTION.CHOOSE_FACEBOOK_PAGE,
                questionId: QUESTION.CHOOSE_FACEBOOK_PAGE_TYPE,
              })
          : null,

        // user has admin pages
        actions: hasAdminPages
          ? [
              {
                action: () => {
                  answerQuestion({
                    response: {
                      value: PAGE_TYPES.PUBLIC,
                      text: 'Another Page',
                    },
                    nextId: QUESTION.CHOOSE_FACEBOOK_PAGE,
                    questionId: QUESTION.CHOOSE_FACEBOOK_PAGE_TYPE,
                  });
                },
                id: 'public',
                name: 'Another Page',
              },

              {
                action: () => {
                  if (pages.length === 1) {
                    const page = pages[0];

                    // When user selects the only available account
                    // we need to answer this and next question at the same time!
                    return Promise.resolve(
                      answerQuestion({
                        response: {
                          value: PAGE_TYPES.ADMIN,
                          text: page.name,
                          image: page.image,
                        },
                        questionId: QUESTION.CHOOSE_FACEBOOK_PAGE_TYPE,
                        nextId: QUESTION.CHOOSE_FACEBOOK_PAGE,
                        disableValueTracking: true,
                      })
                    ).then(() => {
                      setSelectedPageType(PAGE_TYPES.ADMIN);

                      return answerQuestion({
                        response: {
                          value: page.id,
                          text: page.name,
                          image: page.image,
                        },
                        questionId: QUESTION.CHOOSE_FACEBOOK_PAGE,
                        nextId: QUESTION.WHAT_TO_COLLECT,
                        disableValueTracking: true,
                      });
                    });
                  }

                  answerQuestion({
                    response: {
                      value: PAGE_TYPES.ADMIN,
                      text: 'Page I manage',
                    },
                    nextId: QUESTION.CHOOSE_FACEBOOK_PAGE,
                    questionId: QUESTION.CHOOSE_FACEBOOK_PAGE_TYPE,
                  });
                },
                id: 'admin',
                name: pages.length === 1 ? `From ${pages[0].name}` : 'From Page I manage',
              },
            ]
          : null,

        id: QUESTION.CHOOSE_FACEBOOK_PAGE_TYPE,
        messages: getPageTypeQuestionMessages(pages),
        response: EMPTY_RESPONSE,
        visible: false,
        disableAnimation: true,
      },

      // Select Facebook Page to use
      {
        // Question will be hidden if chosen to use only available page
        isBotMessageDisabled: hasOnlyOnePageAndUsingIt,
        isUserReplyDisabled: hasOnlyOnePageAndUsingIt,

        // select-input with search
        select: {
          title: 'Select a Facebook Page',
          options: isPublicChosen
            ? []
            : pages.map(page => ({
                action: () => {
                  setSelectedPageType(PAGE_TYPES.ADMIN);

                  answerQuestion({
                    response: {
                      value: page.id,
                      text: page.name,
                      image: page.image,
                    },
                    questionId: QUESTION.CHOOSE_FACEBOOK_PAGE,
                    nextId: QUESTION.WHAT_TO_COLLECT,
                    disableValueTracking: true,
                  });
                },
                id: page.id,
                name: page.name,
                logoUrl: page.image,
                description: page.category,
              })),
          children: isPublicChosen && (
            <FacebookPageSearch
              autoFocus
              noMargin
              title="Add a public Facebook Page"
              items={searchedPages}
              isSearching={isSearching}
              onClear={clearPageSearch}
              onSearch={searchPages}
              onSelect={page => {
                // Is selected page one you admin or not
                const isSelectedPageAdmin = containsId(pages, page.id);
                const pageType = isSelectedPageAdmin ? PAGE_TYPES.ADMIN : PAGE_TYPES.PUBLIC;
                setSelectedPageType(pageType);

                answerQuestion({
                  response: {
                    value: page.id,
                    text: page.name,
                    image: page.image,
                  },
                  questionId: QUESTION.CHOOSE_FACEBOOK_PAGE,
                  nextId: isSelectedPageAdmin
                    ? QUESTION.WHAT_TO_COLLECT
                    : QUESTION.CAN_COLLECT_ONLY_ADMIN,
                  disableValueTracking: true,
                });
              }}
            />
          ),
        },
        id: QUESTION.CHOOSE_FACEBOOK_PAGE,
        messages: ['Which Facebook Page would you like to use?'],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Select what to collect

      {
        actions: SOURCE_OPTIONS.map(option => ({
          action: () => {
            answerQuestion({
              response: {
                value: [option.value], // HOX value as array
                text: getSourceSelectionText([option.value], SOURCE_OPTIONS),
              },
              questionId: QUESTION.WHAT_TO_COLLECT,
              nextId: option.value === SOURCES.RATINGS ? null : QUESTION.REQUIRE_KEYWORD,
            });
          },
          id: option.value,
          name: option.text,
        })),
        actionButtonsDirection: 'column',

        id: QUESTION.WHAT_TO_COLLECT,
        messages: ['What would you like me to collect?'],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      {
        autoAction: () => {
          answerQuestion({
            response: {
              value: [SOURCE_OPTIONS[0].value],
              text: SOURCE_OPTIONS[0].text,
            },
            questionId: QUESTION.CAN_COLLECT_ONLY_ADMIN,
            nextId: QUESTION.REQUIRE_KEYWORD,
          });
        },

        id: QUESTION.CAN_COLLECT_ONLY_ADMIN,
        nextId: QUESTION.REQUIRE_KEYWORD,
        messages: [],
        response: EMPTY_RESPONSE,
        visible: false,
        disableUndo: true,
        isUserReplyDisabled: true,
      },

      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'needsToMention',
                  text: 'No, posts need to mention...',
                },
                nextId: QUESTION.HASHTAG_INPUT,
                questionId: QUESTION.REQUIRE_KEYWORD,
              });
            },
            id: 0,
            name: 'No, posts need to mention…',
          },
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'collectAll',
                  text: 'Collect all posts',
                },
                nextId: null,
                questionId: QUESTION.REQUIRE_KEYWORD,
              });
            },
            id: 1,
            name: 'Yes, display all',
          },
        ],

        id: QUESTION.REQUIRE_KEYWORD,
        messages:
          pageType === PAGE_TYPES.PUBLIC
            ? [
                <span>
                  Ok, I'll collect posts by <i>{selectedPage}</i> Facebook Page for you.
                </span>,
                <span>
                  Would you like to display all the posts from <i>{selectedPage}</i> Facebook Page?
                </span>,
              ]
            : [
                `Would you like to show all the posts from ${pageFiltersInSentence(
                  selectedFilters
                )}?`,
              ],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Keyword input
      {
        actions: [],
        input: (
          <WordInput
            action={hashtags =>
              answerQuestion({
                response: {
                  value: tagList(hashtags),
                  text: tagList(hashtags),
                  isTags: true,
                },
                nextId: QUESTION.CONFIRM_HASHTAG,
                questionId: QUESTION.HASHTAG_INPUT,
              })
            }
            placeholder="Type in a keyword"
            autoActionOnMaxTags
            maxTags={1}
          />
        ),
        id: QUESTION.HASHTAG_INPUT,
        messages: [],
        response: EMPTY_RESPONSE,
        undoText: 'Edit keyword…',
        visible: false,
      },

      {
        actions: [
          {
            action: () => {
              revertToQuestion(QUESTION.REQUIRE_KEYWORD);
            },
            id: 0,
            name: 'I’d like to change my answer',
          },
          {
            action: () => {
              answerQuestion({
                response: {
                  value: 'confirm',
                  text: 'Yes, let’s continue',
                },
                nextId: null,
                questionId: QUESTION.CONFIRM_HASHTAG,
              });
            },
            id: 1,
            name: 'Yes, let’s continue',
          },
        ],
        id: QUESTION.CONFIRM_HASHTAG,
        messages: [
          getHashtagConfirmationMessage(
            get(selectedFilters, [0]) || 'PUBLIC_PAGE_ADMIN_POSTS',
            selectedHashtags || []
          ),
        ],
        response: EMPTY_RESPONSE,
        visible: false,
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoadingPages: getPagesLoadingStatus(state),
  isSearching: getSearchLoadingStatus(state),
  pages: getPages(state),
  pageType: getSelectedPageType(state),
  chosenPageTypeOption: getAnswerForPageType(state),
  searchedPages: getSearchedPages(state),
  selectedPage: getAnswerTextForFacebookPage(state),
  selectedFilters: getAnswersForFacebookPageFilters(state),
  selectedHashtags: getAnswersForFacebookHashtags(state),
});

const mapDispatchToProps = {
  clearPageSearch,
  fetchPages,
  searchPages,
  setSelectedPageType,
};

const ConnectedConversationAboutFacebook = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationAboutFacebook);

export default serviceConversation(ConnectedConversationAboutFacebook, {
  service: SERVICES.FACEBOOK,
});
