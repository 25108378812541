import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { ReactComponent as ExternalLinkSVG } from 'assets/svgs/external-link.svg';
import { ReactComponent as AddSVG } from 'assets/svgs/icon-add.svg';
import UserMessageActionCard from 'components/UserMessageActionCard';
import styles from './UserMessageSelectActions.module.scss';

const UserMessageSelectActions = ({ children, options, title }) => {
  const hasOptions = options.length;

  return (
    <UserMessageActionCard title={hasOptions > 0 ? title : null}>
      <React.Fragment>
        {options.map(option => (
          <button
            key={option.id}
            className={classnames(styles.option, { [styles.noImageOption]: !option.logoUrl })}
            onClick={option.action}
          >
            <span className={styles.leftCol}>
              {!!option.logoUrl && (
                <span
                  className={styles.logo}
                  style={{ backgroundImage: `url(${option.logoUrl})` }}
                />
              )}
              <span className={styles.optionInfo}>
                <span>{option.name}</span>
                {option.description && (
                  <span className={styles.description}>{option.description}</span>
                )}
                {option.serviceUrl && (
                  <a
                    className={styles.optionLink}
                    href={option.serviceUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {option.serviceLinkText}
                    <ExternalLinkSVG className={styles.optionLinkIcon} />
                  </a>
                )}
              </span>
            </span>

            <span className={styles.plusSign}>
              <AddSVG className={styles.plusSignIcon} />
            </span>
          </button>
        ))}
        {children}
      </React.Fragment>
    </UserMessageActionCard>
  );
};

UserMessageSelectActions.defaultProps = {
  children: undefined,
  options: [],
};

UserMessageSelectActions.propTypes = {
  children: PropTypes.node,
  options: PropTypes.array.isRequired,
};

export default React.memo(UserMessageSelectActions);
