// # Functionality for common questions
import { FEED_TYPES } from 'constants/Services';
import { FACEBOOK_FEED_CONFIG_TEMPLATE } from './facebook';
import { LINKEDIN_FEED_CONFIG_TEMPLATE } from './linkedin';
import { INSTAGRAM_FEED_CONFIG_TEMPLATE } from './instagram';
import { PINTEREST_FEED_CONFIG_TEMPLATE } from './pinterest';
import { REVIEWS_FEED_CONFIG_TEMPLATE } from './reviews';
import { RSS_FEED_CONFIG_TEMPLATE } from './rss';
import { TIKTOK_FEED_CONFIG_TEMPLATE } from './tiktok';
import { TWITTER_FEED_CONFIG_TEMPLATE } from './twitter';
import { YOUTUBE_FEED_CONFIG_TEMPLATE } from './youtube';
import { GOOGLE_REVIEW_FEED_CONFIG_TEMPLATE } from './google-review';
import { setSectionId, updateConfig } from './shared';

export const STEPS = Object.freeze({
  CHOOSE_MODERATION: 'COMMON/CHOOSE_MODERATION',
  CHOOSE_SECTION: 'COMMON/CHOOSE_SECTION',
  CHOOSE_SERVICE: 'COMMON/CHOOSE_SERVICE',
  PREPARE_FEED_CREATION: 'COMMON/PREPARE_FEED_CREATION',
  CONFIRM_FEED_WITHOUT_HISTORY: 'COMMON/CONFIRM_FEED_WITHOUT_HISTORY',
  CREATING_MEDIA_TRACKER: 'COMMON/CREATING_MEDIA_TRACKER',
  CONFIRM_AI_MODERATION: 'COMMON/CONFIRM_AI_MODERATION',
  CONFIRM_MODERATION: 'COMMON/CONFIRM_MODERATION',
  INCLUDE_HISTORY_OR_NOT: 'COMMON/INCLUDE_HISTORY_OR_NOT',
  MAX_FEEDS_REACHED: 'COMMON/MAX_FEEDS_REACHED',
  NO_HISTORY_FOR_FEED: 'COMMON/NO_HISTORY_FOR_FEED',
  HISTORY_FOUND_FOR_FEED: 'COMMON/HISTORY_FOUND_FOR_FEED',
  HISTORY_FOUND_FOR_FEED_FIRST_TIMER: 'COMMON/HISTORY_FOUND_FOR_FEED_FIRST_TIMER',
  HISTORY_FOUND_FOR_FEED_FIRST_TIMER_INBOX: 'COMMON/HISTORY_FOUND_FOR_FEED_FIRST_TIMER_INBOX',
  ERROR_CREATING_FEED: 'COMMON/ERROR_CREATING_FEED',
  PREVIEW: 'COMMON/PREVIEW',
  TRIGGER_HISTORY_PREVIEW: 'COMMON/TRIGGER_HISTORY_PREVIEW',
});

const CONFIG_TEMPLATES = {
  [FEED_TYPES.FACEBOOK]: FACEBOOK_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.GOOGLE_REVIEW]: GOOGLE_REVIEW_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.LINKEDIN]: LINKEDIN_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.INSTAGRAM]: INSTAGRAM_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.PINTEREST]: PINTEREST_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.REVIEWS]: REVIEWS_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.RSS]: RSS_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.TIKTOK]: TIKTOK_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.TWITTER]: TWITTER_FEED_CONFIG_TEMPLATE,
  [FEED_TYPES.YOUTUBE]: YOUTUBE_FEED_CONFIG_TEMPLATE,
};

// Returns updated feed config state
export function commonFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case STEPS.CHOOSE_SERVICE: {
      return updateConfig(state, CONFIG_TEMPLATES[response.value]);
    }

    case STEPS.CHOOSE_SECTION: {
      return setSectionId(state, response.value);
    }

    case STEPS.CHOOSE_MODERATION:
    case STEPS.CONFIRM_MODERATION: {
      return updateConfig(state, { moderated: response.value });
    }

    default: {
      return state;
    }
  }
}
