const config = Object.freeze({
  flocklerAppUrl: process.env.REACT_APP_FLOCKLER_APP_URL,
  flocklerUrl: process.env.REACT_APP_FLOCKLER_URL,
  flocklerCookiesUrl: process.env.REACT_APP_COOKIES_URL,
  flocklerPluginsUrl: process.env.REACT_APP_FLOCKLER_PLUGINS_URL,
  newsroomUrl: process.env.REACT_APP_NEWSROOM_URL,
  helpScoutBeaconId: process.env.REACT_APP_HELP_SCOUT_BEACON_ID,
  postHogHost: process.env.REACT_APP_POSTHOG_API_HOST,
  postHogApiKey: process.env.REACT_APP_POSTHOG_API_KEY,
  loginUrl: process.env.REACT_APP_LOGIN_URL,
  privateApiUrl: process.env.REACT_APP_PRIVATE_API_URL,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  questionDelay: process.env.REACT_APP_QUESTION_DELAY,
  showFeedDebug: process.env.REACT_APP_SHOW_FEED_DEBUG,
  signupGtmId: process.env.REACT_APP_SIGNUP_GTM_ID,
  trackingGtmId: process.env.REACT_APP_TRACKING_GTM_ID,
  flocklerBaseDomain: process.env.REACT_APP_FLOCKLER_BASE_DOMAIN,
  feedsGaCode: process.env.REACT_APP_GA_CODE,
});

export default config;
