import {
  pipe,
  setAccountId,
  updateToPlaylist,
  updateToChannel,
  updateFilter,
  updateKeywords,
  updateTypeFilter,
} from './shared';
import { SERVICES } from 'constants/Services';

export const SOURCES = Object.freeze({
  PLAYLIST: 'playlist',
  CHANNEL: 'channel',
});

export const YOUTUBE_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.YOUTUBE,
  filters: [{}],
};

export const QUESTION_IDS = {
  CHOOSE_CHANNEL_OR_PLAYLIST: 'YOUTUBE/CHOOSE_CHANNEL_OR_PLAYLIST',
  CHOOSE_KEYWORDS: 'YOUTUBE/CHOOSE_KEYWORDS',
  CHOOSE_KEYWORDS_FOR_CHANNEL_OR_PLAYLIST: 'YOUTUBE/CHOOSE_KEYWORDS_FOR_CHANNEL_OR_PLAYLIST',
  CHOOSE_MODERATION: 'YOUTUBE/CHOOSE_MODERATION',
  CONNECT_ACCOUNT: 'YOUTUBE/CONNECT_ACCOUNT',
  KEYWORDS_OR_CHANNELS: 'YOUTUBE/KEYWORDS_OR_CHANNELS',
  NO_ACCOUNT_CONNECTED_YET: 'YOUTUBE/NO_ACCOUNT_CONNECTED_YET',
  ACCOUNT_CONNECTED_ALREADY: 'YOUTUBE/ACCOUNT_CONNECTED_ALREADY',
  LIMIT_VIDEOS_BY_CHANNEL_TO_KEYWORDS: 'YOUTUBE/LIMIT_VIDEOS_BY_CHANNEL_TO_KEYWORDS',
};

// Returns updated feed config state
export function youtubeFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.NO_ACCOUNT_CONNECTED_YET:
    case QUESTION_IDS.ACCOUNT_CONNECTED_ALREADY: {
      return setAccountId(state, response.value);
    }

    case QUESTION_IDS.CHOOSE_CHANNEL_OR_PLAYLIST: {
      if (response.type === SOURCES.PLAYLIST) {
        return pipe(
          updateToPlaylist(response.value.id),
          updateFilter({ name: response.value.name, image_url: response.value.image })
        )(state);
      }

      return pipe(
        updateToChannel(response.value.id),
        updateFilter({ name: response.value.name, image_url: response.value.image })
      )(state);
    }

    case QUESTION_IDS.CHOOSE_KEYWORDS_FOR_CHANNEL_OR_PLAYLIST: {
      const keywordUpdater = pipe(
        updateFilter({ match_any: false }),
        updateKeywords(response.value)
      );

      return keywordUpdater(state);
    }

    case QUESTION_IDS.CHOOSE_KEYWORDS: {
      const keywordUpdater = pipe(
        updateFilter({ match_any: false }),
        updateKeywords(response.value),
        updateTypeFilter('keyword_in_description')
      );

      return keywordUpdater(state);
    }

    default: {
      return state;
    }
  }
}
