import React from 'react';
import PropTypes from 'prop-types';

import UserAvatar from 'components/UserAvatar';
import styles from './UserMessage.module.scss';

const UserMessage = React.memo(({ avatarSrc, username, hideAvatar, children }) => {
  return (
    <div className={styles.userMessage}>
      <div className={styles.userMessageContents}>{children}</div>
      <UserAvatar
        userName={username}
        profilePictureUrl={avatarSrc}
        className={`${styles.userMessageAvatar} fs-block`}
        style={{
          visibility: hideAvatar ? 'hidden' : 'visible',
        }}
      />
    </div>
  );
});

UserMessage.defaultProps = {
  avatarSrc: undefined,
  hideAvatar: false,
};

UserMessage.propTypes = {
  avatarSrc: PropTypes.string,
  hideAvatar: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

export default UserMessage;
