import React from 'react';
import PropTypes from 'prop-types';

import config from 'config';
import styles from './BotMessageContent.module.scss';

import LoadingIndicator from 'components/LoadingIndicator';

// For how long should we show faux typing animation
export const FAUX_TYPING_INDICATOR_VISIBLE_MS = config.questionDelay;

class BotMessageContent extends React.Component {
  state = {
    fauxTyping: true,
    messageVisible: false,
  };

  componentDidMount() {
    const { index, scrollToBottom, isLoading } = this.props;

    this.messageVisibleTimeout = setTimeout(() => {
      this.setState({ ...this.state, messageVisible: true });
      scrollToBottom();
    }, FAUX_TYPING_INDICATOR_VISIBLE_MS * (index - 1) * 1.5);

    // keep loading state if has isLoading prop
    if (!isLoading) {
      this.fauxTypingTimeout = setTimeout(() => {
        this.setState({ ...this.state, fauxTyping: false });
        scrollToBottom();
      }, FAUX_TYPING_INDICATOR_VISIBLE_MS * index);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.messageVisibleTimeout);
    clearTimeout(this.fauxTypingTimeout);
  }

  render() {
    const { children, disableLoading } = this.props;
    const isShowingLoader = !disableLoading && this.state.fauxTyping;

    return (
      !!this.state.messageVisible && (
        <div className={styles.botMessageContent}>
          {isShowingLoader ? <LoadingIndicator /> : children}
        </div>
      )
    );
  }
}

BotMessageContent.defaultProps = {
  children: undefined,
  index: 1,
  isLoading: false,
};

BotMessageContent.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  isLoading: PropTypes.bool,
};

export default BotMessageContent;
