import React from 'react';

import { helpScoutMessage } from 'utils/help-scout';
import { STEPS } from 'services/media-trackers/common';

const MaxFeedsStep = ({
  maxMediaTrackersCount,
  mediaTrackerCount,
  redirectToFlocklerFeedsList,
  redirectToFlocklerSite,
}) => ({
  actions:
    maxMediaTrackersCount === 0
      ? [
          {
            action: () => redirectToFlocklerSite(),
            id: 'back_to_flockler_app',
            name: 'Back to app',
          },
        ]
      : [
          {
            action: () => redirectToFlocklerFeedsList(),
            id: 'back_to_flockler_app_feeds',
            name: 'Show list of my feeds',
          },
        ],
  id: STEPS.MAX_FEEDS_REACHED,
  messages:
    maxMediaTrackersCount === 0
      ? [
          <strong>
            It seems that you don’t have any automated feeds included in your subscription.
          </strong>,
          <span>
            Would you like to gather content without manual work?{' '}
            <button
              onClick={() =>
                helpScoutMessage({
                  subject: 'My subscription doesn’t include any automated feeds.',
                })
              }
            >
              Get in touch.
            </button>
          </span>,
        ]
      : [
          <strong>
            You have reached your limit of active feeds ({mediaTrackerCount}/{maxMediaTrackersCount}
            ).
          </strong>,
          <span>
            Need more? Disable some of the feeds or{' '}
            <button
              onClick={() => helpScoutMessage({ subject: 'I have reached limit of active feeds.' })}
            >
              get in touch with us
            </button>
            .
          </span>,
        ],
  visible: true,
});

export default MaxFeedsStep;
