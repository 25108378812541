import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';
import replace from 'lodash/replace';
import includes from 'lodash/includes';
import { WithContext as ReactTags } from 'react-tag-input';

import styles from './WordInput.module.scss';

const KEYCODE_COMMA = 188;
const KEYCODE_ENTER = 13;
const KEYCODE_SPACE = 32;
const KEYCODE_TAB = 9;

const DELIMITER_KEYCODES = [KEYCODE_COMMA, KEYCODE_ENTER, KEYCODE_SPACE, KEYCODE_TAB];
// without space when multiple word tags allowed
const MULTIWORD_DELIMITER_KEYCODES = [KEYCODE_COMMA, KEYCODE_ENTER, KEYCODE_TAB];

class WordInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      tags: props.tags || [],
      tagText: '',
    };
  }

  inputRef = React.createRef();

  validate = text => {
    // WordInput validation API
    // supports multiple validations in array
    // format: [{ validate: fn(text), errorMessage: string }]
    const { validations } = this.props;

    if (validations && validations.length > 0) {
      const notPassedValidation = validations.find(validation => !validation.validate(text));
      if (notPassedValidation) {
        this.setState({
          errorMessage: notPassedValidation.errorMessage,
        });

        return false;
      }
    }

    return true;
  };

  handleInputChange = tagText => {
    const { prepend } = this.props;
    let tagTextWithPrepend = tagText;

    this.setState({ tagText });
    this.setState({ errorMessage: null });

    const isOnlyPrepend = prepend && tagText === prepend;
    if (!tagText.length || isOnlyPrepend) {
      return;
    }

    if (tagText.indexOf(prepend) !== 0) {
      tagTextWithPrepend = `${prepend}${tagText}`;
    }

    this.validate(tagTextWithPrepend);
  };

  handleDelete = i => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  };

  handleAddition = tag => {
    const { isPrependSkippedWhenQuoted, prepend } = this.props;
    const { tags } = this.state;

    // trim text
    tag.text = tag.text.trim();

    const isQuoted = startsWith(tag.text, '"') && endsWith(tag.text, '"');
    const isPrependSkipped = isPrependSkippedWhenQuoted && isQuoted;

    // Remove all quotation marks and trim again
    tag.text = replace(tag.text, new RegExp('"', 'g'), '').trim();

    if (prepend && !isPrependSkipped && !tag.text.match(new RegExp(`^${prepend}`))) {
      tag.text = `${prepend}${tag.text}`;
    }

    if (includes(tags.map(t => t.text), tag.text)) {
      alert(`${tag.text} has already already been added.`);
      return false;
    }

    if (!tag.text || !this.validate(tag.text)) {
      return false;
    } else {
      this.setState({ errorMessage: null });
    }

    this.setState({ tags: [...tags, tag] }, this.afterAddition);
  };

  manualHandleAddition = () => {
    const { tagText } = this.state;
    if (!!tagText) {
      this.handleAddition({ id: tagText, text: tagText });
    }
  };

  afterAddition = () => {
    const { autoActionOnMaxTags, action } = this.props;
    const { tags } = this.state;

    this.setState({ tagText: '' });

    if (autoActionOnMaxTags && this.maxTagsReached()) {
      action(tags);
    }
  };

  maxTagsReached() {
    const { maxTags } = this.props;
    const { tags } = this.state;

    if (maxTags) {
      return tags.length >= maxTags;
    }

    return false;
  }

  render() {
    const { errorMessage, tags, tagText } = this.state;
    const {
      action,
      buttonLabel,
      isQuotedMultiWordTagAllowed,
      maxLength,
      placeholder,
      style,
    } = this.props;

    const isStartOfQuote = startsWith(tagText, '"');

    return (
      <React.Fragment>
        <div
          className={classnames(styles.wrapper, {
            [styles.wordInputFieldHidden]: this.maxTagsReached(),
          })}
          style={style}
        >
          <ReactTags
            allowDragDrop={false}
            classNames={{
              remove: styles.wordInputTagRemove,
              tag: styles.wordInputTag,
              tags: styles.wordInputTags,
              tagInputField: this.maxTagsReached() ? 'hidden' : styles.wordInputField,
            }}
            delimiters={
              isQuotedMultiWordTagAllowed && isStartOfQuote
                ? MULTIWORD_DELIMITER_KEYCODES
                : DELIMITER_KEYCODES
            }
            handleAddition={this.handleAddition}
            handleDelete={this.handleDelete}
            handleInputChange={this.handleInputChange}
            maxLength={maxLength}
            placeholder={placeholder}
            tags={tags}
            inputValue={tagText}
            ref={this.inputRef}
          />

          {tagText && (
            <button
              className={classnames(styles.addButton, { [styles.dodgeTags]: tags.length > 0 })}
              onClick={this.manualHandleAddition}
            >
              +
            </button>
          )}

          {!!errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}

          {!!tags.length && (
            <button className={styles.wordInputButton} onClick={() => action(tags)}>
              {buttonLabel}
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

WordInput.defaultProps = {
  buttonLabel: 'All added',
  placeholder: '',
  isQuotedMultiWordTagAllowed: false,
  isPrependSkippedWhenQuoted: false,
  validations: null,
};

WordInput.propTypes = {
  action: PropTypes.func.isRequired,
  autoActionOnMaxTags: PropTypes.bool,
  buttonLabel: PropTypes.string,
  isQuotedMultiWordTagAllowed: PropTypes.bool,
  isPrependSkippedWhenQuoted: PropTypes.bool,
  maxTags: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  validations: PropTypes.arrayOf(
    PropTypes.shape({
      validate: PropTypes.func,
      errorMessage: PropTypes.string,
    })
  ),
};

export default WordInput;
