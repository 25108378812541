import React from 'react';
import get from 'lodash/get';
import { helpScoutMessage } from 'utils/help-scout';
import { STEPS } from 'services/media-trackers/common';

const FeedCreationFailedStep = ({ error, isLoading, onRetry, redirectToFlocklerSite }) => {
  if (isLoading) {
    return {
      id: STEPS.ERROR_CREATING_FEED,
      messages: ['Retrying...'],
      isLoading: true,
      visible: false,
      isAfterCreationQuestion: true,
    };
  }

  const isRetryAvailable = get(error, ['value', 'status']) !== 422;
  const errors = get(error, ['value', 'data', 'errors']) || [];

  // This error should happen only with Instagram mentions feeds
  const insufficientAdminPermissionsError = (
    errors.find(err => err && err.insufficient_admin_permission_on_page) || {}
  ).insufficient_admin_permission_on_page;

  const maybeRetryAction = isRetryAvailable
    ? {
        action: onRetry,
        id: 'retry_feed_creation',
        name: 'Retry',
      }
    : null;

  return {
    actions: [
      maybeRetryAction,
      {
        action: () =>
          helpScoutMessage({ subject: 'I had a problem with creating automated feed.' }),
        id: 'ask_for_help',
        name: 'Ask for help',
      },
      {
        action: () => redirectToFlocklerSite(),
        id: 'back_to_flockler_app',
        name: 'Take me back to the app',
      },
    ].filter(action => action !== null),
    id: STEPS.ERROR_CREATING_FEED,
    messages: insufficientAdminPermissionsError
      ? [
          <>
            Your account doesn’t have permissions to create this feed. Please contact{' '}
            {insufficientAdminPermissionsError.name || 'your'} Page admin or ask for help.
          </>,
        ]
      : ['Creating your automated feed failed 😞'],
    visible: false,
    isAfterCreationQuestion: true,
  };
};

export default FeedCreationFailedStep;
