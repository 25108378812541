import isNil from 'lodash/isNil';
import toString from 'lodash/toString';

export function isEqual(idA, idB) {
  return toString(idA) === toString(idB);
}

export function findById(haystack, needle) {
  return haystack.find(item => isEqual(item.id, needle));
}

export function findByKey(key, haystack, needle) {
  return haystack.find(item => isEqual(item[key], needle));
}

export function containsId(haystack, needle) {
  return !isNil(findById(haystack, needle));
}
