import React, { Component } from 'react';
import replace from 'lodash/replace';

import PageSearch from 'components/PageSearch';
import EmptySearchResult from 'components/EmptySearchResult';

// Autocomplete expect following values from items: id, name, image, description
const formatDescription = link => {
  if (!link) {
    return '';
  }

  let description = replace(link, 'https://www.', '');

  // remove '/' from the end
  if (description.length && description.charAt(description.length - 1) === '/') {
    description = description.substr(0, description.length - 1);
  }

  return description;
};

const itemTransform = ({ id, image, link, name }) => ({
  id,
  description: formatDescription(link),
  image,
  name,
});

const EmptyResult = searchText => (
  <EmptySearchResult
    title={`No public Facebook Page called *${searchText}* found`}
    tipText="Try pasting a link to the Facebook Page"
  />
);

class FacebookPageSearch extends Component {
  render() {
    const { items, ...rest } = this.props;

    return (
      <PageSearch
        items={items.map(itemTransform)}
        placeholder="Enter the name of a Facebook Page…"
        renderEmptyResult={EmptyResult}
        {...rest}
      />
    );
  }
}

export default FacebookPageSearch;
