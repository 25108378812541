import sortBy from 'lodash/sortBy';

function getParamValue(filter, paramName) {
  if (!filter.parameters) {
    return null;
  }

  const parameter = filter.parameters.find(p => p.name === paramName);

  if (!parameter) {
    return null;
  }

  return parameter.value;
}

function filterDisplayName(filter, service) {
  const filterType = filter.filter_type;

  switch (filterType) {
    case 'match_tag':
      return getParamValue(filter, 'name');
    case 'match_author':
      const authorPrefix = service === 'twitter' ? '@' : '';
      return `${authorPrefix}${(
        getParamValue(filter, 'visible_name') || getParamValue(filter, 'name')
      ).trim()}`;
    case 'match_category_by_author':
      return getParamValue(filter, 'category_name').trim();
    case 'match_playlist':
    case 'match_channel':
    case 'match_network':
      return getParamValue(filter, 'visible_name').trim();
    default:
      return null;
  }
}

export function getDisplayName(mt) {
  let previousFilterType = null;

  if (mt.name) {
    return mt.name;
  }

  return sortBy(mt.media_tracker_filters, 'filter_type').reduce((currentName, filter) => {
    const filterType = filter.filter_type;
    const filterName = filterDisplayName(filter, mt.service);
    const prevSeparator = previousFilterType === filterType ? '\u00A0' : ', ';
    const separator = previousFilterType ? prevSeparator : '';

    previousFilterType = filterName ? filterType : null;

    if (filterName) {
      return (currentName + separator + filterName).trim();
    }

    return currentName;
  }, '');
}
