import { setFilter } from './shared';
import { SERVICES } from 'constants/Services';

export const PINTEREST_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.PINTEREST,
  filters: [{}],
};

export const QUESTION_IDS = {
  ADD_SOURCE: 'PINTEREST/ADD_SOURCE',
};

// Returns updated feed config state
export function pinterestFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.ADD_SOURCE: {
      // HOX this does not follow 1:1 proposal of short media tracker API!
      // It proposes storing information as rss url. But because we are still using
      // longer format of media trackers, there would be unnecessary rss-url parsing and formatting
      // when forming feed type for Pinterest

      const { board, user } = response.value;

      let filterUpdate = { type: 'rss', author: user };
      if (board) {
        filterUpdate = { ...filterUpdate, category: board };
      }

      return setFilter(state, filterUpdate);
    }

    default: {
      return state;
    }
  }
}
