export function setAccountId(prevConfig, accountId) {
  return {
    ...prevConfig,
    account_id: accountId,
  };
}

export function setSectionId(prevConfig, sectionId) {
  return {
    ...prevConfig,
    section_ids: [sectionId],
  };
}

export function setName(prevConfig, name) {
  return {
    ...prevConfig,
    name,
  };
}

export function setService(prevConfig, service) {
  return {
    ...prevConfig,
    service,
  };
}

export function setFilter(prevConfig, filterUpdate) {
  const prevFilters = prevConfig.filters && prevConfig.filters.length ? prevConfig.filters[0] : {};
  return {
    ...prevConfig,
    filters: [
      {
        ...prevFilters,
        ...filterUpdate,
      },
    ],
  };
}

export function setKeywords(prevConfig, keywords) {
  return setFilter(prevConfig, { keywords });
}

export function setUsernames(prevConfig, usernames) {
  return setFilter(prevConfig, { usernames });
}

export function setIncludedSources(prevConfig, include) {
  return setFilter(prevConfig, { include });
}

export function setSourceId(prevConfig, sourceId) {
  return setFilter(prevConfig, { sourceId });
}

export function setChannel(prevConfig, channelId) {
  return setFilter(prevConfig, {
    channel_id: channelId,
    type: 'channel',
  });
}

export function setPlaylist(prevConfig, playlistId) {
  return setFilter(prevConfig, {
    playlist_id: playlistId,
    type: 'playlist',
  });
}

export function setPage(prevConfig, pageId) {
  return setFilter(prevConfig, {
    page_id: pageId,
    type: 'page',
  });
}

export function setPageData(prevConfig, pageId, pageName) {
  return setFilter(prevConfig, {
    page_id: pageId,
    page_name: pageName,
  });
}

export function setTypeFilter(prevConfig, type) {
  return setFilter(prevConfig, { type });
}

export const updateConfig = (prevConfig, update) => {
  return {
    ...prevConfig,
    ...update,
  };
};

// If need to pipe update functions, create config updater from core feedConfig set-functions
const createConfigUpdater = fn => value => prevConfig => fn(prevConfig, value);

export const updateFilter = createConfigUpdater(setFilter);
export const updateKeywords = createConfigUpdater(setKeywords);
export const updateTypeFilter = createConfigUpdater(setTypeFilter);
export const updateToPlaylist = createConfigUpdater(setPlaylist);
export const updatePage = createConfigUpdater(setPage);
export const updateName = createConfigUpdater(setName);
export const updateToChannel = createConfigUpdater(setChannel);

// Pipe performs left-to-right function composition
// Passes updated state always to next update function
export const pipe =
  (...updateFns) =>
  state =>
    updateFns.reduce((currentState, updateFn) => updateFn(currentState), state);
