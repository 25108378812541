import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppContainer from 'components/AppContainer';
import { getFlocklerSiteUrl } from 'concepts/redirect-flockler';
import { setFeedId } from 'concepts/update-section';
import UpdateSectionConversation from 'containers/update-section/UpdateSectionConversation';

class ReconnectView extends Component {
  componentDidMount() {
    this.saveFeedId();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.feedId !== this.props.feedId) {
      this.saveFeedId();
    }
  }

  saveFeedId() {
    this.props.setFeedId(this.props.feedId);
  }

  render() {
    return (
      <AppContainer
        continueToAppUrl={this.props.flocklerSiteUrl}
        title={<h1>Update feed section</h1>}
      >
        <UpdateSectionConversation />
      </AppContainer>
    );
  }
}

const mapStateToProps = state => ({
  flocklerSiteUrl: getFlocklerSiteUrl(state),
});

const mapDispatchToProps = { setFeedId };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReconnectView);
