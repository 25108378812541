// Service providing different routes for Newsroom and Flockler app

import config from 'config';
import { CMS_TYPE_REACT_APP } from 'constants/FlocklerCmsTypes';
import {
  APP_ROUTE_EMBED,
  APP_ROUTE_FEEDS_LIST,
  APP_ROUTE_HOME,
  APP_ROUTE_INBOX,
  APP_ROUTE_MANUAL_CONTENT,
  APP_ROUTE_SECTION,
  APP_ROUTE_SITE,
  APP_ROUTE_SUBSCRIPTION,
} from 'constants/FlocklerAppRoutes';

// Newsroom URLs
const getNewsroomUrls = (appRoute, args) => {
  const baseUrl = config.newsroomUrl;

  switch (appRoute) {
    case APP_ROUTE_HOME:
      return baseUrl;
    case APP_ROUTE_SITE:
      return `${baseUrl}/${args.siteUrl}`;
    case APP_ROUTE_EMBED:
      return `${config.flocklerAppUrl}/${args.siteUrl}/layouts`;
    case APP_ROUTE_SECTION:
    case APP_ROUTE_MANUAL_CONTENT:
      return `${baseUrl}/${args.siteUrl}/sections/${args.sectionId}`;
    case APP_ROUTE_INBOX:
      return `${baseUrl}/${args.siteUrl}/sections/${args.sectionId}/inbox`;
    case APP_ROUTE_FEEDS_LIST:
      return `${config.flocklerAppUrl}/${args.siteUrl}/feeds`;
    default:
      return baseUrl;
  }
};

// Flockler app URLs
const getFlocklerAppUrls = (appRoute, args) => {
  const baseUrl = config.flocklerAppUrl;

  switch (appRoute) {
    case APP_ROUTE_HOME:
      return baseUrl;
    case APP_ROUTE_SITE:
      return `${baseUrl}/${args.siteUrl}`;
    case APP_ROUTE_EMBED:
      return `${baseUrl}/${args.siteUrl}/layouts`;
    case APP_ROUTE_SECTION:
    case APP_ROUTE_MANUAL_CONTENT:
      return `${config.newsroomUrl}/${args.siteUrl}/sections/${args.sectionId}`;
    case APP_ROUTE_INBOX:
      return `${config.newsroomUrl}/${args.siteUrl}/sections/${args.sectionId}/inbox`;
    case APP_ROUTE_FEEDS_LIST:
      return `${baseUrl}/${args.siteUrl}/feeds`;
    case APP_ROUTE_SUBSCRIPTION:
      return `${baseUrl}/${args.siteUrl}/settings/subscription`;
    default:
      return baseUrl;
  }
};

const getFlocklerUrl = (cmsType, appRoute, args) => {
  if (cmsType === CMS_TYPE_REACT_APP) {
    return getFlocklerAppUrls(appRoute, args);
  }

  return getNewsroomUrls(appRoute, args);
};

export const getFlocklerAppUrl = (cmsType, args) => getFlocklerUrl(cmsType, APP_ROUTE_HOME, args);
export const getFlocklerSiteUrl = (cmsType, args) => getFlocklerUrl(cmsType, APP_ROUTE_SITE, args);
export const getFlocklerEmbedUrl = (cmsType, args) =>
  getFlocklerUrl(cmsType, APP_ROUTE_EMBED, args);
export const getFlocklerSectionUrl = (cmsType, args) =>
  getFlocklerUrl(cmsType, APP_ROUTE_SECTION, args);
export const getFlocklerInboxUrl = (cmsType, args) =>
  getFlocklerUrl(cmsType, APP_ROUTE_INBOX, args);
export const getFlocklerFeedsListUrl = (cmsType, args) =>
  getFlocklerUrl(cmsType, APP_ROUTE_FEEDS_LIST, args);
export const getFlocklerManualContentUrl = (cmsType, args) =>
  getFlocklerUrl(cmsType, APP_ROUTE_MANUAL_CONTENT, args);
export const getFlocklerSubscriptionUrl = (cmsType, args) =>
  getFlocklerUrl(cmsType, APP_ROUTE_SUBSCRIPTION, args);
export const getFlocklerBlogUrl = () => "https://flockler.com/blog";
