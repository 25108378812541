import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import config from 'config';
import { getConversationState } from 'services/conversation-updater';
import { getQuestionAnswer } from 'concepts/feed-builder';
import { createSection } from 'concepts/section';
import { getSiteSections, getSiteUrl } from 'concepts/site';
import { QUESTION_IDS as QUESTION } from 'services/media-trackers/linkedin';
import { SERVICES } from 'constants/Services';
import { fetchLinkedInOrganizations } from 'services/api';
import serviceConversation from 'components/ConversationAboutServiceHOC';
import ConversationMessageList from 'components/ConversationMessageList';
import TargetFilterSelection from 'components/TargetFilterSelection';
import { helpScoutMessage } from 'utils/help-scout';
import styles from './ConversationAboutLinkedin.module.scss';

const EMPTY_RESPONSE = { value: null, text: null };

const fetchPages = () => {
  return fetchLinkedInOrganizations()
    .then(res => res.data)
    .then(response => {
      const { organizations } = response;

      if (!organizations || !organizations.length) {
        return Promise.reject();
      }

      return organizations;
    });
};

class ConversationAboutLinkedin extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoadingPages: false };
  }

  connectCallback = () => {
    const { answerQuestion } = this.props;
    this.setState({ isLoadingPages: true });

    return fetchPages()
      .then(pages => {
        this.setState({ isLoadingPages: false });

        return answerQuestion({
          response: {
            value: pages,
            text: `${pages.length} Pages found`,
          },
          questionId: QUESTION.LOADING_PAGES,
          nextId: pages.length > 0 ? QUESTION.CHOOSE_PAGE : QUESTION.NO_PAGES_FOUND,
        });
      })
      .catch(error => {
        this.setState({ isLoadingPages: false });
        return answerQuestion({
          response: {
            value: null,
            text: 'Error loading Pages',
          },
          questionId: QUESTION.LOADING_PAGES,
          nextId: QUESTION.ERROR_LOADING_PAGES,
        });
      });
  };

  reconnectAccount = () => {
    const { getConnectAction } = this.props;

    const reconnect = getConnectAction(
      QUESTION.ACCOUNT_CONNECTED_ALREADY,
      QUESTION.LOADING_PAGES,
      null,
      true
    );
    reconnect();
  };

  getConversationTemplate = () => {
    const {
      answerQuestion,
      createAccountConnectQuestion,
      createSection,
      getConnectAction,
      initialAccountCount,
      loadedPagesAnswer,
      siteSections,
      siteUrl,
    } = this.props;

    const loadedPages = get(loadedPagesAnswer, 'value') || [];

    return [
      // Question for user who has no accounts yet for service
      createAccountConnectQuestion({
        actions: [
          {
            action: getConnectAction(QUESTION.NO_ACCOUNT_CONNECTED_YET, QUESTION.LOADING_PAGES),
            id: QUESTION.CONNECT_ACCOUNT,
            name: 'Connect LinkedIn account…',
          },
        ],
        secondaryAction: {
          action: () => {
            if (!siteSections.length) {
              createSection('Posts').then(section => {
                window.location.href = `${config.newsroomUrl}/${siteUrl}/sections/${section.id}/linkedin`;
              });
            } else {
              // open LinkedIn manual adding
              window.location.href = `${config.newsroomUrl}/${siteUrl}/sections/${siteSections[0].id}/linkedin`;
            }
          },
          buttonClassName: styles.secondaryActionNotLinkLike,
          name: <>Don’t have a LinkedIn account? You can add individual posts manually.</>,
        },
        messages: [
          'LinkedIn it is then! Let’s start by connecting your LinkedIn account.',
          <>
            You’ll need to connect a LinkedIn account so I can fetch content for you.{' '}
            <strong style={{ background: 'yellow', padding: '0 1px' }}>
              After connecting your account, you can select your Company Page content to display.
            </strong>
          </>,
        ],
        nextId: QUESTION.LOADING_PAGES,
        visible: initialAccountCount === 0,
        questionId: QUESTION.NO_ACCOUNT_CONNECTED_YET,
        callback: this.connectCallback,
      }),

      // Question for user who has account(s) connected already
      createAccountConnectQuestion({
        actions: [],
        messages: [],
        nextId: QUESTION.LOADING_PAGES,
        visible: initialAccountCount > 0,
        questionId: QUESTION.ACCOUNT_CONNECTED_ALREADY,
        callback: this.connectCallback,
        shouldBeReverted: true,
      }),

      // "Ghost" question when waiting for pages to load
      {
        id: QUESTION.LOADING_PAGES,
        nextId: QUESTION.CHOOSE_PAGE,
        isLoading: true,
        isUserReplyDisabled: true,
        isBotMessageDisabled: !this.state.isLoadingPages,
        messages: [],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      {
        id: QUESTION.ERROR_LOADING_PAGES,
        nextId: null,
        isUserReplyDisabled: true,
        messages: [
          'Unfortunately I couldn’t get LinkedIn Pages for your account.',
          <span>
            Please try{' '}
            <button onClick={this.reconnectAccount}>reconnecting your LinkedIn account</button> or{' '}
            <button
              onClick={() => helpScoutMessage({ subject: 'LinkedIn Pages do not load correctly.' })}
            >
              chat with us
            </button>{' '}
            if problem persists.
          </span>,
        ],
        response: EMPTY_RESPONSE,
        visible: false,
      },

      // Choose Page(s)
      {
        autoAction:
          loadedPages.length === 1
            ? () => {
                return answerQuestion({
                  response: {
                    image: loadedPages[0].image,
                    value: loadedPages[0].id,
                    text: loadedPages[0].name,
                  },
                  nextId: QUESTION.WANT_TO_USE_TARGET_FILTER,
                  questionId: QUESTION.CHOOSE_PAGE,
                });
              }
            : null,
        select:
          loadedPages.length > 1
            ? {
                title: 'Select a LinkedIn Page',
                options: loadedPages.map(page => ({
                  action: () => {
                    answerQuestion({
                      response: {
                        image: page.image,
                        text: page.name,
                        value: page.id,
                      },
                      nextId: QUESTION.WANT_TO_USE_TARGET_FILTER,
                      questionId: QUESTION.CHOOSE_PAGE,
                    });
                  },
                  id: page.id,
                  name: page.name,
                  logoUrl: page.image,
                })),
              }
            : null,

        id: QUESTION.CHOOSE_PAGE,
        messages:
          loadedPages.length === 1
            ? [`It looks like you have access to ${loadedPages[0].name} LinkedIn Page 👌`]
            : [
                'It looks like you have access to multiple LinkedIn Pages.',
                'Which one would you like to use?',
              ],
        response: EMPTY_RESPONSE,
        visible: false,
        disableUndo: loadedPages.length === 1,
        isUserReplyDisabled: loadedPages.length === 1,
        isUserAvatarHidden: loadedPages.length === 1,
      },

      {
        actions: [
          {
            action: () => {
              answerQuestion({
                response: {
                  value: '',
                  text: 'Yes, show targeted posts',
                },
                nextId: QUESTION.CHOOSE_TARGET_FILTERS,
                questionId: QUESTION.WANT_TO_USE_TARGET_FILTER,
              });
            },
            id: 0,
            name: 'Choose target filters…',
          },

          {
            action: () => {
              answerQuestion({
                response: {
                  value: '',
                  text: 'Show all posts',
                },
                nextId: null,
                questionId: QUESTION.WANT_TO_USE_TARGET_FILTER,
              });
            },
            id: 1,
            name: 'Show all posts',
          },
        ],
        id: QUESTION.WANT_TO_USE_TARGET_FILTER,
        messages: [
          'Do you use language or location targeting when publishing LinkedIn posts? Would you like to show just posts targeted for specific languages or countries and regions?',
        ],
        visible: false,
        response: EMPTY_RESPONSE,
      },
      {
        custom: {
          children: (
            <TargetFilterSelection
              action={({ locales, locations }) => {
                const isGlobalSelected =
                  locales?.some(l => l.code === 'global') ||
                  locations?.some(l => l.id === 'global');

                const response = {
                  text: isGlobalSelected
                    ? 'Only posts without a language and a location'
                    : [...locales, ...locations].map(l => l.name).join(', '),
                  value: { locales, locations },
                };

                const showTargetFilteringInfoMessage =
                  !isGlobalSelected && locales?.length && locations?.length;

                answerQuestion({
                  response: response,
                  nextId: showTargetFilteringInfoMessage
                    ? QUESTION.INFORM_ABOUT_TARGET_FILTERING
                    : null,
                  questionId: QUESTION.CHOOSE_TARGET_FILTERS,
                });
              }}
            />
          ),
        },

        id: QUESTION.CHOOSE_TARGET_FILTERS,
        messages: [],
        visible: false,
        response: EMPTY_RESPONSE,
      },

      // When multiple target filters are selected we show informative bot message
      {
        autoAction: () => {
          return answerQuestion({
            response: {
              text: '',
              value: true,
            },
            nextId: null,
            questionId: QUESTION.INFORM_ABOUT_TARGET_FILTERING,
          });
        },
        id: QUESTION.INFORM_ABOUT_TARGET_FILTERING,
        messages: [
          'Alright, we will collect posts that are targeted to either the locations or the languages that you selected.',
        ],
        visible: false,
        isUserReplyDisabled: true,
        response: EMPTY_RESPONSE,
      },
    ];
  };

  render() {
    const { answers, revertToQuestion } = this.props;

    const conversation = this.getConversationTemplate();
    const conversationState = getConversationState(conversation, answers);

    return (
      <ConversationMessageList
        conversation={conversationState}
        revertToQuestion={revertToQuestion}
      />
    );
  }
}

const mapStateToProps = state => ({
  loadedPagesAnswer: getQuestionAnswer(QUESTION.LOADING_PAGES)(state),
  siteUrl: getSiteUrl(state),
  siteSections: getSiteSections(state),
});

const mapDispatchToProps = {
  createSection,
};

const ConnectedConversationAboutLinkedin = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationAboutLinkedin);

export default serviceConversation(ConnectedConversationAboutLinkedin, {
  service: SERVICES.LINKEDIN,
});
