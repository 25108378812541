import React from 'react';
import { connect } from 'react-redux';

import { getFeedConfig } from 'concepts/feed-builder';
import Sidebar from 'components/Sidebar';

import styles from './DebugPanel.module.scss';

class DebugPanel extends React.Component {
  render() {
    const { feedConfig } = this.props;

    return (
      <div className={styles.panel}>
        <Sidebar feedConfig={feedConfig} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  feedConfig: getFeedConfig(state),
});

export default connect(mapStateToProps)(DebugPanel);
