import includes from 'lodash/includes';

// These are not valid in Instagram URLS
const FORBIDDEN_USERNAME_CANDIDATES = ['p'];

const parseValid = (text, notValidTexts) => {
  if (!text || includes(notValidTexts, text.toLowerCase())) {
    return null;
  }

  return text;
};

export const parseInstagramUserUrl = url => {
  var match = /instagr(\.am|am\.com)\/([^/]+)/i.exec(url);

  if (!match) {
    return null;
  }

  const username = parseValid(match[2], FORBIDDEN_USERNAME_CANDIDATES);

  if (!username) {
    return { error: true };
  }

  return { username };
};
