import isObject from 'lodash/isObject';
import omit from 'lodash/omit';

// This should prevent possibly sensitive data to be sent to Google Analytics
const NON_TRACKABLE_FIELDS = ['access_token', 'account_id', 'page_id'];
export const cleanEventValues = value => {
  if (!isObject(value)) {
    return value;
  }

  return omit(value, NON_TRACKABLE_FIELDS);
};
