import { setAccountId, setIncludedSources, setKeywords, setPageData } from './shared';
import { SERVICES } from 'constants/Services';

export const SOURCES = Object.freeze({
  ADMIN_POSTS: 'admin_posts',
  FAN_POSTS: 'fan_posts',
  MENTIONS: 'public_mentions',
  RATINGS: 'ratings',
});

export const SOURCES_DTO_FORMAT = Object.freeze({
  [SOURCES.ADMIN_POSTS]: 'posts',
  [SOURCES.FAN_POSTS]: 'visitor_posts',
  [SOURCES.MENTIONS]: 'tagged',
  [SOURCES.RATINGS]: 'ratings',
});

export const PAGE_TYPES = Object.freeze({
  ADMIN: 'admin',
  PUBLIC: 'public',
});

export const FACEBOOK_FEED_CONFIG_TEMPLATE = {
  account_id: null,
  moderated: null,
  section_ids: [],
  service: SERVICES.FACEBOOK,
  filters: [
    {
      type: 'posts_related_to_page',
      keywords: [],
      match_any: false,
      page_id: null,
      include: [],
    },
  ],
};

export const QUESTION_IDS = {
  ACCOUNT: 'FACEBOOK/ACCOUNT',
  ACCOUNT_SELECTED: 'FACEBOOK/ACCOUNT_SELECTED',
  ERROR_LOADING_PAGES: 'FACEBOOK/ERROR_LOADING_PAGES',

  CHOOSE_FACEBOOK_PAGE_TYPE: 'FACEBOOK/CHOOSE_FACEBOOK_PAGE_TYPE',
  CHOOSE_FACEBOOK_PAGE: 'FACEBOOK/CHOOSE_FACEBOOK_PAGE',
  NO_ACCOUNT_CONNECTED_YET: 'FACEBOOK/NO_ACCOUNT_CONNECTED_YET',
  ACCOUNT_CONNECTED_ALREADY: 'FACEBOOK/ACCOUNT_CONNECTED_ALREADY',
  NOT_ANY_PAGE_ADMIN: 'FACEBOOK/NOT_ANY_PAGE_ADMIN',
  LOADING_PAGES: 'FACEBOOK/LOADING_PAGES',

  HASHTAG_INPUT: 'FACEBOOK/HASHTAG_INPUT',
  REQUIRE_KEYWORD: 'FACEBOOK/REQUIRE_KEYWORD',
  CAN_COLLECT_ONLY_ADMIN: 'FACEBOOK/CAN_COLLECT_ONLY_ADMIN',
  WHAT_TO_COLLECT: 'FACEBOOK/WHAT_TO_COLLECT',
  CONFIRM_HASHTAG: 'FACEBOOK/CONFIRM_HASHTAG',
};

// Returns updated feed config state
export function facebookFeedConfigReducer(state, { questionId, response }) {
  switch (questionId) {
    case QUESTION_IDS.ACCOUNT_CONNECTED_ALREADY:
    case QUESTION_IDS.NO_ACCOUNT_CONNECTED_YET: {
      return setAccountId(state, response.value);
    }

    case QUESTION_IDS.CHOOSE_FACEBOOK_PAGE: {
      return setPageData(state, response.value, response.text);
    }

    case QUESTION_IDS.CAN_COLLECT_ONLY_ADMIN:
    case QUESTION_IDS.WHAT_TO_COLLECT: {
      return setIncludedSources(state, response.value);
    }

    case QUESTION_IDS.HASHTAG_INPUT: {
      return setKeywords(state, response.value);
    }

    default: {
      return state;
    }
  }
}
