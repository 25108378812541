import React from 'react';
import { connect } from 'react-redux';

import { getUser } from 'concepts/user';
import { getLatestError } from 'concepts/error';
import { helpScoutMessage } from 'utils/help-scout';
import { redirectToFlockler } from 'concepts/redirect-flockler';

import ActionButton, { actionButtonize } from 'components/ActionButton';
import HelpScout from 'components/HelpScout';
import styles from './ErrorView.module.scss';
import { ReactComponent as ErrorSvg } from 'assets/svgs/icon-robot.svg';

const ActionButtonLink = actionButtonize('a');
const defaultErrorMessage =
  "Seems like something is not correct here. We're sorry for the inconvenience.";

const ErrorView = ({ latestError, user, redirectToFlockler }) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.card}>
        <ErrorSvg className={styles.icon} />
        <div className={styles.content}>
          <div className={styles.title}>{latestError || defaultErrorMessage}</div>
        </div>
        <div className={styles.actions}>
          <ActionButton
            onClick={() =>
              helpScoutMessage({
                subject: `I got an error${!!latestError ? `: "${latestError}"` : ''}`,
              })
            }
          >
            Ask for help
          </ActionButton>
          <ActionButtonLink onClick={redirectToFlockler}>Back to app</ActionButtonLink>
        </div>
      </div>

      <HelpScout user={user} />
    </div>
  );
};

const mapStateToProps = state => ({
  latestError: getLatestError(state),
  user: getUser(state),
});

const mapDispatchToProps = { redirectToFlockler };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorView);
