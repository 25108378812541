// # Section concept
import { getSiteId, reloadSite } from 'concepts/site';
import * as api from 'services/api';

// # Action types
const CREATE_SECTION_START = 'section/CREATE_SECTION_START';
const CREATE_SECTION_SUCCESS = 'section/CREATE_SECTION_SUCCESS';
const CREATE_SECTION_FAIL = 'section/CREATE_SECTION_FAIL';

// # Selector
export const getSectionCreationStatus = state => state.section.isCreating;

// # Action creators
export const createSection = sectionName => (dispatch, getState) => {
  // Start action
  dispatch({ type: CREATE_SECTION_START });

  const siteId = getSiteId(getState());

  if (!siteId || !sectionName) {
    dispatch({ type: CREATE_SECTION_FAIL });
    return Promise.reject('Can not create section');
  }

  // Post media tracker object
  return api
    .postSiteSection(siteId, { name: sectionName })
    .then(response => {
      dispatch({
        type: CREATE_SECTION_SUCCESS,
        payload: response.data,
      });

      // This fetches site with new sections
      dispatch(reloadSite());
      return response.data;
    })
    .catch(err => {
      dispatch({ type: CREATE_SECTION_FAIL });
      return Promise.reject(err);
    });
};

// # Reducer
const initialState = Object.freeze({
  isCreating: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SECTION_START: {
      return { ...state, isCreating: true };
    }

    case CREATE_SECTION_SUCCESS:
    case CREATE_SECTION_FAIL: {
      return { ...state, isCreating: false };
    }

    default: {
      return state;
    }
  }
}
