// Concept for redirect to correct Flockler application
//
// Responsibility of this concept is to enable easy redirecting without passing
// same props (cmsType & siteUrl) to components all the time

import isNil from 'lodash/isNil';
import { getSiteCmsType, getSiteUrl, getSectionId } from 'concepts/site';
import { getUserHasCreatedFirstFeed } from 'concepts/user';

import * as flocklerUrls from 'services/flockler-urls';
import redirect from 'services/redirect';

const getUrl = (getAppRoute, params) => state => {
  const cmsType = getSiteCmsType(state);
  const siteUrl = getSiteUrl(state);

  const urlParams = { siteUrl, ...params };
  let url = getAppRoute(cmsType, urlParams);

  // Fallback to root url if siteUrl not found
  if (!siteUrl) {
    url = flocklerUrls.getFlocklerAppUrl(cmsType);
  }

  return url;
};

export const getFlocklerSiteUrl = getUrl(flocklerUrls.getFlocklerSiteUrl);

const redirectAction = (getAppRoute, openInNewTab = false) => params => (dispatch, getState) => {
  const state = getState();
  const url = getUrl(getAppRoute, params)(state);

  if (openInNewTab) {
    window.open(url, '_blank');
  } else {
    redirect(url);
  }
};

// If sectionId does not exist as parameter, we get section id from site
const redirectActionWithSectionId = getAppRoute => redirectSectionId => (dispatch, getState) => {
  let sectionId = redirectSectionId;

  if (isNil(sectionId)) {
    sectionId = getSectionId(getState());
  }

  return dispatch(redirectAction(getAppRoute)({ sectionId }));
};

// Redirect actions
export const redirectToFlockler = redirectAction(flocklerUrls.getFlocklerAppUrl);
export const redirectToFlocklerSite = redirectAction(flocklerUrls.getFlocklerSiteUrl);
export const redirectToFlocklerEmbed = redirectAction(flocklerUrls.getFlocklerEmbedUrl);
export const redirectToFlocklerFeedsList = redirectAction(flocklerUrls.getFlocklerFeedsListUrl);
export const redirectToFlocklerSection = redirectActionWithSectionId(
  flocklerUrls.getFlocklerSectionUrl
);
export const redirectToFlocklerInbox = redirectActionWithSectionId(
  flocklerUrls.getFlocklerInboxUrl
);
export const redirectToFlocklerManualContent = redirectActionWithSectionId(
  flocklerUrls.getFlocklerManualContentUrl
);

// After successful feed creation user is redirected to
// - chosen section if user has already created one feed before
// - to embed view if user has not created any feeds before
export const redirectToFlocklerAfterSuccessfulFeedCreation = sectionId => (dispatch, getState) => {
  const hasUserCreatedFirstFeed = getUserHasCreatedFirstFeed(getState());

  if (!hasUserCreatedFirstFeed) {
    return dispatch(redirectToFlocklerEmbed());
  }

  return dispatch(redirectToFlocklerSection(sectionId));
};
export const redirectToFlocklerSubscription = redirectAction(flocklerUrls.getFlocklerSubscriptionUrl, true);
export const redirectToFlocklerBlog = redirectAction(flocklerUrls.getFlocklerBlogUrl, true);
